var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useCallback, useEffect, useRef, useState } from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLoadingSpinner } from "@elastic/eui";
import moment from "moment";
import ReactDOM from "react-dom";
import { addMinutes, differenceInMinutes } from "date-fns";
import { BorderRadius, colors } from "@pm-frontend/styles";
import { PmText } from "@pm-frontend/shared/components/Text/PmText";
import URL from "@pm-shared/utils/url";
import { useBookResidentAvailability, useEditAlternativeEvent, useScheduleMeld } from "../queries";
import { isSegmentScheduled } from "../utils/utils";
import { getMeldProperty, isMeldAssigned, isMeldSchedulable } from "@pm-frontend/shared/utils/meld-utils";
import { canScheduleMelds } from "@pm-frontend/shared/utils/permission-utils";
import {
  useCalendarStateActions,
  useCalendarStateAltEventSelectedAgentsTime,
  useCalendarStateMeldResidentAvailabilities
} from "@pm-frontend/routes/Calendar/stores/calendarStateStore";
import Features from "@pm-assets/js/common/feature-flags";
import {
  calendarEventOnDragOver,
  CALENDAR_PENDING_AVAILABILITY_EVENT_NAME,
  DraggingEvent,
  getCalendarEventOnDragStart
} from "../utils/click-drag-and-drop-utils";
import { getPersonaColor } from "@pm-frontend/shared/utils/color-utils";
import { useGetSetActivePaneAndMap, useIsEventViewableDueToPropertyGroups } from "../utils/hooks";
import {
  useCalendarDragAndDropState,
  useCalendarDragAndDropStateActions
} from "../stores/dragDropStore";
import { getPropertyNameDistinctFromLine1 } from "@pm-frontend/shared/utils/property-utils";
import { MeldCalendarDetailsCard } from "../rightpane/MeldCalendarMeldsList";
import { CALENDAR_TIMEFRAMES } from "../stores/timeFrameStore";
import { getCanRescheduleAppointment } from "@pm-frontend/shared/utils/appointment-utils";
import {
  getCalendarDraftModeActions,
  getCalendarDraftModeEnabled,
  useCalendarDraftModeEnabled,
  useGetCalendarDraftModeIgnoredRecommendations,
  useGetCalendarDraftModePendingActions
} from "../stores/draftModeStore";
import { makeCompositeId } from "@pm-frontend/shared/utils/assignment-utils";
import { PmEmptyButton } from "@pm-frontend/shared/components/Buttons/PmEmptyButton";
import { PmXCircleOutline } from "@pm-frontend/assets/icons/components/PmXCircleOutline";
import { PmCheckCircleOutline } from "@pm-frontend/assets/icons/components/PmCheckCircleOutline";
const DraftActionsToHide = {
  assignMeld: false,
  cancelAppointment: false
};
const PADDING_IN_PX = 2;
const LINE_HEIGHT_IN_PX = 16;
const getEventColor = (event, isEventViewableDueToPropertyGroups = true) => {
  if (!isEventViewableDueToPropertyGroups) {
    return {
      text: colors.neutrals.gray900,
      bg: colors.neutrals.gray400
    };
  }
  switch (event.type) {
    case "management_scheduled":
      return {
        text: colors.brand.black,
        bg: getPersonaColor(event.agent)
      };
    case "vendor_scheduled":
      return {
        text: colors.brand.black,
        bg: getPersonaColor(event.vendor)
      };
    case "pendingDraftAction":
      return {
        text: colors.brand.black,
        bg: getPersonaColor(event.persona)
      };
    case "google_calendar_event":
      return {
        text: colors.brand.white,
        bg: colors.neutrals.gray600
      };
    case "outlook_calendar_event":
      return {
        text: colors.brand.white,
        bg: colors.neutrals.gray600
      };
    case "recommend_meld_placeholder":
      return {
        text: colors.brand.darkHover,
        bg: colors.brand.lightBlue
      };
    case "pending_recommendation_event":
      return {
        text: colors.brand.meldBlue,
        bg: colors.brand.white
      };
    case "resident_availability":
      return {
        text: colors.neutrals.gray800,
        bg: colors.brand.white
      };
    case "management_availability":
      return {
        text: colors.neutrals.gray800,
        bg: colors.brand.veryLightBlue
      };
    case "vendor_availability":
      return {
        text: colors.neutrals.gray800,
        bg: colors.brand.veryLightBlue
      };
    case "alternative_event_scheduled":
      return {
        text: colors.brand.white,
        bg: colors.neutrals.gray600
      };
    case "pending_offered_availability":
      return {
        text: colors.neutrals.gray800,
        bg: colors.brand.veryLightBlue
      };
    case "pending_alt_event":
      return {
        text: colors.neutrals.gray800,
        bg: colors.brand.veryLightBlue
      };
    default:
      return {
        text: colors.brand.white,
        bg: colors.brand.meldBlue
      };
  }
};
const EventTextStyles = {
  padding: "2px",
  lineHeight: "16px",
  overflow: "hidden",
  overflowWrap: "break-word"
};
const RemoveEventButton = ({
  onClick,
  ariaLabel,
  zIndex,
  color = colors.neutrals.gray800
}) => {
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        position: "absolute",
        width: "16px",
        height: "16px",
        // TODO: what if the icon is larger than the event?
        bottom: 6,
        right: 4,
        zIndex,
        cursor: "pointer"
      },
      onClick,
      "data-testid": "meld-calendar-draft-remove-event",
      "aria-label": ariaLabel
    },
    /* @__PURE__ */ React.createElement(PmXCircleOutline, { fill: color })
  );
};
const MeldOfferedAvailabilityEvent = ({
  isMobile,
  event,
  eventStyle,
  segment,
  isEventViewableDueToPropertyGroups,
  dragAndDropState
}) => {
  const { isHovered, floatingTargetRef, handleMouseEnter, handleMouseLeave } = useFloatingEventDetails();
  if (!isEventViewableDueToPropertyGroups) {
    return null;
  }
  const eventColor = getEventColor(event);
  const pointerEventsDisabled = dragAndDropState !== null;
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: __spreadProps(__spreadValues({}, eventStyle), {
        borderRadius: "6px",
        fontSize: "12px",
        pointerEvents: pointerEventsDisabled ? "none" : void 0
      }),
      onClick: event.onClick,
      "data-testid": `meld-calendar-offered-availability-event-${event.personaType}-${event.personaId}-${segment.id}`,
      ref: floatingTargetRef,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave
    },
    /* @__PURE__ */ React.createElement(PmText, { style: EventTextStyles, color: eventColor.text, fontSize: "p3" }, "Time offered to resident"),
    /* @__PURE__ */ React.createElement(PmText, { style: EventTextStyles, color: eventColor.text, fontSize: "p3", fontWeight: "semiBold" }, event.description),
    !isMobile && isHovered && dragAndDropState === null && /* @__PURE__ */ React.createElement(FloatingEventDetails, { targetRef: floatingTargetRef, isVisible: isHovered }, /* @__PURE__ */ React.createElement(MeldCalendarDetailsCard, { source: "calendarEventHover", meld: event.meld }))
  );
};
const MeldPendingOfferedAvailabilityEvent = ({
  event,
  eventStyle
}) => {
  const onRemoveClick = () => {
    if (getCalendarDraftModeEnabled()) {
      return;
    }
    if (event.type !== "pending_offered_availability") {
      return null;
    }
    const removePendingAvailability = new CustomEvent(
      CALENDAR_PENDING_AVAILABILITY_EVENT_NAME,
      {
        detail: {
          type: "removePendingAvailability",
          tempId: event.tempId
        }
      }
    );
    window.dispatchEvent(removePendingAvailability);
  };
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: __spreadProps(__spreadValues({}, eventStyle), { borderRadius: "6px", fontSize: "12px" }),
      "data-testid": `meld-calendar-pending-offered-availability-event-${event.personaId}`
    },
    /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", gutterSize: "xs", style: { justifyContent: "space-between" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmText, { style: EventTextStyles, color: getEventColor(event).text, fontSize: "p3" }, event.description)), /* @__PURE__ */ React.createElement(
      EuiFlexItem,
      {
        grow: false,
        onClick: onRemoveClick,
        "data-testid": `meld-calendar-remove-pending-offered-availability-event-${event.personaId}`
      },
      /* @__PURE__ */ React.createElement(EuiIcon, { type: URL.getStatic("icons/add_circle_filled.svg"), size: "original" })
    ))
  );
};
const MeldResidentAvailabilityEvent = ({
  event,
  eventStyle,
  selectedTimeFrame,
  meld,
  segment,
  isEventViewableDueToPropertyGroups,
  dragAndDropState,
  isMobile
}) => {
  const { isHovered, floatingTargetRef, handleMouseEnter, handleMouseLeave } = useFloatingEventDetails();
  const personaCompositeID = makeCompositeId(event.personaId, event.personaType);
  const scheduleMeldMutation = useScheduleMeld(meld, {
    initiator: "offeredAvailabilityCalendarEvent",
    actionType: "addAppointment"
  });
  const { addScheduledSegment } = useCalendarStateActions();
  const residentAvailabilitiesMeldId = useCalendarStateMeldResidentAvailabilities();
  const bookMutation = useBookResidentAvailability(
    meld,
    { initiator: "offeredAvailabilityCalendarEvent" },
    isMeldAssigned(meld) ? void 0 : personaCompositeID
  );
  if (!isEventViewableDueToPropertyGroups) {
    return null;
  }
  const onAddRequestedAppointment = (seg) => {
    const startTimeStr = seg.event.dtstart;
    const endTimeStr = seg.event.dtend;
    if (!startTimeStr || !endTimeStr) {
      return;
    }
    const startTime = moment(startTimeStr);
    const endTime = moment(endTimeStr);
    const durationInMin = endTime.diff(startTime, "minutes");
    scheduleMeldMutation.mutate({
      isReschedulingAppointment: false,
      isAddingAppointment: true,
      events: null,
      startTime,
      durationInMin,
      appointmentId: null
    });
  };
  if (isSegmentScheduled({ event: { dtstart: event.start, dtend: event.end } }, meld)) {
    return null;
  }
  const residentIcon = /* @__PURE__ */ React.createElement(EuiIcon, { type: URL.getStatic("icons/residents_gray.svg"), size: "m" });
  const titleText = /* @__PURE__ */ React.createElement(PmText, { style: EventTextStyles, color: getEventColor(event).text, fontSize: "p3" }, event.description);
  const linkOnClick = () => {
    if (getCalendarDraftModeEnabled()) {
      return;
    }
    if (!Features.isMultipleAppointmentsEnabled()) {
      bookMutation.mutate({ ids: [segment.id], isAssigning: !isMeldAssigned(meld) });
      return;
    }
    const hasScheduledAppointment = meld.vendorappointment.length > 0 && meld.vendorappointment[0].availability_segment !== null || meld.managementappointment.length > 0 && meld.managementappointment[0].availability_segment !== null;
    if (hasScheduledAppointment) {
      onAddRequestedAppointment(segment);
    } else {
      if (Features.isMultipleAppointmentsEnabled()) {
        addScheduledSegment(segment);
      }
      bookMutation.mutate({ ids: [segment.id], isAssigning: !isMeldAssigned(meld) });
    }
  };
  const showScheduleLink = event.start_moment.isAfter(moment()) && (isMeldSchedulable(meld) || !isMeldAssigned(meld)) && canScheduleMelds;
  const link = /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", gutterSize: "xs" }, /* @__PURE__ */ React.createElement(
    PmEmptyButton,
    {
      padding: "0",
      textSize: "12px",
      onClick: linkOnClick,
      hasBorder: false,
      isDisabled: bookMutation.isLoading,
      text: residentAvailabilitiesMeldId ? "" : "Schedule"
    }
  ), bookMutation.isLoading && /* @__PURE__ */ React.createElement(EuiLoadingSpinner, { size: "s", style: { marginTop: "4px" } }));
  const pointerEventsDisabled = dragAndDropState !== null;
  if (selectedTimeFrame !== CALENDAR_TIMEFRAMES.ONE_DAY) {
    return /* @__PURE__ */ React.createElement(
      EuiFlexGroup,
      {
        direction: "column",
        justifyContent: "spaceBetween",
        gutterSize: "xs",
        style: __spreadProps(__spreadValues({}, eventStyle), {
          border: `1px solid ${colors.brand.meldBlue}`,
          borderRadius: "6px",
          cursor: "unset",
          pointerEvents: pointerEventsDisabled ? "none" : void 0
        }),
        onClick: event.onClick,
        "data-testid": `meld-calendar-resident-availability-event-${segment.id}`,
        ref: floatingTargetRef,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave
      },
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { flexShrink: 0 } }, residentIcon),
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { flexShrink: 0 } }, titleText, /* @__PURE__ */ React.createElement(PmText, { fontWeight: "semiBold", fontSize: "p3", style: { padding: "2px" } }, meld.brief_description)),
      /* @__PURE__ */ React.createElement(EuiFlexItem, { style: { flexGrow: 1 } }),
      showScheduleLink && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { flexShrink: 0, marginTop: "auto" } }, link),
      !isMobile && isHovered && dragAndDropState === null && /* @__PURE__ */ React.createElement(FloatingEventDetails, { targetRef: floatingTargetRef, isVisible: isHovered }, /* @__PURE__ */ React.createElement(MeldCalendarDetailsCard, { source: "calendarEventHover", meld }))
    );
  }
  return /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "column",
      justifyContent: "spaceBetween",
      gutterSize: "none",
      style: __spreadProps(__spreadValues({}, eventStyle), {
        border: `1px solid ${colors.brand.meldBlue}`,
        borderRadius: "6px",
        cursor: "unset",
        pointerEvents: pointerEventsDisabled ? "none" : void 0
      }),
      onClick: event.onClick,
      "data-testid": `meld-calendar-resident-availability-event-${segment.id}`,
      ref: floatingTargetRef,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, justifyContent: "spaceBetween" }, showScheduleLink && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { flexShrink: 0, marginTop: "auto" } }, link), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, residentIcon))),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmText, { fontWeight: "semiBold", fontSize: "p3" }, meld.brief_description), titleText),
    !isMobile && isHovered && dragAndDropState === null && /* @__PURE__ */ React.createElement(FloatingEventDetails, { targetRef: floatingTargetRef, isVisible: isHovered }, /* @__PURE__ */ React.createElement(MeldCalendarDetailsCard, { source: "calendarEventHover", meld }))
  );
};
const getEventSize = (eventStyle, type, defaultHourColumnSize) => {
  if (type === "row" && typeof eventStyle.width === "string") {
    return parseInt(eventStyle.width, 10);
  } else if (type === "column" && typeof eventStyle.height === "string") {
    return parseInt(eventStyle.height, 10);
  }
  return defaultHourColumnSize;
};
const NUM_RESIZE_CHUNKS_PER_HOUR = 4;
const getMinimumDragEventAdjustmentSize = (hourSizeInPx) => {
  return hourSizeInPx / NUM_RESIZE_CHUNKS_PER_HOUR;
};
const useCalendarEventDragAndDrop = ({
  id,
  isMobile,
  onResizeDrop,
  eventStyle,
  type,
  hourSizeInPx
}) => {
  const dragAndDropState = useCalendarDragAndDropState();
  const { startResizingEvent, stopResizingEvent, startDraggingMeld, startDraggingEvent, stopDragging } = useCalendarDragAndDropStateActions();
  const eventRef = useRef(null);
  const minAdjustmentSizeInPx = getMinimumDragEventAdjustmentSize(hourSizeInPx);
  const [size, setSize] = useState(() => getEventSize(eventStyle, type, hourSizeInPx));
  const initialX = useRef(0);
  const initialY = useRef(0);
  const newSize = useRef(size);
  const onResizeDrag = useCallback((e) => {
    e.stopPropagation();
    if (!eventRef.current) {
      return;
    }
    const delta = type === "row" ? e.clientX - initialX.current : e.clientY - initialY.current;
    if (Math.abs(delta) < minAdjustmentSizeInPx) {
      return;
    }
    initialX.current = e.clientX;
    initialY.current = e.clientY;
    setSize((prevSize) => {
      const numberOfChunks = Math.round((prevSize + delta) / minAdjustmentSizeInPx);
      if (numberOfChunks > 8 * NUM_RESIZE_CHUNKS_PER_HOUR) {
        return prevSize;
      }
      const updatedSize = numberOfChunks * minAdjustmentSizeInPx;
      newSize.current = updatedSize;
      return Math.max(minAdjustmentSizeInPx, updatedSize);
    });
  }, []);
  const onResizeDragStart = (e) => {
    e.stopPropagation();
    const dragImage = document.createElement("div");
    dragImage.style.width = "0px";
    dragImage.style.height = "0px";
    document.body.appendChild(dragImage);
    e.dataTransfer.setDragImage(dragImage, 0, 0);
    setTimeout(() => document.body.removeChild(dragImage), 0);
    startResizingEvent(id);
    initialX.current = e.clientX;
    initialY.current = e.clientY;
    document.addEventListener("drag", onResizeDrag);
    document.addEventListener("dragover", calendarEventOnDragOver);
    document.addEventListener("drop", onResizeDrop);
  };
  useEffect(() => {
    return () => {
      document.removeEventListener("drag", onResizeDrag);
      document.removeEventListener("drop", onResizeDrop);
      document.removeEventListener("dragover", calendarEventOnDragOver);
    };
  }, []);
  useEffect(() => {
    setSize(getEventSize(eventStyle, type, hourSizeInPx));
  }, [hourSizeInPx]);
  const dragEventEnabled = !isMobile && dragAndDropState === null || (dragAndDropState == null ? void 0 : dragAndDropState.type) === "draggingEvent";
  const dragMeldEnabled = !isMobile && dragAndDropState === null || (dragAndDropState == null ? void 0 : dragAndDropState.type) === "draggingMeld";
  const resizeEnabled = !isMobile && dragAndDropState === null || (dragAndDropState == null ? void 0 : dragAndDropState.type) === "resizingEvent";
  return {
    onResizeDrag,
    newSize,
    size,
    setSize,
    eventRef,
    startDraggingMeld,
    startDraggingEvent,
    stopResizingEvent,
    resizeEnabled,
    onResizeDragStart,
    dragMeldEnabled,
    dragEventEnabled,
    stopDragging,
    dragAndDropState
  };
};
function getMeldScheduledEventText(event, isEventViewableDueToPropertyGroups) {
  if (!isEventViewableDueToPropertyGroups) {
    return "Meld is not in your property groups.";
  }
  return event.description;
}
function getMeldScheduledAddressText(meld) {
  if (!meld) {
    return void 0;
  }
  const property = getMeldProperty(meld);
  const distinctPropertyName = getPropertyNameDistinctFromLine1(property);
  const result = [];
  if (distinctPropertyName) {
    result.push(distinctPropertyName);
  } else {
    if (meld.unit) {
      result.push(meld.unit.display_address.line_1);
    } else {
      result.push(property.line_1);
    }
  }
  if (meld.unit) {
    result.push(`Unit ${meld.unit.unit}`);
  }
  return result.filter(Boolean).join(", ");
}
const useFloatingEventDetails = () => {
  const [isHovered, setIsHovered] = useState(false);
  const hoverTimeout = useRef(null);
  const floatingTargetRef = useRef(null);
  const handleMouseEnter = () => {
    hoverTimeout.current = setTimeout(() => {
      setIsHovered(true);
    }, HOVER_CARD_DELAY_IN_MS);
  };
  const handleMouseLeave = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    if (isHovered) {
      setIsHovered(false);
    }
  };
  return {
    isHovered,
    floatingTargetRef,
    handleMouseEnter,
    handleMouseLeave
  };
};
const FloatingEventDetails = ({
  children,
  targetRef,
  isVisible
}) => {
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });
  useEffect(() => {
    if (targetRef.current && isVisible) {
      const rect = targetRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top - rect.height / 2,
        // Center vertically
        left: rect.right - rect.width * 0.25,
        // Shift left by 25% of width
        width: rect.width
        // Match the width
      });
    }
  }, [isVisible, targetRef]);
  if (!isVisible) {
    return null;
  }
  return ReactDOM.createPortal(
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          width: "300px",
          position: "absolute",
          top: `${position.top}px`,
          left: `${position.left}px`,
          // on top of everything, see `zIndicies` in single/multi day calendars
          zIndex: 1e3
        }
      },
      children
    ),
    document.body
  );
};
const HOVER_CARD_DELAY_IN_MS = 500;
const MeldScheduledEvent = ({
  event,
  eventStyle,
  modalState,
  type,
  isMobile,
  hourSizeInPx,
  isEventViewableDueToPropertyGroups
}) => {
  var _a, _b, _c, _d, _e, _f;
  const { isHovered, floatingTargetRef, handleMouseEnter, handleMouseLeave } = useFloatingEventDetails();
  const draftModeEnabled = useCalendarDraftModeEnabled();
  const onResizeDrop = useCallback((e) => {
    var _a2;
    e.preventDefault();
    e.stopPropagation();
    const apptId = event.type === "management_scheduled" && event.meld && ((_a2 = event.segment.managementavailabilitysegment.scheduled_management_appointment) == null ? void 0 : _a2.id) || event.type === "vendor_scheduled" && event.meld && event.segment.vendoravailabilitysegment.scheduled_vendor_appointment;
    const newDurationInMin = newSize.current / hourSizeInPx * 60;
    if (!apptId) {
    } else if (newDurationInMin === event.end_moment.diff(event.start_moment, "minutes")) {
    } else {
      modalState.open({
        type: "rescheduleEvent",
        meld: event.meld,
        newDurationInMin,
        newDurationStart: event.start_moment,
        appointmentId: apptId
      });
    }
    stopResizingEvent();
    document.removeEventListener("drag", onResizeDrag);
    document.removeEventListener("drop", onResizeDrop);
    document.removeEventListener("dragover", calendarEventOnDragOver);
    setSize(getEventSize(eventStyle, type, hourSizeInPx));
  }, []);
  const {
    onResizeDrag,
    newSize,
    size,
    setSize,
    eventRef,
    startDraggingEvent,
    stopResizingEvent,
    resizeEnabled: resizeMeldEnabled,
    onResizeDragStart,
    dragMeldEnabled,
    stopDragging,
    dragAndDropState
  } = useCalendarEventDragAndDrop({
    id: ((_a = event.meld) == null ? void 0 : _a.id) || 0,
    isMobile,
    onResizeDrop,
    eventStyle,
    type,
    hourSizeInPx
  });
  const eventTimeCanBeRescheduled = getCanRescheduleAppointment({
    availability_segment: { event: { dtstart: event.start, dtend: event.end } }
  });
  const dragEnabled = dragMeldEnabled && eventTimeCanBeRescheduled;
  const resizeEnabled = resizeMeldEnabled && eventTimeCanBeRescheduled;
  const appointmentId = event.type === "vendor_scheduled" && event.segment.vendoravailabilitysegment.scheduled_vendor_appointment || event.type === "management_scheduled" && ((_b = event.segment.managementavailabilitysegment.scheduled_management_appointment) == null ? void 0 : _b.id) || null;
  const text = getMeldScheduledEventText(event, isEventViewableDueToPropertyGroups);
  const addressText = getMeldScheduledAddressText(event.meld);
  const pointerEventsDisabled = dragAndDropState !== null && dragAndDropState.id !== ((_c = event.meld) == null ? void 0 : _c.id);
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        width: type === "row" ? size : eventStyle.width,
        height: type === "column" ? size : eventStyle.height,
        position: "relative",
        left: eventStyle.left,
        top: eventStyle.top,
        pointerEvents: pointerEventsDisabled ? "none" : void 0
      },
      ref: eventRef,
      "data-testid": `meld-calendar-meld-event-${(_d = event.meld) == null ? void 0 : _d.id}-${event.personaType}-${event.personaId}`
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        ref: floatingTargetRef,
        style: __spreadProps(__spreadValues({}, eventStyle), {
          paddingRight: "4px",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          cursor: isEventViewableDueToPropertyGroups && dragEnabled && "grab" || "pointer"
        }),
        onClick: isEventViewableDueToPropertyGroups ? event.onClick : void 0,
        draggable: isEventViewableDueToPropertyGroups && dragEnabled,
        onDragStart: isEventViewableDueToPropertyGroups && event.meld && dragEnabled ? getCalendarEventOnDragStart({
          meld: event.meld,
          coordinates: event.coordinates,
          additionalCallback: startDraggingEvent,
          appointmentId,
          type: "meld",
          personaType: event.type === "management_scheduled" ? "agent" : "vendor",
          personaIds: event.type === "management_scheduled" ? ((_f = (_e = event.segment.managementavailabilitysegment.scheduled_management_appointment) == null ? void 0 : _e.management_assignment) == null ? void 0 : _f.in_house_servicers) || [] : [event.segment.vendoravailabilitysegment.assignment_request.vendor.id]
        }) : void 0,
        onDragEnd: isEventViewableDueToPropertyGroups ? stopDragging : void 0,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave
      },
      /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "none" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
        PmText,
        {
          style: EventTextStyles,
          color: getEventColor(event, isEventViewableDueToPropertyGroups).text,
          fontWeight: "semiBold",
          fontSize: "p3"
        },
        text
      )), addressText !== void 0 && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmText, { style: EventTextStyles, color: colors.neutrals.gray900, fontSize: "p3" }, addressText)))
    ),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          width: type === "row" ? "10px" : "100%",
          height: type === "row" ? "100%" : "10px",
          backgroundColor: getEventColor(event, isEventViewableDueToPropertyGroups).bg,
          borderTopRightRadius: type === "row" ? eventStyle.borderRadius : void 0,
          borderBottomRightRadius: eventStyle.borderRadius,
          borderBottomLeftRadius: type === "row" ? void 0 : eventStyle.borderRadius,
          borderTop: type === "row" ? eventStyle.border : void 0,
          borderRight: eventStyle.border,
          borderBottom: eventStyle.border,
          borderLeft: type === "row" ? void 0 : eventStyle.border,
          position: "absolute",
          left: type === "row" ? size - 10 : 0,
          top: type === "row" ? 0 : size - 10,
          cursor: isEventViewableDueToPropertyGroups && resizeEnabled && (type === "row" ? "e-resize" : "ns-resize") || void 0,
          zIndex: eventStyle.zIndex
        },
        draggable: isEventViewableDueToPropertyGroups && resizeEnabled,
        onDragStart: isEventViewableDueToPropertyGroups && resizeEnabled ? onResizeDragStart : void 0
      }
    ),
    draftModeEnabled && appointmentId && /* @__PURE__ */ React.createElement(
      RemoveEventButton,
      {
        ariaLabel: "Cancel appointment",
        zIndex: eventStyle.zIndex,
        onClick: () => getCalendarDraftModeActions().addPendingAction({
          type: "cancelAppointment",
          date: /* @__PURE__ */ new Date(),
          meldId: event.meld.id,
          appointmentId,
          recommendationId: void 0,
          personaType: event.type === "management_scheduled" ? "agent" : "vendor",
          // not needed as we don't display anything for canceled appointments
          personaIds: []
        })
      }
    ),
    !isMobile && isHovered && dragAndDropState === null && /* @__PURE__ */ React.createElement(FloatingEventDetails, { targetRef: floatingTargetRef, isVisible: isHovered }, /* @__PURE__ */ React.createElement(MeldCalendarDetailsCard, { source: "calendarEventHover", meld: event.meld }))
  );
};
const AlternativeEvent = ({
  event,
  eventStyle,
  modalState,
  type,
  isMobile,
  hourSizeInPx
}) => {
  const editMutation = useEditAlternativeEvent(event.type === "alternative_event_scheduled" ? event.altEvent : null);
  const onResizeDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const apptId = event.type === "alternative_event_scheduled" && event.altEvent && event.altEvent.id;
    const newDurationInMin = newSize.current / hourSizeInPx * 60;
    if (getCalendarDraftModeEnabled()) {
    } else if (!apptId) {
    } else if (newDurationInMin === event.end_moment.diff(event.start_moment, "minutes")) {
    } else if (event.altEvent.parent_event) {
      modalState.open({
        type: "updateEvent",
        altEvent: { id: event.altEvent.id },
        newDurationInMin,
        startTime: event.start_moment,
        event: event.altEvent.event.id
      });
    } else {
      const newEndTime = event.start_moment.clone().add(newDurationInMin, "minutes");
      editMutation.mutate({
        type: "None",
        payload: {
          id: event.altEvent.id,
          management_attendees: event.altEvent.management_attendees,
          event: {
            id: event.altEvent.event.id,
            description: event.altEvent.event.description,
            dtstart: event.start_moment.toISOString(),
            dtend: newEndTime.toISOString()
          }
        }
      });
    }
    stopResizingEvent();
    document.removeEventListener("drag", onResizeDrag);
    document.removeEventListener("drop", onResizeDrop);
    document.removeEventListener("dragover", calendarEventOnDragOver);
    setSize(getEventSize(eventStyle, type, hourSizeInPx));
  }, []);
  const {
    onResizeDrag,
    newSize,
    size,
    setSize,
    eventRef,
    startDraggingEvent,
    stopResizingEvent,
    resizeEnabled,
    onResizeDragStart,
    dragEventEnabled,
    stopDragging,
    dragAndDropState
  } = useCalendarEventDragAndDrop({
    id: event.altEvent.id,
    isMobile,
    onResizeDrop,
    eventStyle,
    type,
    hourSizeInPx
  });
  const appointmentId = event.type === "alternative_event_scheduled" && event.altEvent.event.id || null;
  let onDragStart;
  if (event.type === "alternative_event_scheduled" && event.altEvent && dragEventEnabled) {
    const type2 = event.altEvent.parent_event ? DraggingEvent.recurringaltevent : DraggingEvent.altevent;
    onDragStart = getCalendarEventOnDragStart({
      meld: event.altEvent,
      coordinates: event.coordinates,
      additionalCallback: startDraggingEvent,
      appointmentId,
      type: type2,
      personaType: null,
      personaIds: event.altEvent.management_attendees
    });
  }
  const pointerEventsDisabled = dragAndDropState !== null && dragAndDropState.type !== "resizingEvent" && dragAndDropState.id !== event.altEvent.id;
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        width: type === "row" ? size : eventStyle.width,
        height: type === "column" ? size : eventStyle.height,
        position: "relative",
        left: eventStyle.left,
        top: eventStyle.top,
        pointerEvents: pointerEventsDisabled ? "none" : void 0
      },
      ref: eventRef
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: __spreadProps(__spreadValues({}, eventStyle), {
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          cursor: dragEventEnabled && "grab" || void 0
        }),
        onClick: event.onClick,
        "data-testid": `meld-calendar-alternative-event-${event.description}`,
        draggable: dragEventEnabled,
        onDragStart,
        onDragEnd: stopDragging
      },
      /* @__PURE__ */ React.createElement(PmText, { style: EventTextStyles, color: getEventColor(event).text, fontSize: "p3" }, event.description)
    ),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          width: type === "row" ? "10px" : "100%",
          height: type === "row" ? "100%" : "10px",
          backgroundColor: getEventColor(event).bg,
          borderTopRightRadius: type === "row" ? eventStyle.borderRadius : void 0,
          borderBottomRightRadius: eventStyle.borderRadius,
          borderBottomLeftRadius: type === "row" ? void 0 : eventStyle.borderRadius,
          borderTop: type === "row" ? eventStyle.border : void 0,
          borderRight: eventStyle.border,
          borderBottom: eventStyle.border,
          borderLeft: type === "row" ? void 0 : eventStyle.border,
          position: "absolute",
          left: type === "row" ? size - 10 : 0,
          top: type === "row" ? 0 : size - 10,
          cursor: resizeEnabled && (type === "row" ? "e-resize" : "ns-resize") || void 0,
          zIndex: eventStyle.zIndex
        },
        draggable: resizeEnabled,
        onDragStart: resizeEnabled ? onResizeDragStart : void 0
      }
    )
  );
};
const GoogleCalendarEvent = ({
  event,
  eventStyle,
  dragAndDropState
}) => {
  const pointerEventsDisabled = dragAndDropState !== null;
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: __spreadProps(__spreadValues({}, eventStyle), { pointerEvents: pointerEventsDisabled ? "none" : void 0 }),
      onClick: event.onClick,
      "data-testid": `meld-calendar-google-event-${event.description}`
    },
    /* @__PURE__ */ React.createElement(PmText, { style: EventTextStyles, color: getEventColor(event).text, fontSize: "p3" }, event.description)
  );
};
const OutlookCalendarEvent = ({
  event,
  eventStyle,
  dragAndDropState
}) => {
  const pointerEventsDisabled = dragAndDropState !== null;
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: __spreadProps(__spreadValues({}, eventStyle), { pointerEvents: pointerEventsDisabled ? "none" : void 0 }),
      onClick: event.onClick,
      "data-testid": `meld-calendar-outlook-event-${event.description}`
    },
    /* @__PURE__ */ React.createElement(PmText, { style: EventTextStyles, color: getEventColor(event).text, fontSize: "p3" }, event.description)
  );
};
const BatchRecommendedMeldEvent = ({ event, eventStyle, modalState }) => {
  const { floatingTargetRef, handleMouseEnter, handleMouseLeave } = useFloatingEventDetails();
  const pendingActions = useGetCalendarDraftModePendingActions();
  const ignoredRecommendations = useGetCalendarDraftModeIgnoredRecommendations();
  const borderStyles = {
    borderRadius: BorderRadius,
    border: `2px dashed ${getEventColor(event).text}`
  };
  if (pendingActions.some((action) => action.meldId === event.meld.id) || ignoredRecommendations.includes(event.recommendation.id)) {
    return null;
  }
  const pointerEventsDisabled = false;
  const text = event.description;
  const addressText = getMeldScheduledAddressText(event.meld) || "";
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        width: eventStyle.width,
        height: eventStyle.height,
        position: "relative",
        left: eventStyle.left,
        top: eventStyle.top,
        pointerEvents: pointerEventsDisabled ? "none" : void 0
      }
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: __spreadProps(__spreadValues({}, eventStyle), {
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          pointerEvents: "none",
          background: colors.brand.white,
          position: "absolute"
        }),
        "data-testid": `meld-calendar-draft-meld-event-${event.meld.id}-${event.personaType}-${event.personaId}`
      }
    ),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        ref: floatingTargetRef,
        style: __spreadProps(__spreadValues(__spreadProps(__spreadValues({}, eventStyle), {
          paddingRight: "4px",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          cursor: "pointer"
        }), borderStyles), {
          // 70% opacity
          background: eventStyle.background + "B3"
        }),
        onClick: event.onClick,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave
      },
      /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "none" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmText, { style: EventTextStyles, color: getEventColor(event).text, fontWeight: "semiBold", fontSize: "p3" }, text)), addressText !== void 0 && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmText, { style: EventTextStyles, color: getEventColor(event).text, fontSize: "p3" }, addressText)))
    ),
    /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", gutterSize: "s" }, /* @__PURE__ */ React.createElement(EuiFlexItem, null, /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          transform: "scale(.80)",
          position: "absolute",
          width: "16px",
          height: "16px",
          bottom: 8,
          right: 22,
          zIndex: eventStyle.zIndex,
          cursor: "pointer"
        },
        onClick: () => modalState.open({
          meld: { id: event.meld.id },
          type: "meldDroppedOnAgent",
          targetAgentId: event.personaId,
          startTime: event.start_moment,
          appointmentId: null,
          recommendationId: event.recommendation.id,
          sourceType: "draganddrop"
        }),
        "data-testid": "meld-calendar-draft-add-event",
        "aria-label": "Add recommended event to pending actions"
      },
      /* @__PURE__ */ React.createElement(PmCheckCircleOutline, { fill: getEventColor(event).text })
    )), /* @__PURE__ */ React.createElement(EuiFlexItem, null, /* @__PURE__ */ React.createElement(
      RemoveEventButton,
      {
        color: getEventColor(event).text,
        ariaLabel: "Reject this recommended event",
        onClick: () => getCalendarDraftModeActions().addIgnoredRecommendation(event.recommendation.id),
        zIndex: eventStyle.zIndex
      }
    )))
  );
};
const MeldRecommendEvent = ({
  event,
  eventStyle,
  dragAndDropState
}) => {
  const isDraggingMeld = dragAndDropState !== null;
  if (isDraggingMeld) {
    return null;
  }
  return /* @__PURE__ */ React.createElement("div", { style: __spreadValues({}, eventStyle), onClick: event.onClick }, /* @__PURE__ */ React.createElement(PmText, { style: EventTextStyles, color: getEventColor(event).text, fontSize: "p3" }, event.description));
};
const MeldAltEventPlaceholderEvent = ({
  event,
  eventStyle
}) => {
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: __spreadProps(__spreadValues({}, eventStyle), { borderRadius: "6px", fontSize: "12px" }),
      "data-testid": `meld-calendar-pending-alt-event-${event.personaId}`
    },
    /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", gutterSize: "xs", style: { justifyContent: "space-between" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmText, { style: EventTextStyles, color: getEventColor(event).text, fontSize: "p3" }, event.description)))
  );
};
const MeldPendingDraftEvent = ({
  event,
  eventStyle,
  type,
  isMobile,
  hourSizeInPx,
  isEventViewableDueToPropertyGroups
}) => {
  const { isHovered, floatingTargetRef, handleMouseEnter, handleMouseLeave } = useFloatingEventDetails();
  const borderStyles = {
    borderRadius: BorderRadius,
    border: `2px dashed ${getEventColor(event, isEventViewableDueToPropertyGroups).text}`
  };
  const onResizeDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const apptId = event.draftAction.type === "rescheduleAppointment" && event.draftAction.appointmentId || null;
    const newDurationInMin = newSize.current / hourSizeInPx * 60;
    if (event.draftAction.type !== "rescheduleAppointment") {
    } else if (!apptId) {
    } else if (newDurationInMin === differenceInMinutes(event.draftAction.endTime, event.draftAction.startTime)) {
    } else if ("startTime" in event.draftAction) {
      const endTime = addMinutes(event.draftAction.startTime, newDurationInMin);
      getCalendarDraftModeActions().updatePendingAction(event.draftAction.uuid, {
        type: "rescheduleAppointment",
        endTime
      });
    }
    stopResizingEvent();
    document.removeEventListener("drag", onResizeDrag);
    document.removeEventListener("drop", onResizeDrop);
    document.removeEventListener("dragover", calendarEventOnDragOver);
    setSize(getEventSize(eventStyle, type, hourSizeInPx));
  }, []);
  const {
    onResizeDrag,
    newSize,
    size,
    setSize,
    eventRef,
    startDraggingEvent,
    stopResizingEvent,
    resizeEnabled: resizeMeldEnabled,
    onResizeDragStart,
    dragMeldEnabled,
    stopDragging,
    dragAndDropState
  } = useCalendarEventDragAndDrop({
    id: event.draftAction.meldId,
    isMobile,
    onResizeDrop,
    eventStyle,
    type,
    hourSizeInPx
  });
  const eventTimeCanBeRescheduled = getCanRescheduleAppointment({
    availability_segment: { event: { dtstart: event.start, dtend: event.end } }
  });
  const dragEnabled = dragMeldEnabled && eventTimeCanBeRescheduled;
  const resizeEnabled = resizeMeldEnabled && eventTimeCanBeRescheduled;
  const appointmentId = event.draftAction.type === "rescheduleAppointment" && event.draftAction.appointmentId;
  const text = getMeldScheduledEventText(event, isEventViewableDueToPropertyGroups);
  const pointerEventsDisabled = dragAndDropState !== null && dragAndDropState.id !== event.draftAction.meldId;
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        width: type === "row" ? size : eventStyle.width,
        height: type === "column" ? size : eventStyle.height,
        position: "relative",
        left: eventStyle.left,
        top: eventStyle.top,
        pointerEvents: pointerEventsDisabled ? "none" : void 0
      },
      ref: eventRef
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: __spreadProps(__spreadValues({}, eventStyle), {
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          pointerEvents: "none",
          background: colors.brand.white,
          position: "absolute"
        }),
        "data-testid": `meld-calendar-draft-meld-event-${event.draftAction.meldId}-${event.personaType}-${event.personaId}`
      }
    ),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        ref: floatingTargetRef,
        style: __spreadProps(__spreadValues(__spreadProps(__spreadValues({}, eventStyle), {
          paddingRight: "4px",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          cursor: isEventViewableDueToPropertyGroups && dragEnabled && "grab" || "pointer"
        }), borderStyles), {
          // 70% opacity
          background: eventStyle.background + "B3"
        }),
        onClick: isEventViewableDueToPropertyGroups ? event.onClick : void 0,
        draggable: isEventViewableDueToPropertyGroups && dragEnabled,
        onDragStart: isEventViewableDueToPropertyGroups && event.draftAction.meldId && dragEnabled && appointmentId ? getCalendarEventOnDragStart({
          meld: { id: event.draftAction.meldId },
          coordinates: event.coordinates,
          additionalCallback: startDraggingEvent,
          appointmentId,
          type: "meld",
          personaType: event.personaType,
          personaIds: event.draftAction.personaIds,
          draftAction: event.draftAction
        }) : void 0,
        onDragEnd: isEventViewableDueToPropertyGroups ? stopDragging : void 0,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave
      },
      /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "none" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
        PmText,
        {
          style: EventTextStyles,
          color: getEventColor(event, isEventViewableDueToPropertyGroups).text,
          fontWeight: "semiBold",
          fontSize: "p3"
        },
        text
      )), event.addressText !== void 0 && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmText, { style: EventTextStyles, color: colors.neutrals.gray900, fontSize: "p3" }, event.addressText)))
    ),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          width: type === "row" ? "10px" : "100%",
          height: type === "row" ? "100%" : "10px",
          left: type === "row" ? size - 10 : 0,
          top: type === "row" ? 0 : size - 10,
          position: "absolute",
          background: colors.brand.white,
          pointerEvents: "none"
        }
      }
    ),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          width: type === "row" ? "10px" : "100%",
          height: type === "row" ? "100%" : "10px",
          borderTopRightRadius: type === "row" ? borderStyles.borderRadius : void 0,
          borderBottomRightRadius: borderStyles.borderRadius,
          borderBottomLeftRadius: type === "row" ? void 0 : borderStyles.borderRadius,
          borderTop: type === "row" ? borderStyles.border : void 0,
          borderRight: borderStyles.border,
          borderBottom: borderStyles.border,
          borderLeft: type === "row" ? void 0 : borderStyles.border,
          position: "absolute",
          left: type === "row" ? size - 10 : 0,
          top: type === "row" ? 0 : size - 10,
          cursor: isEventViewableDueToPropertyGroups && resizeEnabled && (type === "row" ? "e-resize" : "ns-resize") || void 0,
          zIndex: eventStyle.zIndex,
          // 0% opacity to show prior background color
          backgroundColor: getEventColor(event, isEventViewableDueToPropertyGroups).bg + "00"
        },
        draggable: isEventViewableDueToPropertyGroups && resizeEnabled,
        onDragStart: isEventViewableDueToPropertyGroups && resizeEnabled ? onResizeDragStart : void 0
      }
    ),
    /* @__PURE__ */ React.createElement(
      RemoveEventButton,
      {
        ariaLabel: "Remove draft action",
        onClick: () => getCalendarDraftModeActions().removePendingAction(event.draftAction.uuid),
        zIndex: eventStyle.zIndex
      }
    ),
    !isMobile && isHovered && dragAndDropState === null && event.draftAction.meldId && /* @__PURE__ */ React.createElement(FloatingEventDetails, { targetRef: floatingTargetRef, isVisible: isHovered })
  );
};
const MeldCalendarEvent = ({
  event,
  offsetIndex,
  offsetTotal,
  zIndex,
  type,
  selectedTimeFrame,
  activeRightpaneMeld,
  modalState,
  isMobile,
  hourSizeInPx
}) => {
  const pendingAltEvent = useCalendarStateAltEventSelectedAgentsTime();
  const dragAndDropState = useCalendarDragAndDropState();
  const { rightPaneState } = useGetSetActivePaneAndMap();
  const isEventViewableDueToPropertyGroups = useIsEventViewableDueToPropertyGroups(event);
  const numHours = event.end_moment.diff(event.start, "hours", true);
  const eventSize = `${100 / offsetTotal}%`;
  const eventSizeOffset = `${offsetIndex * (100 / offsetTotal)}%`;
  const minuteOffset = `${event.start_moment.minutes() / 60 * hourSizeInPx}px`;
  const offsetStyles = {};
  if (type === "row") {
    const eventWidthInPx = hourSizeInPx * numHours - 2;
    offsetStyles.height = eventSize;
    offsetStyles.left = minuteOffset;
    offsetStyles.width = eventWidthInPx > 0 ? `${eventWidthInPx}px` : "fit-content";
    offsetStyles.top = eventSizeOffset;
  } else {
    const eventHeightInPx = hourSizeInPx * numHours - 2;
    offsetStyles.width = eventSize;
    offsetStyles.top = minuteOffset;
    offsetStyles.height = eventHeightInPx > LINE_HEIGHT_IN_PX ? `${eventHeightInPx}px` : void 0;
    offsetStyles.left = eventSizeOffset;
  }
  const meldIsActive = event.meld && (activeRightpaneMeld == null ? void 0 : activeRightpaneMeld.id) === event.meld.id;
  const backgroundColor = getEventColor(event, isEventViewableDueToPropertyGroups).bg;
  const eventStyle = __spreadProps(__spreadValues({}, offsetStyles), {
    zIndex,
    background: backgroundColor,
    lineHeight: `${LINE_HEIGHT_IN_PX}px`,
    position: "relative",
    padding: `${PADDING_IN_PX}px`,
    borderRadius: "6px",
    cursor: "pointer",
    border: `1px solid ${meldIsActive ? backgroundColor : colors.brand.white}`,
    overflow: "hidden",
    boxShadow: meldIsActive ? "2px 4px 10px 0px rgba(0, 0, 0, 0.50)" : void 0
  });
  switch (event.type) {
    case "vendor_scheduled":
    case "management_scheduled":
      return /* @__PURE__ */ React.createElement(
        MeldScheduledEvent,
        {
          event,
          eventStyle,
          modalState,
          type,
          isMobile,
          hourSizeInPx,
          isEventViewableDueToPropertyGroups
        }
      );
    case "google_calendar_event":
      return /* @__PURE__ */ React.createElement(GoogleCalendarEvent, { event, eventStyle, dragAndDropState });
    case "outlook_calendar_event":
      return /* @__PURE__ */ React.createElement(OutlookCalendarEvent, { event, eventStyle, dragAndDropState });
    case "alternative_event_scheduled":
      if (rightPaneState.type === "alternativeEvent" && (pendingAltEvent == null ? void 0 : pendingAltEvent.eventId) === event.altEvent.id) {
        return null;
      }
      return /* @__PURE__ */ React.createElement(
        AlternativeEvent,
        {
          event,
          eventStyle,
          modalState,
          type,
          isMobile,
          hourSizeInPx
        }
      );
    case "recommend_meld_placeholder":
      return /* @__PURE__ */ React.createElement(MeldRecommendEvent, { event, eventStyle, dragAndDropState });
    case "pending_recommendation_event":
      return /* @__PURE__ */ React.createElement(
        BatchRecommendedMeldEvent,
        {
          event,
          eventStyle,
          modalState,
          isMobile,
          hourSizeInPx
        }
      );
    case "pending_alt_event":
      return /* @__PURE__ */ React.createElement(MeldAltEventPlaceholderEvent, { event, eventStyle });
    case "resident_availability":
      return /* @__PURE__ */ React.createElement(
        MeldResidentAvailabilityEvent,
        {
          isMobile,
          event,
          eventStyle,
          selectedTimeFrame,
          meld: event.meld,
          segment: event.segment,
          isEventViewableDueToPropertyGroups,
          dragAndDropState
        }
      );
    case "vendor_availability":
      return /* @__PURE__ */ React.createElement(
        MeldOfferedAvailabilityEvent,
        {
          isMobile,
          event,
          meld: event.meld,
          eventStyle,
          segment: event.segment.vendoravailabilitysegment,
          isEventViewableDueToPropertyGroups,
          dragAndDropState
        }
      );
    case "management_availability":
      return /* @__PURE__ */ React.createElement(
        MeldOfferedAvailabilityEvent,
        {
          isMobile,
          meld: event.meld,
          event,
          eventStyle,
          segment: event.segment.managementavailabilitysegment,
          isEventViewableDueToPropertyGroups,
          dragAndDropState
        }
      );
    case "pending_offered_availability":
      return /* @__PURE__ */ React.createElement(MeldPendingOfferedAvailabilityEvent, { event, eventStyle });
    case "pendingDraftAction":
      if (DraftActionsToHide[event.draftAction.type]) {
        return null;
      }
      return /* @__PURE__ */ React.createElement(
        MeldPendingDraftEvent,
        {
          event,
          eventStyle,
          type,
          isMobile,
          hourSizeInPx,
          isEventViewableDueToPropertyGroups
        }
      );
    default:
      return null;
  }
};
export { MeldCalendarEvent, getMeldScheduledAddressText };
