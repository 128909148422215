var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import {
  EuiButtonIcon,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiLink,
  EuiLoadingSpinner
} from "@elastic/eui";
import { Link, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import moment from "moment";
import { isFuture } from "date-fns";
import { PmPageLoading } from "@pm-frontend/shared/components/Loaders/PmPageLoading";
import { PmCallout } from "@pm-frontend/shared/components/PmCallout";
import { calendarMeldKeys, useBookResidentAvailability, useScheduleMeld } from "../queries";
import { PmText } from "@pm-frontend/shared/components/Text/PmText";
import {
  canAssignmentChange,
  getMeldDetailsLink,
  getMeldPriorityBadgeProps,
  getMeldProperty,
  getMeldStatusBadgeProps,
  isAgentInMeldPropertyGroups,
  isEstimateMeld,
  isMeldAssigned,
  isMeldSchedulable,
  getIsMeldScheduled,
  isPropertyLevelMeld,
  getMeldCoordinates,
  isClosed,
  getMeldPropertyGroups
} from "@pm-frontend/shared/utils/meld-utils";
import { getFormattedCityStateZip, getTwoLinePropertyNameAddress } from "@pm-frontend/shared/utils/property-utils";
import { PmBadge } from "@pm-frontend/shared/components/PmBadge";
import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { getFullName } from "@pm-frontend/shared/utils/agent-utils";
import { useGetAllMaintenance } from "@pm-frontend/shared/hooks/queries/useGetAllMaintenance";
import {
  AssignMaintenanceDropdown,
  getInitialSelectedMaintenance,
  useAssignMaintenanceField
} from "@pm-frontend/routes/Melds/shared/forms/MeldEditAssignmentForm";
import {
  getAssignedMaint,
  isMaintenanceManagementAgent
} from "@pm-frontend/shared/utils/assignment-utils";
import { PmFormStyling } from "@pm-frontend/shared/components/Forms/PmFormStyling";
import { PmEmptyButton } from "@pm-frontend/shared/components/Buttons/PmEmptyButton";
import {
  PmDescriptionList
} from "@pm-frontend/shared/components/DetailSection/PmDescriptionList";
import { usePatchAssignMeldMaintenance } from "@pm-frontend/routes/Melds/queries";
import { PmFilledButton } from "@pm-frontend/shared/components/Buttons/PmFilledButton";
import { BorderRadius, colors } from "@pm-frontend/styles";
import { getVendorDetailsLink, getVendorDisplayName } from "@pm-frontend/shared/utils/vendor-utils";
import {
  useCalendarStateActions,
  useCalendarStateScheduledSegments
} from "@pm-frontend/routes/Calendar/stores/calendarStateStore";
import URL from "@pm-shared/utils/url";
import { PmAvatar } from "@pm-frontend/shared/components/Avatar/PmAvatar";
import { ResidentPresenceRequiredBadge } from "../subcomponents/ResidentPresenceBadge";
import { isSegmentScheduled } from "../utils/utils";
import Features from "@pm-assets/js/common/feature-flags";
import { canAddAvailabilities } from "@pm-frontend/shared/utils/calendar-utils";
import {
  canCreateAssignMelds,
  canScheduleMelds,
  canViewEditReassignMelds
} from "@pm-frontend/shared/utils/permission-utils";
import { getDisplayStreetAndUnit } from "@pm-frontend/shared/utils/unit-utils";
import { MeldCalendarDatePickers } from "./subcomponents/MeldCalendarDatePickers";
import {
  AgentCreatedMultipleAppointmentsOnInitialSchedule,
  AgentCreatedAdditionalAppointment,
  AgentRescheduledAppointment,
  track,
  CalendarMeldAssign,
  CalendarMeldViewDetailsClicked
} from "@pm-app/utils/analytics";
import { useGetCalendarEventMetaData, useGetSetActivePaneAndMap } from "../utils/hooks";
import { useGetAgentMe } from "@pm-frontend/shared/hooks/queries/useGetAgents";
import {
  CALENDAR_TIMEFRAMES,
  useCalendarStateSelectedDate,
  useCalendarStateSelectedTimeFrame
} from "../stores/timeFrameStore";
import { sortDescendingAppointments } from "../maps/utils";
import { DraggingEvent, getCalendarEventOnDragStart } from "../utils/click-drag-and-drop-utils";
import { useCalendarDragAndDropStateActions } from "../stores/dragDropStore";
import { getCanRescheduleAppointment } from "@pm-frontend/shared/utils/appointment-utils";
import {
  getCalendarDraftModeActions,
  getCalendarDraftModeEnabled,
  getCalendarDraftModePendingActions,
  useCalendarDraftModeEnabled,
  useGetCalendarDraftModePendingActions
} from "../stores/draftModeStore";
import { getMeldScheduledAddressText } from "../subcomponents/MeldCalendarEvents";
const PersonaAvatarLine = ({
  name,
  id,
  type,
  created,
  meld
}) => {
  const nameText = /* @__PURE__ */ React.createElement(PmText, { fontSize: "p1" }, name);
  return /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, alignItems: "center", gutterSize: "m", key: id }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmAvatar, { name, persona: { created } })), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, type === "vendor" ? /* @__PURE__ */ React.createElement(
    Link,
    {
      to: {
        pathname: getVendorDetailsLink({ id }),
        state: {
          backText: "Back to calendar",
          backUrl: LinkHelper.normalize(RouteUrls.meldCalendar(meld))
        }
      }
    },
    /* @__PURE__ */ React.createElement(EuiLink, { style: { fontSize: "16px" } }, name)
  ) : nameText));
};
const getDraftModeAssignedMaint = (meld, pendingDraftActions) => {
  for (const action of pendingDraftActions) {
    if (action.type === "assignMeld" && action.meldId === meld.id) {
      if (action.assigneeType === "agent") {
        return {
          type: "ManagementAgent",
          in_house_servicers: action.maintenance,
          names: action.maintenance.map((agent) => getFullName(agent))
        };
      } else {
        return {
          type: "Vendor",
          vendor: action.maintenance[0],
          name: getVendorDisplayName(action.maintenance[0])
        };
      }
    }
  }
  return void 0;
};
const ReadonlyAssignedMaint = ({
  meld,
  setIsAssigning
}) => {
  const draftModeEnabled = useCalendarDraftModeEnabled();
  const pendingDraftActions = useGetCalendarDraftModePendingActions();
  const assignees = draftModeEnabled && getDraftModeAssignedMaint(meld, pendingDraftActions) || getAssignedMaint(meld);
  let body = "";
  if (!assignees) {
    body = /* @__PURE__ */ React.createElement(PmCallout, { message: "Meld is not assigned" });
  } else if (assignees.type === "ManagementAgent") {
    body = /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "s" }, assignees.in_house_servicers.map((agent) => /* @__PURE__ */ React.createElement(
      PersonaAvatarLine,
      {
        name: getFullName(agent),
        created: agent.created,
        id: agent.id,
        key: agent.id,
        type: "agent",
        meld
      }
    )));
  } else if (assignees.type === "Vendor") {
    body = /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "s" }, /* @__PURE__ */ React.createElement(
      PersonaAvatarLine,
      {
        name: assignees.name,
        created: assignees.vendor.created,
        id: assignees.vendor.id,
        type: "vendor",
        meld
      }
    ));
  } else if (assignees.type === "VendorInvite") {
    body = /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "s" }, /* @__PURE__ */ React.createElement(
      PersonaAvatarLine,
      {
        name: assignees.name,
        created: assignees.invite_assignment.created,
        id: assignees.invite_assignment.id,
        type: "agent",
        meld
      }
    ));
  }
  return /* @__PURE__ */ React.createElement(
    PmDescriptionList,
    {
      "data-testid": "meld-calendar-details-rightpane-readonly-assignment",
      listItems: [
        {
          title: "Assignees",
          description: body,
          titleAction: {
            text: assignees ? "Reassign" : "Assign",
            onClick: () => setIsAssigning(true),
            enabled: canViewEditReassignMelds && canCreateAssignMelds && canAssignmentChange(meld),
            dataTestId: "meld-calendar-details-rightpane-assign-link"
          }
        }
      ]
    }
  );
};
const EditAssignedMaint = ({
  meld,
  allMaintenance,
  setIsAssigning
}) => {
  const draftModeEnabled = useCalendarDraftModeEnabled();
  const eventMetaData = useGetCalendarEventMetaData();
  const [selected, setSelected] = useState(() => {
    if (draftModeEnabled) {
      const draftAssignedMaintenance = getDraftModeAssignedMaint(meld, getCalendarDraftModePendingActions());
      return getInitialSelectedMaintenance(meld, allMaintenance, draftAssignedMaintenance);
    }
    return getInitialSelectedMaintenance(meld, allMaintenance);
  });
  const { dropdownError, handleSelectedChange, setDropdownError, noSuggestions } = useAssignMaintenanceField({
    meld,
    allMaintenance,
    selected,
    setSelected
  });
  const queryClient = useQueryClient();
  const assignMutation = usePatchAssignMeldMaintenance(
    meld,
    !isMeldAssigned(meld) || selected.length > 0 ? "assign" : "unassign"
  );
  const onAssignmentSubmit = () => {
    const mappedSelected = selected.map((option) => allMaintenance.find((m) => m.composite_id === option.value)).filter(Boolean);
    if (mappedSelected.length > 1 && !mappedSelected.every(isMaintenanceManagementAgent)) {
      return;
    }
    if (!isMeldAssigned(meld) && mappedSelected.length === 0) {
      setDropdownError("Please make a maintenance selection");
      return;
    }
    if (draftModeEnabled) {
      const assignees = mappedSelected[0].type === "ManagementAgent" ? {
        assigneeType: "agent",
        maintenance: mappedSelected
      } : {
        assigneeType: "vendor",
        maintenance: mappedSelected
      };
      getCalendarDraftModeActions().addPendingAction(__spreadValues({
        type: "assignMeld",
        meldId: meld.id,
        date: null,
        personaIds: mappedSelected.map((p) => p.id),
        personaType: mappedSelected[0].type === "ManagementAgent" ? "agent" : "vendor",
        recommendationId: void 0
      }, assignees));
      setIsAssigning(false);
      return;
    }
    if (mappedSelected[0]) {
      track(
        CalendarMeldAssign(__spreadValues({
          personaType: mappedSelected[0].type === "ManagementAgent" ? "agent" : "vendor"
        }, eventMetaData))
      );
    }
    assignMutation.mutate(
      {
        maintenance: mappedSelected,
        user_groups: []
      },
      {
        onSuccess: () => {
          setIsAssigning(false);
          return queryClient.invalidateQueries(calendarMeldKeys.meldDetail(meld.id.toString()));
        },
        onError: (err) => {
          var _a, _b, _c;
          if (((_c = (_b = (_a = err.response) == null ? void 0 : _a.data) == null ? void 0 : _b.non_field_errors) == null ? void 0 : _c[0]) === "Meld is already assigned to this vendor") {
            setDropdownError("Meld is already assigned to this vendor");
          }
        }
      }
    );
  };
  return /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "column",
      gutterSize: "m",
      alignItems: "flexStart",
      "data-testid": "meld-calendar-details-rightpane-edit-assignment"
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(
      AssignMaintenanceDropdown,
      {
        dropdownError,
        allMaintenance,
        propertyGroups: getMeldPropertyGroups(meld),
        selected,
        handleSelectedChange,
        noSuggestions
      }
    )),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, gutterSize: "m", style: { flexWrap: "wrap" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      PmFilledButton,
      {
        text: !isMeldAssigned(meld) || selected.length > 0 ? "Assign" : "Unassign",
        onClick: onAssignmentSubmit,
        isLoading: assignMutation.isLoading
      }
    )), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmEmptyButton, { text: "Cancel", onClick: () => setIsAssigning(false) }))))
  );
};
const AssignedMaintenance = ({
  meld,
  allMaintenance
}) => {
  const [isAssigning, setIsAssigning] = useState(() => {
    if (getCalendarDraftModeEnabled()) {
      return false;
    }
    return !isMeldAssigned(meld);
  });
  if (isAssigning && canAssignmentChange(meld) && canViewEditReassignMelds && canCreateAssignMelds && !isEstimateMeld(meld)) {
    return /* @__PURE__ */ React.createElement(EditAssignedMaint, { meld, allMaintenance, setIsAssigning });
  } else {
    return /* @__PURE__ */ React.createElement(ReadonlyAssignedMaint, { meld, setIsAssigning });
  }
};
const getEventFromAppointment = (appt) => {
  return {
    id: appt.id,
    date: moment(appt.availability_segment.event.dtstart).startOf("day"),
    startTime: moment(appt.availability_segment.event.dtstart),
    endTime: appt.availability_segment.event.dtend ? moment(appt.availability_segment.event.dtend) : void 0,
    appointment: appt
  };
};
const getInitialMeldEvents = (meld, selectedDate, selectedTimeframe) => {
  var _a, _b;
  const result = [];
  if (((_a = meld.managementappointment) == null ? void 0 : _a.length) > 0) {
    meld.managementappointment.forEach((appt) => {
      if (!appt.availability_segment || Features.isMultipleAppointmentsEnabled() && !getCanRescheduleAppointment(appt)) {
        return;
      }
      result.push(
        getEventFromAppointment({
          id: appt.id,
          availability_segment: appt.availability_segment
        })
      );
    });
  } else if (((_b = meld.vendorappointment) == null ? void 0 : _b.length) > 0) {
    meld.vendorappointment.forEach((appt) => {
      if (!appt.availability_segment || Features.isMultipleAppointmentsEnabled() && !isEstimateMeld(meld) && !getCanRescheduleAppointment(appt)) {
        return;
      }
      result.push(getEventFromAppointment(appt));
    });
  }
  if (result.length > 0) {
    return result.sort((a, b) => {
      if (!a.startTime || !b.startTime) {
        return 0;
      }
      return a.startTime.isBefore(b.startTime) ? -1 : 1;
    });
  } else {
    const date = selectedTimeframe === CALENDAR_TIMEFRAMES.ONE_DAY ? moment(selectedDate) : moment();
    return [{ id: void 0, date, startTime: void 0, endTime: void 0, appointment: void 0 }];
  }
};
const ScheduleButton = ({
  onScheduleClick,
  isLoading,
  showScheduleText
}) => {
  return /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
    PmFilledButton,
    {
      text: showScheduleText ? "Schedule" : "Reschedule",
      onClick: onScheduleClick,
      isLoading,
      "data-testid": "calendar-rightpane-schedule-primary-button"
    }
  ));
};
const AddAnotherButton = ({ appendEvent }) => {
  return /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
    PmEmptyButton,
    {
      text: "Add Another",
      onClick: appendEvent,
      "data-testid": "calendar-rightpane-schedule-addanother-button"
    }
  ));
};
const getOnCancelClick = (meld, modalState, event) => {
  if (!event.id || !meld || !canScheduleMelds) {
    return;
  }
  const appointment = event.appointment;
  if (appointment === void 0) {
    return;
  }
  const appointmentIsInFuture = appointment.availability_segment && isFuture(new Date(appointment.availability_segment.event.dtstart));
  if (!appointmentIsInFuture) {
    return void 0;
  }
  const assignees = getAssignedMaint(meld);
  const personaType = (assignees == null ? void 0 : assignees.type) === "ManagementAgent" ? "agent" : "vendor";
  return () => {
    if (getCalendarDraftModeEnabled()) {
      getCalendarDraftModeActions().addPendingAction({
        type: "cancelAppointment",
        meldId: meld.id,
        appointmentId: appointment.id,
        recommendationId: void 0,
        date: new Date(appointment.availability_segment.event.dtstart),
        personaType,
        // not needed as we don't display anything for canceled appointments
        personaIds: []
      });
    } else {
      modalState.open({ type: "cancelAppointment", meldId: meld.id, appointmentId: appointment.id });
    }
  };
};
const ScheduleForm = ({ meld, isMobile, modalState }) => {
  var _a;
  const [dirtySets, setDirtySets] = useState({});
  const selectedDate = useCalendarStateSelectedDate();
  const draftModeEnabled = useCalendarDraftModeEnabled();
  const draftModePendingActions = useGetCalendarDraftModePendingActions();
  const selectedTimeFrame = useCalendarStateSelectedTimeFrame(isMobile);
  const {
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      events: getInitialMeldEvents(meld, selectedDate, selectedTimeFrame)
    }
  });
  const events = watch("events");
  const assignedMaintenance = getAssignedMaint(meld);
  const isMeldScheduledValue = getIsMeldScheduled(meld);
  const isAddingAppointment = isMeldScheduledValue && events.some((event) => event.id === void 0);
  const scheduleMeldMutation = useScheduleMeld(meld, {
    initiator: "form",
    actionType: !isMeldScheduledValue ? "scheduleMeld" : isAddingAppointment ? "addAppointment" : "rescheduleMeld"
  });
  const appendEvent = () => {
    if (draftModeEnabled) {
      return;
    }
    setValue("events", [
      ...events,
      { id: void 0, date: moment(selectedDate), startTime: void 0, endTime: void 0, appointment: void 0 }
    ]);
  };
  const onRescheduleClick = (index) => {
    const eventToReschedule = events[index];
    if (!eventToReschedule.id || !eventToReschedule.startTime || !eventToReschedule.endTime) {
      if (!eventToReschedule.startTime) {
        setError(`events.${index}.startTime`, { message: "Please enter a starting time" });
      }
      if (!eventToReschedule.endTime) {
        setError(`events.${index}.endTime`, { message: "Please enter an ending time" });
      }
      return;
    }
    const personaIds = (assignedMaintenance == null ? void 0 : assignedMaintenance.type) === "ManagementAgent" && assignedMaintenance.in_house_servicers.map((a) => a.id) || (assignedMaintenance == null ? void 0 : assignedMaintenance.type) === "Vendor" && [assignedMaintenance.vendor.id] || [];
    if (draftModeEnabled) {
      getCalendarDraftModeActions().addPendingAction({
        type: "rescheduleAppointment",
        meldId: meld.id,
        appointmentId: eventToReschedule.id,
        recommendationId: void 0,
        date: new Date(eventToReschedule.date.toISOString()),
        startTime: new Date(eventToReschedule.startTime.toISOString()),
        endTime: new Date(eventToReschedule.endTime.toISOString()),
        personaType: (assignedMaintenance == null ? void 0 : assignedMaintenance.type) === "ManagementAgent" ? "agent" : "vendor",
        personaIds,
        description: meld.brief_description,
        addressText: getMeldScheduledAddressText(meld) || ""
      });
      return;
    }
    track(AgentRescheduledAppointment());
    scheduleMeldMutation.mutate({
      isReschedulingAppointment: true,
      isAddingAppointment: false,
      events: null,
      startTime: eventToReschedule.startTime,
      durationInMin: eventToReschedule.endTime.diff(eventToReschedule.startTime, "minutes", true),
      appointmentId: eventToReschedule.id ? eventToReschedule.id : null
    });
  };
  const onScheduleClick = () => {
    if (draftModeEnabled) {
      return;
    }
    let startTime;
    let durationInMin;
    if (isAddingAppointment) {
      const lastIndex = events.length - 1;
      const lastEvent = events[lastIndex];
      if (!(lastEvent == null ? void 0 : lastEvent.startTime) || !lastEvent.endTime) {
        if (!lastEvent.startTime) {
          setError(`events.${lastIndex}.startTime`, { message: "Please enter a starting time" });
        }
        if (!lastEvent.endTime) {
          setError(`events.${lastIndex}.endTime`, { message: "Please enter an ending time" });
        }
        return;
      }
      startTime = lastEvent.startTime;
      durationInMin = lastEvent.endTime.diff(lastEvent.startTime, "minutes", true);
      track(AgentCreatedAdditionalAppointment());
    } else {
      const firstEvent = events[0];
      if (!(firstEvent == null ? void 0 : firstEvent.startTime) || !firstEvent.endTime) {
        if (!firstEvent.startTime) {
          setError(`events.${0}.startTime`, { message: "Please enter a starting time" });
        }
        if (!firstEvent.endTime) {
          setError(`events.${0}.endTime`, { message: "Please enter an ending time" });
        }
        return;
      }
      startTime = firstEvent.startTime;
      durationInMin = firstEvent.endTime.diff(firstEvent.startTime, "minutes", true);
    }
    if (events.length > 1) {
      track(AgentCreatedMultipleAppointmentsOnInitialSchedule(events.length));
    }
    scheduleMeldMutation.mutate({
      startTime,
      durationInMin,
      events,
      isReschedulingAppointment: false,
      isAddingAppointment,
      appointmentId: null
    });
  };
  const showScheduleText = ((_a = events[0]) == null ? void 0 : _a.id) === void 0 || Features.isMultipleAppointmentsEnabled();
  const now = moment();
  return /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "m", "data-testid": "meld-calendar-details-rightpane-schedule-form" }, events.map((event, index) => {
    var _a2, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
    if (draftModeEnabled) {
      if (draftModePendingActions.some(
        (action) => {
          var _a3;
          return action.type === "cancelAppointment" && action.meldId === meld.id && action.appointmentId === ((_a3 = event.appointment) == null ? void 0 : _a3.id);
        }
      )) {
        return null;
      }
    }
    const onDateChange = (date) => {
      clearErrors();
      const eventId = event.id;
      if (typeof eventId === "number") {
        setDirtySets((dirty) => __spreadProps(__spreadValues({}, dirty), { [eventId]: true }));
      }
      const newEventsValue = [...events];
      if (!newEventsValue[index]) {
        return;
      }
      if (!date || date.isBefore(now.clone().startOf("day"))) {
        return;
      }
      newEventsValue[index].date = date;
      const currentStartTime = newEventsValue[index].startTime;
      const currentEndTime = newEventsValue[index].endTime;
      if (currentStartTime) {
        const newStartTime = currentStartTime.clone();
        newStartTime.year(date.year());
        newStartTime.month(date.month());
        newStartTime.date(date.date());
        if (newStartTime.isBefore(now)) {
          newEventsValue[index].startTime = void 0;
          newEventsValue[index].endTime = void 0;
        } else {
          newEventsValue[index].startTime = newStartTime;
        }
      }
      if (currentEndTime && newEventsValue[index].startTime !== void 0) {
        const newEndTime = currentEndTime.clone();
        newEndTime.year(date.year());
        newEndTime.month(date.month());
        newEndTime.date(date.date());
        if (newEndTime.isBefore(now)) {
          newEventsValue[index].endTime = void 0;
        } else {
          newEventsValue[index].endTime = newEndTime;
        }
      } else {
        newEventsValue[index].endTime = void 0;
      }
      setValue("events", newEventsValue);
    };
    const onFirstTimeChange = (date) => {
      clearErrors();
      const eventId = event.id;
      if (typeof eventId === "number") {
        setDirtySets((dirty) => __spreadProps(__spreadValues({}, dirty), { [eventId]: true }));
      }
      const newEventsValue = [...events];
      if (!newEventsValue[index] || !date) {
        return;
      }
      const newDate = date.clone();
      newDate.year(newEventsValue[index].date.year());
      newDate.month(newEventsValue[index].date.month());
      newDate.date(newEventsValue[index].date.date());
      if (newDate.isBefore(now)) {
        return;
      }
      const currentStartTime = newEventsValue[index].startTime;
      newEventsValue[index].startTime = newDate;
      const currentEndTime = newEventsValue[index].endTime;
      let newEndTime;
      if (currentEndTime && currentStartTime) {
        const durationInHours = currentEndTime.diff(currentStartTime, "hours", true);
        newEndTime = newDate.clone().add(durationInHours, "hours");
      } else {
        newEndTime = newDate.clone().add(2, "hours");
      }
      if (!newDate.isSame(newEndTime, "day")) {
        newEndTime = void 0;
      }
      newEventsValue[index].endTime = newEndTime;
      setValue("events", newEventsValue);
    };
    const onSecondTimeChange = (date) => {
      clearErrors();
      const eventId = event.id;
      if (typeof eventId === "number") {
        setDirtySets((dirty) => __spreadProps(__spreadValues({}, dirty), { [eventId]: true }));
      }
      const newEventsValue = [...events];
      if (!newEventsValue[index] || !date) {
        return;
      }
      const newDate = date.clone();
      newDate.year(newEventsValue[index].date.year());
      newDate.month(newEventsValue[index].date.month());
      newDate.date(newEventsValue[index].date.date());
      const currentStartTime = newEventsValue[index].startTime;
      if (newDate.isBefore(now)) {
        return;
      }
      if (currentStartTime && (newDate.isBefore(currentStartTime) || newDate.diff(currentStartTime, "hours", true) > 8)) {
        return;
      }
      newEventsValue[index].endTime = newDate;
      setValue("events", newEventsValue);
    };
    const onRemoveClick = () => {
      clearErrors();
      const newEventsValue = [...events];
      newEventsValue.splice(index, 1);
      setValue("events", newEventsValue);
    };
    const removeLink = /* @__PURE__ */ React.createElement(EuiLink, { onClick: onRemoveClick, style: { fontSize: "16px" } }, "Remove");
    const onHoursClick = (hours) => {
      var _a3;
      clearErrors();
      const eventId = event.id;
      const newEventsValue = [...events];
      if (!newEventsValue[index]) {
        return;
      }
      const startTime = newEventsValue[index].startTime || now.clone();
      startTime.year(newEventsValue[index].date.year());
      startTime.month(newEventsValue[index].date.month());
      startTime.date(newEventsValue[index].date.date());
      let newEndTime = startTime.clone().add(hours, "hours");
      if (!newEndTime.isSame(startTime, "day")) {
        newEndTime = void 0;
      }
      if (typeof eventId === "number") {
        if (newEndTime === void 0) {
          setDirtySets((dirty) => __spreadProps(__spreadValues({}, dirty), { [eventId]: false }));
        } else if (((_a3 = event.endTime) == null ? void 0 : _a3.toISOString()) !== newEndTime.toISOString()) {
          setDirtySets((dirty) => __spreadProps(__spreadValues({}, dirty), { [eventId]: true }));
        }
      }
      newEventsValue[index].endTime = newEndTime;
      newEventsValue[index].startTime = startTime;
      setValue("events", newEventsValue);
    };
    const dateFieldsAreInvalid = !!((_b = (_a2 = errors.events) == null ? void 0 : _a2[index]) == null ? void 0 : _b.startTime) || !!((_d = (_c = errors.events) == null ? void 0 : _c[index]) == null ? void 0 : _d.endTime);
    let dateFieldsErrorText;
    if (dateFieldsAreInvalid) {
      const dateFieldErrors = [
        (_g = (_f = (_e = errors.events) == null ? void 0 : _e[index]) == null ? void 0 : _f.startTime) == null ? void 0 : _g.message,
        (_j = (_i = (_h = errors.events) == null ? void 0 : _h[index]) == null ? void 0 : _i.endTime) == null ? void 0 : _j.message
      ].filter((m) => typeof m === "string");
      if (errors) {
        dateFieldsErrorText = dateFieldErrors;
      }
    }
    const onCancelClick = getOnCancelClick(meld, modalState, event);
    return /* @__PURE__ */ React.createElement(
      EuiFlexItem,
      {
        grow: false,
        key: ((_k = event.id) == null ? void 0 : _k.toString()) || `new-${index}`,
        "data-testid": `meld-calendar-schedule-form-fields-${index}`
      },
      /* @__PURE__ */ React.createElement(
        EuiFormRow,
        {
          isInvalid: dateFieldsAreInvalid,
          error: dateFieldsErrorText,
          label: `Date and Time #${index + 1}`,
          labelAppend: event.id || index === 0 ? void 0 : removeLink
        },
        /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "m" }, /* @__PURE__ */ React.createElement(
          MeldCalendarDatePickers,
          {
            isMobile,
            event,
            eventIndex: index,
            onDateChange,
            onStartTimeChange: onFirstTimeChange,
            onEndTimeChange: onSecondTimeChange,
            errors,
            onCancelClick
          }
        ), event.date.isSameOrAfter(now, "dates") && /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: true, gutterSize: "m", style: { flexWrap: "wrap" } }, /* @__PURE__ */ React.createElement(EuiLink, { onClick: () => onHoursClick(2), style: { fontSize: "16px" } }, "2 hours"), /* @__PURE__ */ React.createElement(EuiLink, { onClick: () => onHoursClick(4), style: { fontSize: "16px" } }, "4 hours"), /* @__PURE__ */ React.createElement(EuiLink, { onClick: () => onHoursClick(8), style: { fontSize: "16px" } }, "8 hours")), event.id && Features.isMultipleAppointmentsEnabled() && dirtySets[event.id] && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { width: "fit-content" } }, /* @__PURE__ */ React.createElement(
          PmEmptyButton,
          {
            onClick: () => {
              onRescheduleClick(index);
              const eventId = event.id;
              if (typeof eventId === "number") {
                setDirtySets((dirty) => __spreadProps(__spreadValues({}, dirty), { [eventId]: false }));
              }
            },
            hasBorder: true,
            isLoading: scheduleMeldMutation.isLoading,
            text: draftModeEnabled ? "Add to draft" : "Reschedule",
            "data-testid": `calendar-rightpane-details-event-reschedule-link-${event.id}`
          }
        )))
      )
    );
  }), canScheduleMelds && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, gutterSize: "s", style: { flexWrap: "wrap" } }, /* @__PURE__ */ React.createElement(React.Fragment, null, !Features.isMultipleAppointmentsEnabled() && /* @__PURE__ */ React.createElement(
    ScheduleButton,
    {
      showScheduleText,
      isLoading: scheduleMeldMutation.isLoading,
      onScheduleClick
    }
  ), Features.isMultipleAppointmentsEnabled() && (isAddingAppointment || !isMeldScheduledValue) && /* @__PURE__ */ React.createElement(
    ScheduleButton,
    {
      showScheduleText,
      isLoading: scheduleMeldMutation.isLoading,
      onScheduleClick
    }
  ), Features.isMultipleAppointmentsEnabled() && !isAddingAppointment && !isEstimateMeld(meld) && /* @__PURE__ */ React.createElement(AddAnotherButton, { appendEvent })))));
};
const getGroupedSegments = (availabilities) => {
  const sortedAvailabilities = [...availabilities].sort((a, b) => a.event.dtstart.localeCompare(b.event.dtstart));
  if (sortedAvailabilities.length === 0) {
    return [];
  }
  let currentDateString = moment(sortedAvailabilities[0].event.dtstart).format("dddd, MMMM D");
  let currentIndex = 0;
  const result = [
    {
      dateText: currentDateString,
      segments: [sortedAvailabilities[0]]
    }
  ];
  for (let i = 1; i < sortedAvailabilities.length; i++) {
    const dateString = moment(sortedAvailabilities[i].event.dtstart).format("dddd, MMMM D");
    if (dateString !== currentDateString) {
      result.push({
        dateText: dateString,
        segments: [sortedAvailabilities[i]]
      });
      currentDateString = dateString;
      currentIndex++;
      continue;
    }
    result[currentIndex].segments.push(sortedAvailabilities[i]);
  }
  return result;
};
const NUM_APPTS_BEFORE_HIDING = 5;
const ResidentAppointments = ({
  residentSegments,
  meld
}) => {
  var _a;
  const draftModeEnabled = useCalendarDraftModeEnabled();
  const [isExpanded, setIsExpanded] = useState(false);
  const bookMutation = useBookResidentAvailability(meld, { initiator: "offeredAvailabilityRightpane" });
  const groupedSegments = getGroupedSegments(residentSegments);
  const hasScheduledAppointment = getIsMeldScheduled(meld);
  const scheduledSegments = useCalendarStateScheduledSegments();
  const { addScheduledSegment, removeScheduledSegment } = useCalendarStateActions();
  const scheduleMeldMutation = useScheduleMeld(meld, {
    initiator: "offeredAvailabilityRightpane",
    actionType: !hasScheduledAppointment ? "scheduleMeld" : Features.isMultipleAppointmentsEnabled() ? "addAppointment" : "rescheduleMeld"
  });
  const onAddRequestedAppointment = (segment) => {
    const startTimeStr = segment.event.dtstart;
    const endTimeStr = segment.event.dtend;
    if (!startTimeStr || !endTimeStr) {
      return;
    }
    const startTime = moment(startTimeStr);
    const endTime = moment(endTimeStr);
    const durationInMin = endTime.diff(startTime, "minutes", true);
    scheduleMeldMutation.mutate({
      isReschedulingAppointment: false,
      isAddingAppointment: true,
      events: null,
      startTime,
      durationInMin,
      appointmentId: null
    });
  };
  const onAddRequestedAppointmentClick = (segment) => {
    if (draftModeEnabled) {
      return;
    }
    onAddRequestedAppointment(segment);
  };
  const onBookClick = (segment) => {
    if (draftModeEnabled) {
      return;
    }
    if (Features.isMultipleAppointmentsEnabled()) {
      addScheduledSegment(segment);
    } else {
      bookMutation.mutate({ ids: [segment.id] });
    }
  };
  let shownCount = 0;
  const description = /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "s", "data-testid": "meld-calendar-details-rightpane-availability-area" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", responsive: false, gutterSize: "xs" }, groupedSegments.map((group) => {
    if (!isExpanded && shownCount >= NUM_APPTS_BEFORE_HIDING) {
      return null;
    }
    return /* @__PURE__ */ React.createElement(React.Fragment, { key: group.dateText }, /* @__PURE__ */ React.createElement(
      EuiFlexGroup,
      {
        direction: "row",
        responsive: true,
        style: { flexWrap: "wrap", columnGap: "12px", rowGap: "4px" }
      },
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmText, { fontWeight: "semiBold" }, group.dateText))
    ), group.segments.map((segment) => {
      const isScheduled = isSegmentScheduled(segment, meld);
      if (!isExpanded && shownCount >= NUM_APPTS_BEFORE_HIDING) {
        return null;
      }
      shownCount++;
      const eventInPast = moment(segment.event.dtstart).isBefore(moment());
      return /* @__PURE__ */ React.createElement(
        EuiFlexGroup,
        {
          direction: "row",
          responsive: true,
          gutterSize: "xs",
          alignItems: "flexEnd",
          "data-testid": `meld-calendar-details-rightpane-availability-row-${segment.id}`,
          style: { flexWrap: "wrap", columnGap: "4px" },
          key: segment.id
        },
        /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { minWidth: "150px" } }, /* @__PURE__ */ React.createElement(PmText, { color: eventInPast ? colors.neutrals.gray600 : void 0 }, `${moment(segment.event.dtstart).format("h a")} - ${segment.event.dtend ? moment(segment.event.dtend).format("h a") : ""}`)),
        !eventInPast && isMeldSchedulable(meld) && canScheduleMelds && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, "data-testid": "meld-calendar-resident-row-schedule-link" }, isScheduled ? /* @__PURE__ */ React.createElement(PmText, { color: colors.interface.green.default }, "Scheduled") : hasScheduledAppointment ? scheduleMeldMutation.isLoading ? /* @__PURE__ */ React.createElement(EuiLoadingSpinner, { size: "m" }) : /* @__PURE__ */ React.createElement(
          EuiLink,
          {
            style: { fontSize: "16px" },
            onClick: () => Features.isMultipleAppointmentsEnabled() ? onAddRequestedAppointmentClick(segment) : onBookClick(segment)
          },
          Features.isMultipleAppointmentsEnabled() ? "Add Appointment" : "Schedule"
        ) : bookMutation.isLoading ? /* @__PURE__ */ React.createElement(EuiLoadingSpinner, { size: "m" }) : /* @__PURE__ */ React.createElement(
          EuiLink,
          {
            style: { textDecoration: "none" },
            onClick: () => {
              const isSelected = scheduledSegments.some((s) => s.id === segment.id);
              if (isSelected) {
                removeScheduledSegment(segment.id);
              } else {
                onBookClick(segment);
              }
            }
          },
          /* @__PURE__ */ React.createElement(
            "span",
            {
              style: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                color: scheduledSegments.some((s) => s.id === segment.id) ? colors.interface.green.default : void 0,
                fontSize: "18px"
              }
            },
            scheduledSegments.some((s) => s.id === segment.id) ? "Selected" : "Schedule"
          )
        ))
      );
    }));
  }))), !isExpanded && ((_a = meld.appointment_request) == null ? void 0 : _a.availabilities) && meld.appointment_request.availabilities.length > NUM_APPTS_BEFORE_HIDING && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "row",
      responsive: false,
      gutterSize: "none",
      onClick: () => setIsExpanded(true),
      style: { cursor: "pointer", height: "24px" }
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiLink, { style: { fontSize: "16px" } }, `Show ${meld.appointment_request.availabilities.length - NUM_APPTS_BEFORE_HIDING} more`)),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { justifyContent: "center" } }, /* @__PURE__ */ React.createElement(
      EuiIcon,
      {
        color: colors.brand.meldBlue,
        type: URL.getStatic("icons/expand-more-open-arrow.svg"),
        "aria-label": "See more resident availabilities",
        size: "m"
      }
    ))
  )), scheduledSegments.length > 0 && !hasScheduledAppointment && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
    PmFilledButton,
    {
      text: "Book Scheduled Segment",
      onClick: () => bookMutation.mutate({ ids: scheduledSegments.map((segment) => segment.id) }),
      isLoading: bookMutation.isLoading,
      "data-testid": "meld-calendar-book-scheduled-segment-button"
    }
  )));
  return /* @__PURE__ */ React.createElement(
    PmDescriptionList,
    {
      listItems: [
        {
          title: "Resident availability",
          description
        }
      ]
    }
  );
};
const getPastSegments = (meld) => {
  const result = [];
  if (!Features.isMultipleAppointmentsEnabled() || isEstimateMeld(meld)) {
    return result;
  }
  if (meld.managementappointment) {
    meld.managementappointment.forEach((appt) => {
      if (appt.availability_segment && !getCanRescheduleAppointment(appt)) {
        result.push(appt.availability_segment);
      }
    });
  }
  if (meld.vendorappointment) {
    meld.vendorappointment.forEach((appt) => {
      if (appt.availability_segment && !getCanRescheduleAppointment(appt)) {
        result.push(appt.availability_segment);
      }
    });
  }
  return result;
};
const PastSegments = ({ segments }) => {
  const formattedSegments = /* @__PURE__ */ React.createElement(React.Fragment, null, segments.sort(sortDescendingAppointments).map((segment) => /* @__PURE__ */ React.createElement(PmText, { key: segment.id + segment.created }, `${moment(segment.event.dtstart).format("dddd, MMMM D, h:mm a")} - ${segment.event.dtend ? moment(segment.event.dtend).format("h:mm a") : ""}`)));
  return /* @__PURE__ */ React.createElement(
    PmDescriptionList,
    {
      listItems: [
        {
          title: "Previous Appointments",
          description: formattedSegments,
          dataTestId: "meld-calendar-right-pane-details-past-appointments"
        }
      ]
    }
  );
};
const MeldAddress = ({ meld }) => {
  if (!meld) {
    return null;
  } else if (isPropertyLevelMeld(meld)) {
    return /* @__PURE__ */ React.createElement(PmText, { fontSize: "p1" }, getTwoLinePropertyNameAddress(getMeldProperty(meld)));
  } else if (meld.unit) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(PmText, { fontSize: "p1" }, getDisplayStreetAndUnit(meld.unit)), /* @__PURE__ */ React.createElement(PmText, { fontSize: "p1" }, getFormattedCityStateZip(meld.unit.prop)));
  }
  return null;
};
const MeldDragHandle = ({ meld }) => {
  const draftModeEnabled = useCalendarDraftModeEnabled();
  const { startDraggingMeld, stopDragging } = useCalendarDragAndDropStateActions();
  const assignees = getAssignedMaint(meld);
  const personaType = (assignees == null ? void 0 : assignees.type) === "ManagementAgent" && "agent" || (assignees == null ? void 0 : assignees.type) === "Vendor" && "vendor" || null;
  const personaIds = (assignees == null ? void 0 : assignees.type) === "ManagementAgent" && assignees.in_house_servicers.map((a) => a.id) || (assignees == null ? void 0 : assignees.type) === "Vendor" && [assignees.vendor.id] || [];
  return /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "row",
      onDragStart: getCalendarEventOnDragStart({
        meld,
        coordinates: getMeldCoordinates(meld),
        additionalCallback: startDraggingMeld,
        appointmentId: null,
        type: DraggingEvent.meld,
        personaType,
        personaIds
      }),
      onDragEnd: stopDragging,
      draggable: true,
      style: {
        cursor: "grab",
        background: colors.brand.veryLightBlue,
        borderRadius: BorderRadius,
        padding: "4px"
      },
      alignItems: "center",
      gutterSize: "xs",
      "data-testid": "calendar-meld-details-rightpane-drag-handle"
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiIcon, { type: "grabHorizontal" })),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmText, { fontSize: "p2" }, `Drag to ${draftModeEnabled ? "add" : "schedule"}`))
  );
};
const MeldCalendarMeldDetails = ({ meld, onClose, isMobile, modalState }) => {
  var _a, _b;
  const { updateRightPaneURL } = useGetSetActivePaneAndMap();
  const pendingDraftActions = useGetCalendarDraftModePendingActions();
  const draftModeEnabled = useCalendarDraftModeEnabled();
  const eventMetaData = useGetCalendarEventMetaData();
  const {
    data: currentAgentDetails,
    isError: currentAgentDetailsIsError,
    isLoading: currentAgentDetailsIsLoading
  } = useGetAgentMe();
  const queryClient = useQueryClient();
  const { meldId } = useParams();
  const meldDataQuery = meldId ? queryClient.getQueryState(calendarMeldKeys.meldDetail(meldId)) : null;
  const { data: allMaintenanceData, isLoading: maintIsLoading, isError: maintIsError } = useGetAllMaintenance();
  if ((meldDataQuery == null ? void 0 : meldDataQuery.status) === "error" || maintIsError || currentAgentDetailsIsError) {
    return /* @__PURE__ */ React.createElement(PmCallout, { message: "Something went wrong. Try reloading the page." });
  } else if (meld === void 0 || allMaintenanceData === void 0 || maintIsLoading || currentAgentDetailsIsLoading || currentAgentDetails === void 0) {
    return /* @__PURE__ */ React.createElement(PmPageLoading, null);
  } else if (!isAgentInMeldPropertyGroups(meld, currentAgentDetails)) {
    return /* @__PURE__ */ React.createElement(
      PmCallout,
      {
        message: "You do not have permission to view this Meld, as you are not in one of this Meld's property groups.",
        "data-testid": "meld-calendar-right-pane-meld-details"
      }
    );
  }
  const pastSegments = getPastSegments(meld);
  let scheduleArea = null;
  if (meld.status === "PENDING_VENDOR") {
    scheduleArea = /* @__PURE__ */ React.createElement(PmCallout, { message: "The vendor must accept the Meld before it can be scheduled" });
  } else if (isMeldSchedulable(meld)) {
    scheduleArea = /* @__PURE__ */ React.createElement(ScheduleForm, { meld, isMobile, modalState });
  } else if (isClosed(meld)) {
    scheduleArea = /* @__PURE__ */ React.createElement(PmCallout, { message: "Closed Melds cannot be scheduled" });
  }
  const offerAvailabilityLink = canAddAvailabilities(meld) && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true, "data-testid": "meld-calendar-details-offer-availability-link" }, /* @__PURE__ */ React.createElement(
    EuiLink,
    {
      onClick: () => updateRightPaneURL({ newRightpaneState: { type: "offerAvailabilities", meldId: meld.id.toString() } })
    },
    "Offer availability to resident"
  ));
  const assignMaintenanceKey = draftModeEnabled && ((_a = pendingDraftActions.find((action) => action.type === "assignMeld" && action.meldId === meld.id)) == null ? void 0 : _a.uuid) || "default";
  return /* @__PURE__ */ React.createElement(
    EuiFlexGrid,
    {
      columns: 1,
      style: { gridTemplateColumns: "100%" },
      "data-testid": "meld-calendar-right-pane-meld-details"
    },
    !onClose && !isMobile && /* @__PURE__ */ React.createElement(MeldDragHandle, { meld }),
    /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", alignItems: "center", responsive: false, justifyContent: "spaceBetween" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmText, { fontSize: "p1" }, meld.brief_description)), onClose !== void 0 && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiButtonIcon,
      {
        display: "empty",
        iconType: URL.getStatic("icons/close.svg"),
        "aria-label": "Close flyout",
        onClick: onClose
      }
    ))),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, className: "preWhiteSpace" }, /* @__PURE__ */ React.createElement(MeldAddress, { meld })),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiFlexGroup,
      {
        direction: "row",
        style: { maxWidth: "100%", flexWrap: "wrap", rowGap: "4px", columnGap: "8px" },
        alignItems: "center"
      },
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { lineHeight: "14px" } }, /* @__PURE__ */ React.createElement(PmText, { fontSize: "p1" }, "#", meld.reference_id)),
      meld.tenant_presence_required && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(ResidentPresenceRequiredBadge, null))
    )),
    /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", style: { maxWidth: "100%", flexWrap: "wrap", rowGap: "4px", columnGap: "8px" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmBadge, __spreadValues({}, getMeldStatusBadgeProps(meld)))), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmBadge, __spreadValues({}, getMeldPriorityBadgeProps(meld))))),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      Link,
      {
        to: {
          pathname: getMeldDetailsLink(meld),
          state: { backText: "Back to Calendar", backUrl: LinkHelper.normalize(RouteUrls.meldCalendar(meld)) }
        },
        onClick: () => track(CalendarMeldViewDetailsClicked(__spreadValues({ meldId: meld.id }, eventMetaData))),
        style: { width: "max-content" }
      },
      /* @__PURE__ */ React.createElement(EuiLink, { style: { fontSize: "16px" } }, isEstimateMeld(meld) ? "View estimate details" : "View details")
    )),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true, "data-testid": "meld-calendar-details-rightpane-assign-area" }, /* @__PURE__ */ React.createElement(AssignedMaintenance, { meld, allMaintenance: allMaintenanceData, key: assignMaintenanceKey })),
    meld.tenant_presence_required && ((_b = meld.appointment_request) == null ? void 0 : _b.availabilities) && meld.appointment_request.availabilities.length > 0 && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true }, /* @__PURE__ */ React.createElement(ResidentAppointments, { residentSegments: meld.appointment_request.availabilities, meld })),
    scheduleArea && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true, "data-testid": "meld-calendar-right-pane-schedule-area" }, /* @__PURE__ */ React.createElement(PmFormStyling, { error: void 0 }, /* @__PURE__ */ React.createElement(EuiForm, { fullWidth: true }, scheduleArea))),
    pastSegments.length > 0 && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true }, /* @__PURE__ */ React.createElement(PastSegments, { segments: pastSegments })),
    offerAvailabilityLink
  );
};
export { MeldCalendarMeldDetails, getInitialMeldEvents, getDraftModeAssignedMaint };
