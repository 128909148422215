import React from "react";

import { EuiFlexGroup } from "@elastic/eui";
import { UseBaseMutationResult } from "@tanstack/react-query";
import { AssignMeldMaintenanceData, useGetMeldNexusesByRange } from "../../queries";
import { MeldToBeAssigned } from "../../shared/flyouts/MeldAssignmentFlyout";
import { AllVendorsListRegisteredOnlyFalse } from "@pm-frontend/shared/types/api/maintenance/api";
import { getMeldProperty } from "@pm-frontend/shared/utils/meld-utils";
import { useGetMeldRecommendations } from "@pm-frontend/shared/hooks/queries/useGetRecommendations";
import { PmPageLoading } from "@pm-frontend/shared/components/Loaders/PmPageLoading";
import { AssignRecommendationCard } from "./AssignRecommendationCard";
import { NexusRecommendationCard } from "./NexusRecommendationCard";

interface AssignmentCardsProps {
  meld: MeldToBeAssigned;
  onClose: () => void;
  mutation: UseBaseMutationResult<unknown, unknown, AssignMeldMaintenanceData, unknown>;
  allMaintenance: AllVendorsListRegisteredOnlyFalse;
}

function trimZipCode(zip: string): string {
  return zip.split("-")[0];
}

const AssignmentCards = ({ meld, onClose, mutation, allMaintenance }: AssignmentCardsProps) => {
  const postcode = getMeldProperty(meld).postcode;

  const { data: recommendationData, isLoading: isRecsLoading } = useGetMeldRecommendations(meld);
  const { data: nexusVendorData, isLoading: isNexusLoading } = useGetMeldNexusesByRange(
    meld.id,
    postcode ? trimZipCode(postcode) : postcode
  );

  if (isNexusLoading || isRecsLoading || nexusVendorData === undefined || recommendationData === undefined) {
    return <PmPageLoading />;
  }

  return (
    <EuiFlexGroup gutterSize="m" responsive={true} style={{ alignItems: "stretch", flexWrap: "wrap" }}>
      {recommendationData && recommendationData.length > 0 && (
        <AssignRecommendationCard
          meld={meld}
          onClose={onClose}
          mutation={mutation}
          recommendationData={recommendationData}
        />
      )}
      {nexusVendorData && nexusVendorData.length > 0 && (
        <NexusRecommendationCard
          meld={meld}
          mutation={mutation}
          onClose={onClose}
          allMaintenance={allMaintenance}
          nexusVendorData={nexusVendorData}
        />
      )}
    </EuiFlexGroup>
  );
};

export { AssignmentCards };
