import React, { useState } from "react";
import { AxiosError } from "axios";

import { usePatchAssignMeldMaintenance } from "../../queries";
import { PmFormStyling } from "@pm-frontend/shared/components/Forms/PmFormStyling";
import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiText,
  EuiToolTip,
  EuiLink,
} from "@elastic/eui";
import { MaintTypes, MaintTypesDisplayNames } from "@pm-frontend/shared/types/meld";
import { AllVendorsListRegisteredOnlyFalse } from "@pm-frontend/shared/types/api/maintenance/api";
import { getInviteFullName } from "@pm-frontend/shared/utils/invite-utils";
import { getFullName } from "@pm-frontend/shared/utils/agent-utils";
import { colors } from "@pm-frontend/styles";
import {
  getAssignedMaint,
  getFilterByPropertyGroups,
  isMaintenanceManagementAgent,
  isMaintenanceVendor,
  isMaintenanceVendorInvite,
  isWorkersCompValid,
  isLiabilityInsuranceValid,
  isAppfolioComplianceValid,
  InviteProps,
  GetAssignedMaintenanceReturn,
} from "@pm-frontend/shared/utils/assignment-utils";
import { getVendorDisplayName } from "@pm-frontend/shared/utils/vendor-utils";
import { getMeldPropertyGroups, isMeldAssigned } from "@pm-frontend/shared/utils/meld-utils";
import URL from "@pm-shared/utils/url";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import { PmFilledButton } from "@pm-frontend/shared/components/Buttons/PmFilledButton";
import { PmEmptyButton } from "@pm-frontend/shared/components/Buttons/PmEmptyButton";
import { getIdFromText } from "@pm-frontend/shared/utils/idFromText";
import { MeldToBeAssigned } from "../flyouts/MeldAssignmentFlyout";
import { PmText } from "@pm-frontend/shared/components/Text/PmText";
import { getPersonaColor } from "@pm-frontend/shared/utils/color-utils";
import { AssignmentCards } from "../../MeldDetails/cards/AssignmentCards";

interface MeldEditAssignmentFormProps {
  meld: MeldToBeAssigned;
  onClose: () => void;
  allMaintenance: AllVendorsListRegisteredOnlyFalse;
}

const maintenanceFormLabel = (
  <EuiFlexGroup direction="row" responsive={false} gutterSize="s" alignItems="center">
    <EuiFlexItem grow={false}>
      <PmText fontWeight="bold" fontSize="p2">
        Select assignees
      </PmText>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiToolTip
        className="preWhiteSpace"
        content={`Vendors and Maintenance Technicians are filtered by the assigned Property Group.\n\nProperty group assignment for Maintenance Technicians is accessible from user management and Vendor is managed from the specific vendor property groups tab.`}
        anchorProps={{ style: { display: "flex" } }}
      >
        <EuiIcon type={URL.getStatic("icons/questionmark.svg")} aria-label="Show maintenance tooltip" size="s" />
      </EuiToolTip>
    </EuiFlexItem>
  </EuiFlexGroup>
);

const isOptionDisabled = (
  maint: { id: number; composite_id: string; vendor_managements?: Array<{ allow_assignments: boolean }> },
  selected: Array<EuiComboBoxOptionOption<string>>,
  validMaintenances: AllVendorsListRegisteredOnlyFalse
): string | undefined => {
  const isValid = validMaintenances.find((m) => m.id === maint.id);
  // maint must be in a valid property group
  if (!isValid) {
    return "(Not in property group)";
  }
  // vendors who are marked unassignable
  if (maint.vendor_managements?.[0]?.allow_assignments === false) {
    return "(Not assignable)";
  }

  // if nothing is selected we are valid
  if (selected.length === 0) {
    return undefined;
  }

  if (isMaintenanceManagementAgent(maint)) {
    // if the selected options include a vendor/invite then disable
    const typeNumber = selected[0].value?.charAt(0);
    if (typeNumber === "2") {
      return undefined;
    } else {
      return "(Vendor already selected)";
    }
  }

  // if a maintenance agent is selected then vendor/invite are invalid
  if (selected[0] && selected[0].value?.charAt(0) === "2") {
    return "(User already selected)";
  }

  return undefined;
};

// maps the all-maintenance endpoint to the select options, and also filters
// out based on property groups
const getOptions = (
  allMaintenance: AllVendorsListRegisteredOnlyFalse,
  selected: Array<EuiComboBoxOptionOption<string>>,
  propertyGroups: number[]
): Array<EuiComboBoxOptionOption<string>> => {
  let currentType = "";
  const filterFunction = getFilterByPropertyGroups(propertyGroups);
  const validMaintenances = allMaintenance.filter(filterFunction);
  return allMaintenance.reduce((result, maint) => {
    if (currentType !== maint.type) {
      currentType = maint.type;
      result.push({
        isGroupLabelOption: true,
        label: MaintTypesDisplayNames[maint.type],
      });
    }
    const disabledReason: string | undefined = isOptionDisabled(maint, selected, validMaintenances);

    let label = "";
    let color = "";
    switch (maint.type) {
      case MaintTypes.VENDOR:
        label = disabledReason ? `${getVendorDisplayName(maint)} ${disabledReason}` : getVendorDisplayName(maint);
        color = getPersonaColor(maint) || colors.brand.meldBlue;
        break;
      case MaintTypes.INVITED_VENDOR:
        label = disabledReason ? `${getInviteFullName(maint)} ${disabledReason}` : getInviteFullName(maint);
        color = colors.brand.veryLightBlue;
        break;
      case MaintTypes.MANAGEMENT_AGENT:
        label = disabledReason ? `${getFullName(maint)} ${disabledReason}` : getFullName(maint);
        color = getPersonaColor(maint) || colors.brand.meldBlue;
        break;
      default:
        break;
    }
    result.push({
      label,
      value: maint.composite_id,
      disabled: !!disabledReason,
      color,
    });
    return result;
  }, [] as Array<EuiComboBoxOptionOption<string>>);
};

const getInitialSelectedMaintenance = (
  meld: MeldToBeAssigned,
  allMaintenance: AllVendorsListRegisteredOnlyFalse,
  assignedMaintenanceOverride?: GetAssignedMaintenanceReturn<
    { id: number; created?: string; name?: string; email?: string },
    { id: number; first_name?: string; last_name?: string; created?: string },
    InviteProps
  >
) => {
  const assignedMaintenance = assignedMaintenanceOverride || getAssignedMaint(meld);
  switch (assignedMaintenance?.type) {
    case MaintTypes.MANAGEMENT_AGENT:
      return assignedMaintenance.in_house_servicers
        .map((ma, index) => {
          const maint = allMaintenance.find((m) => m.id === ma.id && m.type === MaintTypes.MANAGEMENT_AGENT);
          if (!maint) {
            return undefined;
          }
          return {
            label: assignedMaintenance.names[index] || "In-house technician",
            value: maint.composite_id,
            color: getPersonaColor(maint) || colors.brand.meldBlue,
          };
        })
        .filter(Boolean) as Array<EuiComboBoxOptionOption<string>>;
    case MaintTypes.VENDOR:
      // eslint-disable-next-line no-case-declarations
      const vendor = allMaintenance.find((m) => m.id === assignedMaintenance.vendor.id && m.type === MaintTypes.VENDOR);
      if (!vendor || vendor.type !== MaintTypes.VENDOR) {
        return [];
      }
      return [
        {
          label: assignedMaintenance.name,
          value: vendor.composite_id,
          color: getPersonaColor(vendor) || colors.brand.meldBlue,
        },
      ];
    case MaintTypes.INVITED_VENDOR:
      // eslint-disable-next-line no-case-declarations
      const invitedVendor = allMaintenance.find(
        (m) =>
          m.type === MaintTypes.INVITED_VENDOR &&
          m.email === assignedMaintenance.invite_assignment.invite_group.invite.email
      );
      if (!invitedVendor) {
        return [];
      }
      return [
        {
          label: assignedMaintenance.name,
          value: invitedVendor.composite_id,
          color: colors.brand.meldBlue,
        },
      ];
    default:
      return [];
  }
};

const DropdownErrorWithoutLink = ({ error }: { error: string }) => {
  return (
    <EuiFlexGroup direction="column" responsive={false} gutterSize="s" alignItems="flexStart">
      <EuiFlexItem>
        <EuiText>{error}</EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const DropdownErrorWithLink = ({ error, link, link_text }: { error: string; link: string; link_text: string }) => {
  return (
    <EuiFlexGroup direction="column" responsive={false} gutterSize="s" alignItems="flexStart">
      <EuiFlexItem>
        <EuiText>{error}</EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiLink href={link} target="_blank">
          {link_text}
        </EuiLink>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const getDropdownErrorProps = ({
  selectedValue,
  allMaintenance,
}: {
  selectedValue: EuiComboBoxOptionOption<string>;
  allMaintenance: MeldEditAssignmentFormProps["allMaintenance"];
}): DropdownErrorProps | null => {
  const selectedMaintenance = allMaintenance.find((maintenance) => maintenance.composite_id === selectedValue.value);
  if (selectedMaintenance?.type === MaintTypes.VENDOR) {
    const isAfComplianceValid = isAppfolioComplianceValid(selectedMaintenance);
    const workersCompValid = isWorkersCompValid(selectedMaintenance);
    const liabilityInsuranceValid = isLiabilityInsuranceValid(selectedMaintenance);
    if (!isAfComplianceValid) {
      return {
        error:
          "Vendor has expired compliance info in AppFolio, so their invoices can't sync. Please update in AppFolio.",
      };
    } else if (!workersCompValid && !liabilityInsuranceValid) {
      return {
        error: "Vendor's workers' compensation insurance and general liability insurance expired",
        link: LinkHelper.normalize(RouteUrls.vendorDetail(selectedMaintenance.id)),
        link_text: "Request vendor compliance changes",
      };
    } else if (!workersCompValid) {
      return {
        error: "Vendor's workers' compensation insurance expired",
        link: LinkHelper.normalize(RouteUrls.vendorDetail(selectedMaintenance.id)),
        link_text: "Request vendor compliance changes",
      };
    } else if (!liabilityInsuranceValid) {
      return {
        error: "Vendor's general liability insurance expired",
        link: LinkHelper.normalize(RouteUrls.vendorDetail(selectedMaintenance.id)),
        link_text: "Request vendor compliance changes",
      };
    }
  }
  return null;
};

type DropdownErrorProps = {
  error: string;
} & (
  | {
      link: string;
      link_text: string;
    }
  | {
      link?: never;
      link_text?: never;
    }
);
const DropdownError = (props: DropdownErrorProps) => {
  if (props.link) {
    return <DropdownErrorWithLink {...props} />;
  } else {
    return <DropdownErrorWithoutLink {...props} />;
  }
};

type UseAssignMaintenanceFieldProps = {
  allMaintenance: MeldEditAssignmentFormProps["allMaintenance"];
  meld: MeldEditAssignmentFormProps["meld"] | undefined;
} & (
  | {
      selected: EuiComboBoxOptionOption<string>[];
      setSelected: (arg0: EuiComboBoxOptionOption<string>[]) => void;
    }
  | {
      selected?: never;
      setSelected?: never;
    }
);

/***
 * This assignment field gets used a in a few places in different ways depending on the form.
 * This hook sets up the state and handlers for the combobox
 * This hook takes selected and setSelected as optional parameters as there are situations where the state needs to be
 * controlled by the caller
 */
const useAssignMaintenanceField = (props: UseAssignMaintenanceFieldProps) => {
  const [controlledSelected, setControlledSelected] = useState<Array<EuiComboBoxOptionOption<string>>>(() =>
    props.meld ? getInitialSelectedMaintenance(props.meld, props.allMaintenance) : []
  );
  const selected = props.selected || controlledSelected;
  const setSelected = props.setSelected || setControlledSelected;
  const [dropdownError, setDropdownError] = useState<React.ReactNode>(() => {
    if (selected.length === 1) {
      const dropdownErrorProps = getDropdownErrorProps({
        selectedValue: selected[0],
        allMaintenance: props.allMaintenance,
      });
      if (dropdownErrorProps) {
        return <DropdownError {...dropdownErrorProps} />;
      }
    }
    return null;
  });

  const noSuggestions =
    selected.length > 0 &&
    !!selected[0].value &&
    (isMaintenanceVendor({ composite_id: selected[0].value }) ||
      isMaintenanceVendorInvite({ composite_id: selected[0].value }));

  const handleSelectedChange = (selectedOptions: Array<EuiComboBoxOptionOption<string>>) => {
    setSelected(selectedOptions);
    if (selectedOptions.length === 1) {
      const selectedValue = selectedOptions[0];
      const dropdownErrorProps = getDropdownErrorProps({ selectedValue, allMaintenance: props.allMaintenance });
      if (dropdownErrorProps) {
        setDropdownError(<DropdownError {...dropdownErrorProps} />);
        return;
      }
    }
    setDropdownError(undefined);
  };

  return {
    noSuggestions,
    selected,
    setSelected,
    dropdownError,
    setDropdownError,
    handleSelectedChange,
  };
};

const AssignMaintenanceDropdown = ({
  dropdownError,
  allMaintenance,
  fullWidth = false,
  selected,
  propertyGroups,
  handleSelectedChange,
  noSuggestions,
  isLoading,
}: {
  dropdownError: React.ReactNode;
  allMaintenance: MeldEditAssignmentFormProps["allMaintenance"];
  fullWidth?: boolean;
  propertyGroups: number[];
  selected: Array<EuiComboBoxOptionOption<string>>;
  handleSelectedChange: (arg0: Array<EuiComboBoxOptionOption<string>>) => void;
  noSuggestions: boolean;
  isLoading?: boolean;
}) => {
  return (
    <EuiFormRow label={maintenanceFormLabel} isInvalid={!!dropdownError} error={dropdownError}>
      <EuiComboBox
        placeholder="Search vendors and technicians"
        isInvalid={!!dropdownError}
        data-testid="meld-details-edit-assignment-modal-maintenance-dropdown"
        options={getOptions(allMaintenance, selected, propertyGroups)}
        selectedOptions={selected}
        rowHeight={32}
        onChange={handleSelectedChange}
        noSuggestions={noSuggestions}
        fullWidth={fullWidth}
        isLoading={isLoading}
      />
    </EuiFormRow>
  );
};

const MeldEditAssignmentForm = ({ meld, allMaintenance, onClose }: MeldEditAssignmentFormProps) => {
  const formId = getIdFromText(`meld-details-edit-assignement-form-${meld.id}`);
  const { selected, dropdownError, handleSelectedChange, setDropdownError, noSuggestions } = useAssignMaintenanceField({
    meld,
    allMaintenance,
  });
  const mutation = usePatchAssignMeldMaintenance(
    meld,
    !isMeldAssigned(meld) || selected.length > 0 ? "assign" : "unassign"
  );

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const parsedUserGroups: number[] = [];
    const mappedSelected = selected
      .map((option) => allMaintenance.find((m) => m.composite_id === option.value))
      .filter(Boolean) as Array<Required<AllVendorsListRegisteredOnlyFalse>[number]>;
    // only multiple in-house techs can be assigned
    if (mappedSelected.length > 1 && !mappedSelected.every(isMaintenanceManagementAgent)) {
      return;
    }
    // userGroups are deprecated, but still in use for historical melds
    if (!isMeldAssigned(meld) && mappedSelected.length === 0) {
      setDropdownError("Please make a maintenance selection");
      return;
    }
    mutation.mutate(
      {
        maintenance: mappedSelected,
        user_groups: parsedUserGroups,
      },
      {
        onSuccess: onClose,
        onError: (err: AxiosError<{ non_field_errors?: string[] }>) => {
          if (err.response?.data?.non_field_errors?.[0] === "Meld is already assigned to this vendor") {
            setDropdownError("Meld is already assigned to this vendor");
          }
        },
      }
    );
  };

  return (
    <PmFormStyling error={undefined}>
      <EuiForm fullWidth={true} component="form" id={formId} onSubmit={onFormSubmit}>
        <EuiFlexGroup direction="column">
          <AssignMaintenanceDropdown
            dropdownError={dropdownError}
            allMaintenance={allMaintenance}
            propertyGroups={getMeldPropertyGroups(meld)}
            fullWidth={true}
            selected={selected}
            handleSelectedChange={handleSelectedChange}
            noSuggestions={noSuggestions}
          />
          <AssignmentCards meld={meld} onClose={onClose} mutation={mutation} allMaintenance={allMaintenance} />
        </EuiFlexGroup>
        <EuiFlexGroup
          direction="row"
          alignItems="flexStart"
          justifyContent="flexStart"
          style={{ gap: "16px", marginTop: "16px" }}
        >
          <EuiFlexItem grow={false}>
            <PmFilledButton
              color="primary"
              isLoading={mutation.isLoading}
              formId={formId}
              text={!isMeldAssigned(meld) || selected.length > 0 ? "Assign" : "Unassign"}
              minWidth="121px"
              data-testid="meld-details-assign-modal-submit-button"
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <PmEmptyButton onClick={onClose} text={"Close"} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </PmFormStyling>
  );
};

export { MeldEditAssignmentForm, useAssignMaintenanceField, AssignMaintenanceDropdown, getInitialSelectedMaintenance };
