import React from "react";
import { EuiDatePicker, EuiDatePickerRange, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiIcon } from "@elastic/eui";
import moment, { Moment } from "moment";
import { FieldErrors } from "react-hook-form";

import { canScheduleMelds } from "@pm-frontend/shared/utils/permission-utils";
import { CalendarScheduleFormData } from "../MeldCalendarMeldDetails";
import { getScheduleFormMaxEndTime, getScheduleFormMinEndTime, getScheduleFormMinStartTime } from "../../utils/utils";
import { MobileDateTimePicker } from "@pm-frontend/shared/components/DatePickerMobile/MobileDateTimePicker";
import URL from "@pm-shared/utils/url";

interface MeldCalendarDatePickersProps {
  isMobile: boolean;
  event: {
    id: number | string | undefined;
    date: Moment;
    startTime: Moment | undefined;
    endTime: Moment | undefined;
  };
  eventIndex: number;
  onStartTimeChange: (date: Moment | null) => void;
  onEndTimeChange: (date: Moment | null) => void;
  onDateChange: (date: Moment | null) => void;
  errors: FieldErrors<CalendarScheduleFormData>;
  onCancelClick?: VoidFunction;
}

// used to control mobile vs desktop date pickers
const MeldCalendarDatePickers = ({
  isMobile,
  event,
  onStartTimeChange,
  onEndTimeChange,
  onDateChange,
  eventIndex,
  errors,
  onCancelClick,
}: MeldCalendarDatePickersProps) => {
  if (isMobile) {
    const handleDateTimeChange = ({
      newDate,
      newTime,
      handler,
    }: {
      newDate?: string;
      newTime?: string;
      handler: (date: moment.Moment | null) => void;
    }) => {
      // we build a time using the new date/time, currently selected date/time or now
      // s/ms were causing leading to unexpected 'hours' calculations (0.99 instead of 1)
      const resultDate = moment().milliseconds(0).seconds(0);

      const date = (newDate && moment(newDate, "YYYY-MM-DD")) || event.date;

      resultDate.set({
        year: date.get("year"),
        month: date.get("month"),
        date: date.get("date"),
      });

      const time = (newTime && moment(newTime, "HH:mm")) || resultDate;

      resultDate.set({
        hour: time.get("hour"),
        minute: time.get("minute"),
      });

      handler(resultDate);
    };
    return (
      <EuiFlexGrid
        columns={onCancelClick ? 2 : 1}
        style={{ gridTemplateColumns: onCancelClick ? "1fr 16px" : "1fr" }}
        gutterSize="s"
      >
        <EuiFlexItem grow={true}>
          <EuiFlexGrid columns={1} style={{ gridTemplateColumns: "1fr" }} gutterSize="s">
            <EuiFlexGroup responsive={false} alignItems="center" gutterSize="s" justifyContent="spaceBetween">
              <EuiFlexItem grow={false}>
                <EuiIcon type="calendar" />
              </EuiFlexItem>
              <EuiFlexItem grow={true}>
                <MobileDateTimePicker
                  type="date"
                  value={event.date ? event.date.format("YYYY-MM-DD") : ""}
                  onChange={(e) => {
                    handleDateTimeChange({
                      newDate: e.currentTarget.value,
                      handler: onDateChange,
                    });
                  }}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup responsive={false} gutterSize="s" justifyContent="spaceBetween" alignItems="center">
                <EuiFlexItem grow={false}>
                  <EuiIcon type="clock" />
                </EuiFlexItem>
                <EuiFlexItem grow={true}>
                  <MobileDateTimePicker
                    type="time"
                    value={event.startTime ? event.startTime.format("HH:mm") : ""}
                    onChange={(e) => {
                      handleDateTimeChange({
                        newTime: e.currentTarget.value,
                        handler: onStartTimeChange,
                      });
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup responsive={false} gutterSize="s" justifyContent="spaceBetween" alignItems="center">
                <EuiFlexItem grow={false}>
                  <EuiIcon type="clock" />
                </EuiFlexItem>
                <EuiFlexItem grow={true}>
                  <MobileDateTimePicker
                    type="time"
                    value={event.endTime ? event.endTime.format("HH:mm") : ""}
                    onChange={(e) => {
                      handleDateTimeChange({
                        newTime: e.currentTarget.value,
                        handler: onEndTimeChange,
                      });
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGrid>
        </EuiFlexItem>
        {onCancelClick && (
          <EuiFlexItem
            grow={false}
            style={{ paddingTop: "12px" }}
            data-testid={`meld-calendar-rightpane-cancel-appointment-button-${event.id}`}
          >
            <EuiIcon
              type={URL.getStatic("icons/close_circle.svg")}
              onClick={onCancelClick}
              style={{
                cursor: "pointer",
                width: "16px",
                height: "16px",
              }}
            />
          </EuiFlexItem>
        )}
      </EuiFlexGrid>
    );
  }

  const now = moment();

  const minStartTime = getScheduleFormMinStartTime(event, now);
  const minEndTime = getScheduleFormMinEndTime(event, now);
  const maxEndTime = getScheduleFormMaxEndTime(event, now);

  return (
    <EuiFlexGroup direction="row" responsive={false} gutterSize="xs" alignItems="flexStart">
      <EuiFlexItem grow={true}>
        <EuiFlexGroup direction="column" responsive={false} gutterSize="s">
          <EuiFlexItem grow={false} data-testid={`meld-calendar-schedule-form-date-field-${eventIndex}`}>
            <EuiDatePicker
              id={event.id?.toString() || `new-${eventIndex}`}
              selected={event.date}
              onChange={onDateChange}
              minDate={now.clone().startOf("day")}
              disabled={!canScheduleMelds}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiDatePickerRange
              iconType="clock"
              startDateControl={
                <EuiDatePicker
                  onChange={onStartTimeChange}
                  showTimeSelect={true}
                  selected={event.startTime}
                  showTimeSelectOnly={true}
                  timeFormat="h:mm A"
                  dateFormat="h:mm A"
                  minTime={minStartTime}
                  maxTime={event.date.clone().endOf("day")}
                  disabled={!canScheduleMelds}
                  isInvalid={!!errors.events?.[eventIndex]?.startTime?.message}
                />
              }
              endDateControl={
                <EuiDatePicker
                  onChange={onEndTimeChange}
                  showTimeSelect={true}
                  selected={event.endTime}
                  showTimeSelectOnly={true}
                  timeFormat="h:mm A"
                  dateFormat="h:mm A"
                  minTime={minEndTime}
                  maxTime={maxEndTime}
                  disabled={!canScheduleMelds}
                  isInvalid={!!errors.events?.[eventIndex]?.endTime?.message}
                />
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      {onCancelClick && (
        <EuiFlexItem
          grow={false}
          style={{ paddingTop: "12px" }}
          data-testid={`meld-calendar-rightpane-cancel-appointment-button-${event.id}`}
        >
          <EuiIcon
            type={URL.getStatic("icons/close_circle.svg")}
            onClick={onCancelClick}
            style={{
              cursor: "pointer",
              width: "16px",
              height: "16px",
            }}
          />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
};
export { MeldCalendarDatePickers };
