import { CALENDAR_TIMEFRAMES, CALENDAR_TIMEFRAMES_TYPE } from "@pm-frontend/routes/Calendar/stores/timeFrameStore";
import { getFirstColumnMoment, getNumberCalendarDateColumns } from "@pm-frontend/routes/Calendar/utils/utils";
import { ActiveVendorsListRecommendations } from "@pm-frontend/shared/types/api/recommend/serializers/agent_vendor_serializers";
import { BaseMeldRecommendationsSerializer } from "@pm-frontend/shared/types/api/recommend/serializers/base_serializers";
import { BatchMeldRecommendationDetailSerializer } from "@pm-frontend/shared/types/api/recommend/serializers/batch_meld_serializer";
import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { apiFetch, apiPost } from "@pm-frontend/shared/utils/apiFetch";
import { LinkHelper } from "@pm-shared/utils/link";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";

const recommendKeys = {
  all: ["recommendations"],
  meldRecommendations: (meldId: string) => [...recommendKeys.all, "meld_recommendations", meldId],
  recommendUsers: (id: string) => [...recommendKeys.all, "recommend_users", id],
  agentRecommendations: (id: string) => [...recommendKeys.all, "agent_recommendations", id],
  batchMeldRecommendations: (agentIds: number[], start: string, end: string) => [
    ...recommendKeys.all,
    "batch_meld_recommendations",
    "agent",
    agentIds.toString(),
    "start",
    start.toString(),
    "end",
    end.toString(),
  ],
  mutations: {
    createAgentRecommendations: () => [...recommendKeys.all, "create_recommendations"],
    createBatchScheduleRecommendations: () => [...recommendKeys.all, "create_batch_recommendation"],
  },
} as const;

const useGetMeldRecommendations = (meld: { id: number }) => {
  return useQuery<ActiveVendorsListRecommendations>({
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.recommend.recommendUsersList(meld.id.toString()))),
    queryKey: recommendKeys.meldRecommendations(meld.id.toString()),
  });
};

const useGetAgentRecommendations = (agent_id: number) => {
  return useQuery<BaseMeldRecommendationsSerializer[]>({
    queryKey: recommendKeys.agentRecommendations(agent_id.toString()),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.recommend.recommendMeldsList(agent_id.toString()))),
    staleTime: 5000,
  });
};

const useGetBatchScheduledMeldRecommendations = (
  agents: number[],
  start: number,
  selectedTimeFrame: CALENDAR_TIMEFRAMES_TYPE,
  enabled: boolean = true
) => {
  const agentsSelected = agents && agents.length > 0;
  const startOfRange =
    selectedTimeFrame === CALENDAR_TIMEFRAMES.WEEK ? getFirstColumnMoment(selectedTimeFrame, start) : moment(start);

  const start__gte = startOfRange.toISOString();
  const end__lt = startOfRange.add(getNumberCalendarDateColumns(selectedTimeFrame), "days").toISOString();

  return useQuery<BatchMeldRecommendationDetailSerializer[]>({
    queryKey: recommendKeys.batchMeldRecommendations(agents, start__gte, end__lt),
    queryFn: () =>
      apiFetch(LinkHelper.normalize(ApiUrls.recommend.recommendBatchMeldsList), {
        params: { agents, start__gte, end__lt },
      }),
    enabled: enabled && agentsSelected,
  });
};

const useCreateAgentRecommendation = () => {
  return useMutation({
    mutationKey: recommendKeys.mutations.createAgentRecommendations(),
    mutationFn: (data: { recommend_type: "MELDS"; agents: number[] }) => {
      return apiPost(LinkHelper.normalize(ApiUrls.recommend.recommendCreate), data);
    },
  });
};

const useCreateBatchScheduleRecommendation = () => {
  return useMutation({
    mutationKey: recommendKeys.mutations.createBatchScheduleRecommendations(),
    mutationFn: (data: {
      type: string;
      start__gte: number;
      end__lt: number;
      meld_filter_choice: string | null;
      default_appt_length: 2;
      excluded_melds: number[];
      agents: number[];
    }) => {
      return apiPost(LinkHelper.normalize(ApiUrls.recommend.recommendBatchCreate), data);
    },
  });
};

export {
  recommendKeys,
  useGetMeldRecommendations,
  useGetAgentRecommendations,
  useGetBatchScheduledMeldRecommendations,
  useCreateAgentRecommendation,
  useCreateBatchScheduleRecommendation,
};
