import React, { useCallback, useEffect, useRef, useState } from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLoadingSpinner } from "@elastic/eui";
import moment from "moment";
import ReactDOM from "react-dom";
import { addMinutes, differenceInMinutes } from "date-fns";

import { BorderRadius, colors } from "@pm-frontend/styles";
import { PmText } from "@pm-frontend/shared/components/Text/PmText";
import URL from "@pm-shared/utils/url";
import { useBookResidentAvailability, useEditAlternativeEvent, useScheduleMeld } from "../queries";
import {
  AppointmentRequestDetailViewSerializer,
  SchedulableMeldDetailViewSerializer,
} from "@pm-frontend/shared/types/api/meld/serializers/scheduable_meld_detail_view_serializer";
import { isSegmentScheduled } from "../utils/utils";
import { getMeldProperty, isMeldAssigned, isMeldSchedulable } from "@pm-frontend/shared/utils/meld-utils";
import { canScheduleMelds } from "@pm-frontend/shared/utils/permission-utils";
import {
  useCalendarStateActions,
  useCalendarStateAltEventSelectedAgentsTime,
  useCalendarStateMeldResidentAvailabilities,
} from "@pm-frontend/routes/Calendar/stores/calendarStateStore";
import Features from "@pm-assets/js/common/feature-flags";
import {
  calendarEventOnDragOver,
  CalendarPendingAvailabilityEventDetail,
  CALENDAR_PENDING_AVAILABILITY_EVENT_NAME,
  DraggingEvent,
  getCalendarEventOnDragStart,
} from "../utils/click-drag-and-drop-utils";
import { MeldCalendarModalState } from "../MeldCalendar";
import { AggregatedCalendarEvent } from "../utils/aggregated-calendar-events-utils";
import { getPersonaColor } from "@pm-frontend/shared/utils/color-utils";
import { useGetSetActivePaneAndMap, useIsEventViewableDueToPropertyGroups } from "../utils/hooks";
import {
  CalendarDragAndDropState,
  useCalendarDragAndDropState,
  useCalendarDragAndDropStateActions,
} from "../stores/dragDropStore";
import { getPropertyNameDistinctFromLine1 } from "@pm-frontend/shared/utils/property-utils";
import { MeldCalendarDetailsCard } from "../rightpane/MeldCalendarMeldsList";
import { CALENDAR_TIMEFRAMES, CALENDAR_TIMEFRAMES_TYPE } from "../stores/timeFrameStore";
import { getCanRescheduleAppointment } from "@pm-frontend/shared/utils/appointment-utils";
import {
  CalendarDraftPendingActions,
  getCalendarDraftModeActions,
  getCalendarDraftModeEnabled,
  useCalendarDraftModeEnabled,
  useGetCalendarDraftModeIgnoredRecommendations,
  useGetCalendarDraftModePendingActions,
} from "../stores/draftModeStore";
import { makeCompositeId } from "@pm-frontend/shared/utils/assignment-utils";
import { PmEmptyButton } from "@pm-frontend/shared/components/Buttons/PmEmptyButton";
import { PmXCircleOutline } from "@pm-frontend/assets/icons/components/PmXCircleOutline";
import { PmCheckCircleOutline } from "@pm-frontend/assets/icons/components/PmCheckCircleOutline";

// just a utility type for grabbing two specific variants of AggregatedCalendarEvent union
type ExtractMember<U, T extends U[keyof U]> = U extends { type: T } ? U : never;

const DraftActionsToHide: Partial<Record<CalendarDraftPendingActions["type"], boolean>> = {
  assignMeld: false,
  cancelAppointment: false,
};

const PADDING_IN_PX = 2;
const LINE_HEIGHT_IN_PX = 16;

interface MeldCalendarEventProps {
  event: AggregatedCalendarEvent;
  sortedAggregatedEvents: AggregatedCalendarEvent[];
  offsetTotal: number;
  offsetIndex: number;
  zIndex: number;
  type: "row" | "column";
  selectedTimeFrame: CALENDAR_TIMEFRAMES_TYPE;
  activeRightpaneMeld: SchedulableMeldDetailViewSerializer | undefined;
  modalState: MeldCalendarModalState;
  isMobile: boolean;
  hourSizeInPx: number;
}

const getEventColor = (
  event: AggregatedCalendarEvent,
  isEventViewableDueToPropertyGroups: boolean = true
): { text: string; bg: string } => {
  if (!isEventViewableDueToPropertyGroups) {
    return {
      text: colors.neutrals.gray900,
      bg: colors.neutrals.gray400,
    };
  }
  switch (event.type) {
    case "management_scheduled":
      return {
        text: colors.brand.black,
        bg: getPersonaColor(event.agent),
      };
    case "vendor_scheduled":
      return {
        text: colors.brand.black,
        bg: getPersonaColor(event.vendor),
      };
    case "pendingDraftAction":
      return {
        text: colors.brand.black,
        bg: getPersonaColor(event.persona),
      };
    case "google_calendar_event":
      return {
        text: colors.brand.white,
        bg: colors.neutrals.gray600,
      };
    case "outlook_calendar_event":
      return {
        text: colors.brand.white,
        bg: colors.neutrals.gray600,
      };
    case "recommend_meld_placeholder":
      return {
        text: colors.brand.darkHover,
        bg: colors.brand.lightBlue,
      };
    case "pending_recommendation_event":
      return {
        text: colors.brand.meldBlue,
        bg: colors.brand.white,
      };
    case "resident_availability":
      return {
        text: colors.neutrals.gray800,
        bg: colors.brand.white,
      };
    case "management_availability":
      return {
        text: colors.neutrals.gray800,
        bg: colors.brand.veryLightBlue,
      };
    case "vendor_availability":
      return {
        text: colors.neutrals.gray800,
        bg: colors.brand.veryLightBlue,
      };
    case "alternative_event_scheduled":
      return {
        text: colors.brand.white,
        bg: colors.neutrals.gray600,
      };
    case "pending_offered_availability":
      return {
        text: colors.neutrals.gray800,
        bg: colors.brand.veryLightBlue,
      };
    case "pending_alt_event":
      return {
        text: colors.neutrals.gray800,
        bg: colors.brand.veryLightBlue,
      };
    default:
      return {
        text: colors.brand.white,
        bg: colors.brand.meldBlue,
      };
  }
};

const EventTextStyles: React.CSSProperties = {
  padding: "2px",
  lineHeight: "16px",
  overflow: "hidden",
  overflowWrap: "break-word",
};

const RemoveEventButton = ({
  onClick,
  ariaLabel,
  zIndex,
  color = colors.neutrals.gray800,
}: {
  color?: string;
  onClick: VoidFunction;
  ariaLabel: string;
  zIndex: React.CSSProperties["zIndex"];
}) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "16px",
        height: "16px",
        // TODO: what if the icon is larger than the event?
        bottom: 6,
        right: 4,
        zIndex,
        cursor: "pointer",
      }}
      onClick={onClick}
      data-testid="meld-calendar-draft-remove-event"
      aria-label={ariaLabel}
    >
      <PmXCircleOutline fill={color} />
    </div>
  );
};

const MeldOfferedAvailabilityEvent = ({
  isMobile,
  event,
  eventStyle,
  segment,
  isEventViewableDueToPropertyGroups,
  dragAndDropState,
}: {
  isMobile: boolean;
  event: ExtractMember<AggregatedCalendarEvent, "management_availability" | "vendor_availability">;
  meld: ExtractMember<AggregatedCalendarEvent, "management_availability" | "vendor_availability">["meld"];
  eventStyle: React.CSSProperties;
  segment: { id: number };
  isEventViewableDueToPropertyGroups: boolean;
  dragAndDropState: CalendarDragAndDropState;
}) => {
  const { isHovered, floatingTargetRef, handleMouseEnter, handleMouseLeave } = useFloatingEventDetails();

  if (!isEventViewableDueToPropertyGroups) {
    return null;
  }
  const eventColor = getEventColor(event);
  const pointerEventsDisabled = dragAndDropState !== null;

  return (
    <div
      style={{
        ...eventStyle,
        borderRadius: "6px",
        fontSize: "12px",
        pointerEvents: pointerEventsDisabled ? "none" : undefined,
      }}
      onClick={event.onClick}
      data-testid={`meld-calendar-offered-availability-event-${event.personaType}-${event.personaId}-${segment.id}`}
      ref={floatingTargetRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <PmText style={EventTextStyles} color={eventColor.text} fontSize="p3">
        Time offered to resident
      </PmText>
      <PmText style={EventTextStyles} color={eventColor.text} fontSize="p3" fontWeight="semiBold">
        {event.description}
      </PmText>
      {!isMobile && isHovered && dragAndDropState === null && (
        <FloatingEventDetails targetRef={floatingTargetRef} isVisible={isHovered}>
          <MeldCalendarDetailsCard source="calendarEventHover" meld={event.meld} />
        </FloatingEventDetails>
      )}
    </div>
  );
};

const MeldPendingOfferedAvailabilityEvent = ({
  event,
  eventStyle,
}: {
  event: AggregatedCalendarEvent;
  eventStyle: React.CSSProperties;
}) => {
  const onRemoveClick = () => {
    if (getCalendarDraftModeEnabled()) {
      // TODO: batch action
      return;
    }
    // shouldn't occur
    if (event.type !== "pending_offered_availability") {
      return null;
    }
    const removePendingAvailability = new CustomEvent<CalendarPendingAvailabilityEventDetail>(
      CALENDAR_PENDING_AVAILABILITY_EVENT_NAME,
      {
        detail: {
          type: "removePendingAvailability",
          tempId: event.tempId,
        },
      }
    );
    window.dispatchEvent(removePendingAvailability);
  };
  return (
    <div
      style={{ ...eventStyle, borderRadius: "6px", fontSize: "12px" }}
      data-testid={`meld-calendar-pending-offered-availability-event-${event.personaId}`}
    >
      <EuiFlexGroup direction="row" gutterSize="xs" style={{ justifyContent: "space-between" }}>
        <EuiFlexItem grow={false}>
          <PmText style={EventTextStyles} color={getEventColor(event).text} fontSize="p3">
            {event.description}
          </PmText>
        </EuiFlexItem>
        <EuiFlexItem
          grow={false}
          onClick={onRemoveClick}
          data-testid={`meld-calendar-remove-pending-offered-availability-event-${event.personaId}`}
        >
          <EuiIcon type={URL.getStatic("icons/add_circle_filled.svg")} size="original" />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};

const MeldResidentAvailabilityEvent = ({
  event,
  eventStyle,
  selectedTimeFrame,
  meld,
  segment,
  isEventViewableDueToPropertyGroups,
  dragAndDropState,
  isMobile,
}: {
  event: AggregatedCalendarEvent;
  meld: SchedulableMeldDetailViewSerializer;
  eventStyle: React.CSSProperties;
  selectedTimeFrame: MeldCalendarEventProps["selectedTimeFrame"];
  segment: NonNullable<SchedulableMeldDetailViewSerializer["appointment_request"]>["availabilities"][number];
  isEventViewableDueToPropertyGroups: boolean;
  dragAndDropState: CalendarDragAndDropState;
  isMobile: boolean;
}) => {
  const { isHovered, floatingTargetRef, handleMouseEnter, handleMouseLeave } = useFloatingEventDetails();
  const personaCompositeID = makeCompositeId(event.personaId, event.personaType);
  const scheduleMeldMutation = useScheduleMeld(meld, {
    initiator: "offeredAvailabilityCalendarEvent",
    actionType: "addAppointment",
  });
  const { addScheduledSegment } = useCalendarStateActions();
  const residentAvailabilitiesMeldId = useCalendarStateMeldResidentAvailabilities();

  const bookMutation = useBookResidentAvailability(
    meld,
    { initiator: "offeredAvailabilityCalendarEvent" },
    isMeldAssigned(meld) ? undefined : personaCompositeID
  );

  if (!isEventViewableDueToPropertyGroups) {
    return null;
  }

  const onAddRequestedAppointment = (seg: AppointmentRequestDetailViewSerializer["availabilities"][number]) => {
    const startTimeStr = seg.event.dtstart;
    const endTimeStr = seg.event.dtend;
    if (!startTimeStr || !endTimeStr) {
      return;
    }

    const startTime = moment(startTimeStr);
    const endTime = moment(endTimeStr);
    const durationInMin = endTime.diff(startTime, "minutes");

    scheduleMeldMutation.mutate({
      isReschedulingAppointment: false,
      isAddingAppointment: true,
      events: null,
      startTime,
      durationInMin,
      appointmentId: null,
    });
  };

  if (isSegmentScheduled({ event: { dtstart: event.start, dtend: event.end } }, meld)) {
    return null;
  }

  const residentIcon = <EuiIcon type={URL.getStatic("icons/residents_gray.svg")} size="m" />;
  const titleText = (
    <PmText style={EventTextStyles} color={getEventColor(event).text} fontSize="p3">
      {event.description}
    </PmText>
  );

  const linkOnClick = () => {
    if (getCalendarDraftModeEnabled()) {
      // TODO: batch action
      return;
    }
    if (!Features.isMultipleAppointmentsEnabled()) {
      bookMutation.mutate({ ids: [segment.id], isAssigning: !isMeldAssigned(meld) });
      return;
    }

    const hasScheduledAppointment =
      (meld.vendorappointment.length > 0 && meld.vendorappointment[0].availability_segment !== null) ||
      (meld.managementappointment.length > 0 && meld.managementappointment[0].availability_segment !== null);

    if (hasScheduledAppointment) {
      onAddRequestedAppointment(segment);
    } else {
      if (Features.isMultipleAppointmentsEnabled()) {
        addScheduledSegment(segment);
      }
      bookMutation.mutate({ ids: [segment.id], isAssigning: !isMeldAssigned(meld) });
    }
  };

  const showScheduleLink =
    event.start_moment.isAfter(moment()) && (isMeldSchedulable(meld) || !isMeldAssigned(meld)) && canScheduleMelds;

  const link = (
    <EuiFlexGroup direction="row" gutterSize="xs">
      <PmEmptyButton
        padding="0"
        textSize="12px"
        onClick={linkOnClick}
        hasBorder={false}
        isDisabled={bookMutation.isLoading}
        text={residentAvailabilitiesMeldId ? "" : "Schedule"}
      />
      {bookMutation.isLoading && <EuiLoadingSpinner size="s" style={{ marginTop: "4px" }} />}
    </EuiFlexGroup>
  );

  const pointerEventsDisabled = dragAndDropState !== null;
  // vertical stacked version has a different layout
  if (selectedTimeFrame !== CALENDAR_TIMEFRAMES.ONE_DAY) {
    return (
      <EuiFlexGroup
        direction="column"
        justifyContent="spaceBetween"
        gutterSize="xs"
        style={{
          ...eventStyle,
          border: `1px solid ${colors.brand.meldBlue}`,
          borderRadius: "6px",
          cursor: "unset",
          pointerEvents: pointerEventsDisabled ? "none" : undefined,
        }}
        onClick={event.onClick}
        data-testid={`meld-calendar-resident-availability-event-${segment.id}`}
        ref={floatingTargetRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <EuiFlexItem grow={false} style={{ flexShrink: 0 }}>
          {residentIcon}
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ flexShrink: 0 }}>
          {titleText}
          <PmText fontWeight="semiBold" fontSize="p3" style={{ padding: "2px" }}>
            {meld.brief_description}
          </PmText>
        </EuiFlexItem>
        <EuiFlexItem style={{ flexGrow: 1 }} />

        {showScheduleLink && (
          <EuiFlexItem grow={false} style={{ flexShrink: 0, marginTop: "auto" }}>
            {link}
          </EuiFlexItem>
        )}
        {!isMobile && isHovered && dragAndDropState === null && (
          <FloatingEventDetails targetRef={floatingTargetRef} isVisible={isHovered}>
            <MeldCalendarDetailsCard source="calendarEventHover" meld={meld} />
          </FloatingEventDetails>
        )}
      </EuiFlexGroup>
    );
  }

  // 1-day calendar
  return (
    <EuiFlexGroup
      direction="column"
      justifyContent="spaceBetween"
      gutterSize="none"
      style={{
        ...eventStyle,
        border: `1px solid ${colors.brand.meldBlue}`,
        borderRadius: "6px",
        cursor: "unset",
        pointerEvents: pointerEventsDisabled ? "none" : undefined,
      }}
      onClick={event.onClick}
      data-testid={`meld-calendar-resident-availability-event-${segment.id}`}
      ref={floatingTargetRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction="row" responsive={false} justifyContent="spaceBetween">
          {showScheduleLink && (
            <EuiFlexItem grow={false} style={{ flexShrink: 0, marginTop: "auto" }}>
              {link}
            </EuiFlexItem>
          )}
          <EuiFlexItem grow={false}>{residentIcon}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <PmText fontWeight="semiBold" fontSize="p3">
          {meld.brief_description}
        </PmText>
        {titleText}
      </EuiFlexItem>

      {!isMobile && isHovered && dragAndDropState === null && (
        <FloatingEventDetails targetRef={floatingTargetRef} isVisible={isHovered}>
          <MeldCalendarDetailsCard source="calendarEventHover" meld={meld} />
        </FloatingEventDetails>
      )}
    </EuiFlexGroup>
  );
};

// this is width for row events and height for column events
const getEventSize = (
  eventStyle: React.CSSProperties,
  type: MeldCalendarEventProps["type"],
  defaultHourColumnSize: number
): number => {
  if (type === "row" && typeof eventStyle.width === "string") {
    return parseInt(eventStyle.width, 10);
  } else if (type === "column" && typeof eventStyle.height === "string") {
    return parseInt(eventStyle.height, 10);
  }
  // this should never occur as row events always have a width
  // and col events always have a height
  return defaultHourColumnSize;
};

// we allow resizing in 15 min increments
const NUM_RESIZE_CHUNKS_PER_HOUR = 4;

// we only allow adjusting in 15 min increments
const getMinimumDragEventAdjustmentSize = (hourSizeInPx: number) => {
  return hourSizeInPx / NUM_RESIZE_CHUNKS_PER_HOUR;
};

const useCalendarEventDragAndDrop = ({
  id,
  isMobile,
  onResizeDrop,
  eventStyle,
  type,
  hourSizeInPx,
}: {
  id: number;
  isMobile: boolean;
  onResizeDrop: React.DragEventHandler<Element>;
  eventStyle: React.CSSProperties;
  // width for row / height for column
  hourSizeInPx: number;
  type: "row" | "column";
}) => {
  const dragAndDropState = useCalendarDragAndDropState();
  const { startResizingEvent, stopResizingEvent, startDraggingMeld, startDraggingEvent, stopDragging } =
    useCalendarDragAndDropStateActions();

  const eventRef = useRef<HTMLDivElement>(null);
  const minAdjustmentSizeInPx = getMinimumDragEventAdjustmentSize(hourSizeInPx);

  // state to handle resizing events (width for row events, height for col events)
  // the initial size comes from the event style, but we need to be able to resize it
  // while dragging, so we keep the size in state.
  const [size, setSize] = useState<number>(() => getEventSize(eventStyle, type, hourSizeInPx));
  const initialX = useRef(0);
  const initialY = useRef(0);
  const newSize = useRef(size);

  // useCallback as add/removeEventListener needs a stable reference
  const onResizeDrag: React.DragEventHandler = useCallback((e) => {
    e.stopPropagation();
    if (!eventRef.current) {
      return;
    }
    const delta = type === "row" ? e.clientX - initialX.current : e.clientY - initialY.current;
    // only update in 60 / NUM_RESIZE_CHUNKS_PER_HOUR minute increments
    if (Math.abs(delta) < minAdjustmentSizeInPx) {
      return;
    }
    initialX.current = e.clientX;
    initialY.current = e.clientY;

    setSize((prevSize) => {
      const numberOfChunks = Math.round((prevSize + delta) / minAdjustmentSizeInPx);
      // prevents dragging events to be longer than 8 hours
      if (numberOfChunks > 8 * NUM_RESIZE_CHUNKS_PER_HOUR) {
        return prevSize;
      }
      const updatedSize = numberOfChunks * minAdjustmentSizeInPx;

      newSize.current = updatedSize;
      return Math.max(minAdjustmentSizeInPx, updatedSize);
    });
  }, []);

  // useCallback as add/removeEventListener needs a stable reference
  const onResizeDragStart: React.DragEventHandler = (e) => {
    e.stopPropagation();

    // Create an invisible element to use as the drag image
    const dragImage = document.createElement("div");
    dragImage.style.width = "0px";
    dragImage.style.height = "0px";
    document.body.appendChild(dragImage);

    // Set the custom drag image
    e.dataTransfer.setDragImage(dragImage, 0, 0);

    // Cleanup: remove the dragImage after setting
    setTimeout(() => document.body.removeChild(dragImage), 0);

    startResizingEvent(id);
    initialX.current = e.clientX;
    initialY.current = e.clientY;
    document.addEventListener("drag", onResizeDrag as unknown as EventListener);
    document.addEventListener("dragover", calendarEventOnDragOver as unknown as EventListener);
    document.addEventListener("drop", onResizeDrop as unknown as EventListener);
  };

  useEffect(() => {
    // be sure to clean up any event listeners when the component unmounts
    return () => {
      document.removeEventListener("drag", onResizeDrag as unknown as EventListener);
      document.removeEventListener("drop", onResizeDrop as unknown as EventListener);
      document.removeEventListener("dragover", calendarEventOnDragOver as unknown as EventListener);
    };
  }, []);

  useEffect(() => {
    // because we are using a state value to control the size rather than the browser,
    // when the browser resizes we need to keep the events in sync
    setSize(getEventSize(eventStyle, type, hourSizeInPx));
  }, [hourSizeInPx]);

  const dragEventEnabled = (!isMobile && dragAndDropState === null) || dragAndDropState?.type === "draggingEvent";
  const dragMeldEnabled = (!isMobile && dragAndDropState === null) || dragAndDropState?.type === "draggingMeld";
  const resizeEnabled = (!isMobile && dragAndDropState === null) || dragAndDropState?.type === "resizingEvent";

  return {
    onResizeDrag,
    newSize,
    size,
    setSize,
    eventRef,
    startDraggingMeld,
    startDraggingEvent,
    stopResizingEvent,
    resizeEnabled,
    onResizeDragStart,
    dragMeldEnabled,
    dragEventEnabled,
    stopDragging,
    dragAndDropState,
  };
};

// mostly to check if the event details should be hidden due to property groups
function getMeldScheduledEventText(
  event: AggregatedCalendarEvent,
  isEventViewableDueToPropertyGroups: boolean
): string {
  if (!isEventViewableDueToPropertyGroups) {
    return "Meld is not in your property groups.";
  }
  return event.description;
}

function getMeldScheduledAddressText(meld: MeldScheduledEventProps["event"]["meld"]): string | undefined {
  if (!meld) {
    return undefined;
  }
  const property = getMeldProperty(meld);
  const distinctPropertyName = getPropertyNameDistinctFromLine1(property);
  const result: Array<string | undefined> = [];

  if (distinctPropertyName) {
    result.push(distinctPropertyName);
  } else {
    if (meld.unit) {
      result.push(meld.unit.display_address.line_1);
    } else {
      result.push(property.line_1);
    }
  }

  if (meld.unit) {
    result.push(`Unit ${meld.unit.unit}`);
  }

  return result.filter(Boolean).join(", ");
}

/**
 * The state used with `FloatingEventDetails`
 */
const useFloatingEventDetails = () => {
  const [isHovered, setIsHovered] = useState(false);
  const hoverTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const floatingTargetRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    hoverTimeout.current = setTimeout(() => {
      setIsHovered(true);
    }, HOVER_CARD_DELAY_IN_MS);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    if (isHovered) {
      setIsHovered(false);
    }
  };

  return {
    isHovered,
    floatingTargetRef,
    handleMouseEnter,
    handleMouseLeave,
  };
};

const FloatingEventDetails = ({
  children,
  targetRef,
  isVisible,
}: {
  children: React.ReactNode;
  targetRef: React.RefObject<HTMLElement>;
  isVisible: boolean;
}) => {
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });

  useEffect(() => {
    if (targetRef.current && isVisible) {
      const rect = targetRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top - rect.height / 2, // Center vertically
        left: rect.right - rect.width * 0.25, // Shift left by 25% of width
        width: rect.width, // Match the width
      });
    }
  }, [isVisible, targetRef]);

  if (!isVisible) {
    return null;
  }
  // we use `createPoral` to make sure the hover state sits above the rest of the page
  // (otherwise it'll be hidden by 'overflow:hidden' in the grid)
  return ReactDOM.createPortal(
    <div
      style={{
        width: "300px",
        position: "absolute",
        top: `${position.top}px`,
        left: `${position.left}px`,
        // on top of everything, see `zIndicies` in single/multi day calendars
        zIndex: 1000,
      }}
    >
      {children}
    </div>,
    document.body
  );
};

const HOVER_CARD_DELAY_IN_MS = 500;

interface MeldScheduledEventProps {
  event: ExtractMember<AggregatedCalendarEvent, "management_scheduled" | "vendor_scheduled">;
  eventStyle: React.CSSProperties;
  modalState: MeldCalendarModalState;
  type: MeldCalendarEventProps["type"];
  isMobile: boolean;
  hourSizeInPx: number;
  isEventViewableDueToPropertyGroups: boolean;
}

const MeldScheduledEvent = ({
  event,
  eventStyle,
  modalState,
  type,
  isMobile,
  hourSizeInPx,
  isEventViewableDueToPropertyGroups,
}: MeldScheduledEventProps) => {
  const { isHovered, floatingTargetRef, handleMouseEnter, handleMouseLeave } = useFloatingEventDetails();
  const draftModeEnabled = useCalendarDraftModeEnabled();

  // useCallback as add/removeEventListener needs a stable reference
  const onResizeDrop: React.DragEventHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const apptId =
      (event.type === "management_scheduled" &&
        event.meld &&
        event.segment.managementavailabilitysegment.scheduled_management_appointment?.id) ||
      (event.type === "vendor_scheduled" &&
        event.meld &&
        event.segment.vendoravailabilitysegment.scheduled_vendor_appointment);

    const newDurationInMin = (newSize.current / hourSizeInPx) * 60;
    if (!apptId) {
      // pass;
    } else if (newDurationInMin === event.end_moment.diff(event.start_moment, "minutes")) {
      // event didn't change, no modal should show
    } else {
      modalState.open({
        type: "rescheduleEvent",
        meld: event.meld,
        newDurationInMin,
        newDurationStart: event.start_moment,
        appointmentId: apptId,
      });
    }
    stopResizingEvent();
    document.removeEventListener("drag", onResizeDrag as unknown as EventListener);
    document.removeEventListener("drop", onResizeDrop as unknown as EventListener);
    document.removeEventListener("dragover", calendarEventOnDragOver as unknown as EventListener);
    // reset the event size in case the user cancels the reschedule
    setSize(getEventSize(eventStyle, type, hourSizeInPx));
  }, []);

  const {
    onResizeDrag,
    newSize,
    size,
    setSize,
    eventRef,
    startDraggingEvent,
    stopResizingEvent,
    resizeEnabled: resizeMeldEnabled,
    onResizeDragStart,
    dragMeldEnabled,
    stopDragging,
    dragAndDropState,
  } = useCalendarEventDragAndDrop({
    id: event.meld?.id || 0,
    isMobile,
    onResizeDrop,
    eventStyle,
    type,
    hourSizeInPx,
  });

  const eventTimeCanBeRescheduled = getCanRescheduleAppointment({
    availability_segment: { event: { dtstart: event.start, dtend: event.end } },
  });
  const dragEnabled = dragMeldEnabled && eventTimeCanBeRescheduled;
  const resizeEnabled = resizeMeldEnabled && eventTimeCanBeRescheduled;

  const appointmentId =
    (event.type === "vendor_scheduled" && event.segment.vendoravailabilitysegment.scheduled_vendor_appointment) ||
    (event.type === "management_scheduled" &&
      event.segment.managementavailabilitysegment.scheduled_management_appointment?.id) ||
    null;

  const text = getMeldScheduledEventText(event, isEventViewableDueToPropertyGroups);
  const addressText = getMeldScheduledAddressText(event.meld);

  const pointerEventsDisabled = dragAndDropState !== null && dragAndDropState.id !== event.meld?.id;

  return (
    <div
      style={{
        width: type === "row" ? size : eventStyle.width,
        height: type === "column" ? size : eventStyle.height,
        position: "relative",
        left: eventStyle.left,
        top: eventStyle.top,
        pointerEvents: pointerEventsDisabled ? "none" : undefined,
      }}
      ref={eventRef}
      data-testid={`meld-calendar-meld-event-${event.meld?.id}-${event.personaType}-${event.personaId}`}
    >
      {/* this div is the drag handle for drag and drop the whole event*/}
      <div
        ref={floatingTargetRef}
        style={{
          ...eventStyle,
          paddingRight: "4px",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          cursor: (isEventViewableDueToPropertyGroups && dragEnabled && "grab") || "pointer",
        }}
        onClick={isEventViewableDueToPropertyGroups ? event.onClick : undefined}
        draggable={isEventViewableDueToPropertyGroups && dragEnabled}
        onDragStart={
          isEventViewableDueToPropertyGroups && event.meld && dragEnabled
            ? getCalendarEventOnDragStart({
                meld: event.meld,
                coordinates: event.coordinates,
                additionalCallback: startDraggingEvent,
                appointmentId,
                type: "meld",
                personaType: event.type === "management_scheduled" ? "agent" : "vendor",
                personaIds:
                  event.type === "management_scheduled"
                    ? event.segment.managementavailabilitysegment.scheduled_management_appointment
                        ?.management_assignment?.in_house_servicers || []
                    : [event.segment.vendoravailabilitysegment.assignment_request.vendor.id],
              })
            : undefined
        }
        onDragEnd={isEventViewableDueToPropertyGroups ? stopDragging : undefined}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiFlexItem grow={false}>
            <PmText
              style={EventTextStyles}
              color={getEventColor(event, isEventViewableDueToPropertyGroups).text}
              fontWeight="semiBold"
              fontSize="p3"
            >
              {text}
            </PmText>
          </EuiFlexItem>
          {addressText !== undefined && (
            <EuiFlexItem grow={false}>
              <PmText style={EventTextStyles} color={colors.neutrals.gray900} fontSize="p3">
                {addressText}
              </PmText>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </div>
      {/* this div is the narrow drag handle for resizing events */}
      <div
        style={{
          width: type === "row" ? "10px" : "100%",
          height: type === "row" ? "100%" : "10px",
          backgroundColor: getEventColor(event, isEventViewableDueToPropertyGroups).bg,
          borderTopRightRadius: type === "row" ? eventStyle.borderRadius : undefined,
          borderBottomRightRadius: eventStyle.borderRadius,
          borderBottomLeftRadius: type === "row" ? undefined : eventStyle.borderRadius,
          borderTop: type === "row" ? eventStyle.border : undefined,
          borderRight: eventStyle.border,
          borderBottom: eventStyle.border,
          borderLeft: type === "row" ? undefined : eventStyle.border,
          position: "absolute",
          left: type === "row" ? size - 10 : 0,
          top: type === "row" ? 0 : size - 10,
          cursor:
            (isEventViewableDueToPropertyGroups && resizeEnabled && (type === "row" ? "e-resize" : "ns-resize")) ||
            undefined,
          zIndex: eventStyle.zIndex,
        }}
        draggable={isEventViewableDueToPropertyGroups && resizeEnabled}
        onDragStart={isEventViewableDueToPropertyGroups && resizeEnabled ? onResizeDragStart : undefined}
      />
      {draftModeEnabled && appointmentId && (
        <RemoveEventButton
          ariaLabel="Cancel appointment"
          zIndex={eventStyle.zIndex}
          onClick={() =>
            getCalendarDraftModeActions().addPendingAction({
              type: "cancelAppointment",
              date: new Date(),
              meldId: event.meld.id,
              appointmentId: appointmentId,
              recommendationId: undefined,
              personaType: event.type === "management_scheduled" ? "agent" : "vendor",
              // not needed as we don't display anything for canceled appointments
              personaIds: [],
            })
          }
        />
      )}
      {!isMobile && isHovered && dragAndDropState === null && (
        <FloatingEventDetails targetRef={floatingTargetRef} isVisible={isHovered}>
          <MeldCalendarDetailsCard source="calendarEventHover" meld={event.meld} />
        </FloatingEventDetails>
      )}
    </div>
  );
};

const AlternativeEvent = ({
  event,
  eventStyle,
  modalState,
  type,
  isMobile,
  hourSizeInPx,
}: {
  event: ExtractMember<AggregatedCalendarEvent, "alternative_event_scheduled">;
  eventStyle: React.CSSProperties;
  modalState: MeldCalendarModalState;
  type: MeldCalendarEventProps["type"];
  isMobile: boolean;
  hourSizeInPx: number;
}) => {
  const editMutation = useEditAlternativeEvent(event.type === "alternative_event_scheduled" ? event.altEvent : null);

  // useCallback as add/removeEventListener needs a stable reference
  const onResizeDrop: React.DragEventHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const apptId = event.type === "alternative_event_scheduled" && event.altEvent && event.altEvent.id;
    const newDurationInMin = (newSize.current / hourSizeInPx) * 60;

    if (getCalendarDraftModeEnabled()) {
      // TODO: batch action
    } else if (!apptId) {
      // pass
    } else if (newDurationInMin === event.end_moment.diff(event.start_moment, "minutes")) {
      // event didn't change, no modal should show
    } else if (event.altEvent.parent_event) {
      modalState.open({
        type: "updateEvent",
        altEvent: { id: event.altEvent.id },
        newDurationInMin,
        startTime: event.start_moment,
        event: event.altEvent.event.id,
      });
    } else {
      // instant submission
      const newEndTime = event.start_moment.clone().add(newDurationInMin, "minutes");
      editMutation.mutate({
        type: "None",
        payload: {
          id: event.altEvent.id,
          management_attendees: event.altEvent.management_attendees,
          event: {
            id: event.altEvent.event.id,
            description: event.altEvent.event.description,
            dtstart: event.start_moment.toISOString(),
            dtend: newEndTime.toISOString(),
          },
        },
      });
    }

    stopResizingEvent();
    document.removeEventListener("drag", onResizeDrag as unknown as EventListener);
    document.removeEventListener("drop", onResizeDrop as unknown as EventListener);
    document.removeEventListener("dragover", calendarEventOnDragOver as unknown as EventListener);
    // reset the event size in case the user cancels the reschedule
    setSize(getEventSize(eventStyle, type, hourSizeInPx));
  }, []);

  const {
    onResizeDrag,
    newSize,
    size,
    setSize,
    eventRef,
    startDraggingEvent,
    stopResizingEvent,
    resizeEnabled,
    onResizeDragStart,
    dragEventEnabled,
    stopDragging,
    dragAndDropState,
  } = useCalendarEventDragAndDrop({
    id: event.altEvent.id,
    isMobile,
    onResizeDrop,
    eventStyle,
    type,
    hourSizeInPx,
  });

  const appointmentId = (event.type === "alternative_event_scheduled" && event.altEvent.event.id) || null;

  let onDragStart;
  if (event.type === "alternative_event_scheduled" && event.altEvent && dragEventEnabled) {
    const type = event.altEvent.parent_event ? DraggingEvent.recurringaltevent : DraggingEvent.altevent;
    onDragStart = getCalendarEventOnDragStart({
      meld: event.altEvent,
      coordinates: event.coordinates,
      additionalCallback: startDraggingEvent,
      appointmentId,
      type,
      personaType: null,
      personaIds: event.altEvent.management_attendees,
    });
  }
  const pointerEventsDisabled =
    dragAndDropState !== null && dragAndDropState.type !== "resizingEvent" && dragAndDropState.id !== event.altEvent.id;

  return (
    <div
      style={{
        width: type === "row" ? size : eventStyle.width,
        height: type === "column" ? size : eventStyle.height,
        position: "relative",
        left: eventStyle.left,
        top: eventStyle.top,
        pointerEvents: pointerEventsDisabled ? "none" : undefined,
      }}
      ref={eventRef}
    >
      {/* this div is the drag handle for drag and drop the whole event*/}
      <div
        style={{
          ...eventStyle,
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          cursor: (dragEventEnabled && "grab") || undefined,
        }}
        onClick={event.onClick}
        data-testid={`meld-calendar-alternative-event-${event.description}`}
        draggable={dragEventEnabled}
        onDragStart={onDragStart}
        onDragEnd={stopDragging}
      >
        <PmText style={EventTextStyles} color={getEventColor(event).text} fontSize="p3">
          {event.description}
        </PmText>
      </div>
      <div
        style={{
          width: type === "row" ? "10px" : "100%",
          height: type === "row" ? "100%" : "10px",
          backgroundColor: getEventColor(event).bg,
          borderTopRightRadius: type === "row" ? eventStyle.borderRadius : undefined,
          borderBottomRightRadius: eventStyle.borderRadius,
          borderBottomLeftRadius: type === "row" ? undefined : eventStyle.borderRadius,
          borderTop: type === "row" ? eventStyle.border : undefined,
          borderRight: eventStyle.border,
          borderBottom: eventStyle.border,
          borderLeft: type === "row" ? undefined : eventStyle.border,
          position: "absolute",
          left: type === "row" ? size - 10 : 0,
          top: type === "row" ? 0 : size - 10,
          cursor: (resizeEnabled && (type === "row" ? "e-resize" : "ns-resize")) || undefined,
          zIndex: eventStyle.zIndex,
        }}
        draggable={resizeEnabled}
        onDragStart={resizeEnabled ? onResizeDragStart : undefined}
      />
    </div>
  );
};

const GoogleCalendarEvent = ({
  event,
  eventStyle,
  dragAndDropState,
}: {
  event: AggregatedCalendarEvent;
  eventStyle: React.CSSProperties;
  dragAndDropState: CalendarDragAndDropState;
}) => {
  const pointerEventsDisabled = dragAndDropState !== null;
  return (
    <div
      style={{ ...eventStyle, pointerEvents: pointerEventsDisabled ? "none" : undefined }}
      onClick={event.onClick}
      data-testid={`meld-calendar-google-event-${event.description}`}
    >
      <PmText style={EventTextStyles} color={getEventColor(event).text} fontSize="p3">
        {event.description}
      </PmText>
    </div>
  );
};

const OutlookCalendarEvent = ({
  event,
  eventStyle,
  dragAndDropState,
}: {
  event: AggregatedCalendarEvent;
  eventStyle: React.CSSProperties;
  dragAndDropState: CalendarDragAndDropState;
}) => {
  const pointerEventsDisabled = dragAndDropState !== null;
  return (
    <div
      style={{ ...eventStyle, pointerEvents: pointerEventsDisabled ? "none" : undefined }}
      onClick={event.onClick}
      data-testid={`meld-calendar-outlook-event-${event.description}`}
    >
      <PmText style={EventTextStyles} color={getEventColor(event).text} fontSize="p3">
        {event.description}
      </PmText>
    </div>
  );
};

interface BatchRecommendedMeldEventProps {
  event: ExtractMember<AggregatedCalendarEvent, "pending_recommendation_event">;
  eventStyle: React.CSSProperties;
  isMobile: boolean;
  modalState: MeldCalendarModalState;
  hourSizeInPx: number;
}

const BatchRecommendedMeldEvent = ({ event, eventStyle, modalState }: BatchRecommendedMeldEventProps) => {
  const { floatingTargetRef, handleMouseEnter, handleMouseLeave } = useFloatingEventDetails();
  const pendingActions = useGetCalendarDraftModePendingActions();
  const ignoredRecommendations = useGetCalendarDraftModeIgnoredRecommendations();

  const borderStyles: React.CSSProperties = {
    borderRadius: BorderRadius,
    border: `2px dashed ${getEventColor(event).text}`,
  };

  if (
    pendingActions.some((action) => action.meldId === event.meld.id) ||
    ignoredRecommendations.includes(event.recommendation.id)
  ) {
    return null;
  }

  const pointerEventsDisabled = false;

  const text = event.description;
  const addressText = getMeldScheduledAddressText(event.meld) || "";

  return (
    <div
      style={{
        width: eventStyle.width,
        height: eventStyle.height,
        position: "relative",
        left: eventStyle.left,
        top: eventStyle.top,
        pointerEvents: pointerEventsDisabled ? "none" : undefined,
      }}
    >
      <div
        // white background div to allow opacity to lighten the color
        style={{
          ...eventStyle,
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          pointerEvents: "none",
          background: colors.brand.white,
          position: "absolute",
        }}
        data-testid={`meld-calendar-draft-meld-event-${event.meld.id}-${event.personaType}-${event.personaId}`}
      />
      {/* this div is the drag handle for drag and drop the whole event*/}
      <div
        ref={floatingTargetRef}
        style={{
          ...eventStyle,
          paddingRight: "4px",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          cursor: "pointer",
          ...borderStyles,
          // 70% opacity
          background: eventStyle.background + "B3",
        }}
        onClick={event.onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiFlexItem grow={false}>
            <PmText style={EventTextStyles} color={getEventColor(event).text} fontWeight="semiBold" fontSize="p3">
              {text}
            </PmText>
          </EuiFlexItem>
          {addressText !== undefined && (
            <EuiFlexItem grow={false}>
              <PmText style={EventTextStyles} color={getEventColor(event).text} fontSize="p3">
                {addressText}
              </PmText>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </div>
      <EuiFlexGroup direction="row" gutterSize="s">
        <EuiFlexItem>
          <div
            style={{
              transform: "scale(.80)",
              position: "absolute",
              width: "16px",
              height: "16px",
              bottom: 8,
              right: 22,
              zIndex: eventStyle.zIndex,
              cursor: "pointer",
            }}
            onClick={() =>
              modalState.open({
                meld: { id: event.meld.id },
                type: "meldDroppedOnAgent",
                targetAgentId: event.personaId,
                startTime: event.start_moment,
                appointmentId: null,
                recommendationId: event.recommendation.id,
                sourceType: "draganddrop",
              })
            }
            data-testid="meld-calendar-draft-add-event"
            aria-label="Add recommended event to pending actions"
          >
            <PmCheckCircleOutline fill={getEventColor(event).text} />
          </div>
        </EuiFlexItem>
        <EuiFlexItem>
          <RemoveEventButton
            color={getEventColor(event).text}
            ariaLabel="Reject this recommended event"
            onClick={() => getCalendarDraftModeActions().addIgnoredRecommendation(event.recommendation.id)}
            zIndex={eventStyle.zIndex}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};

const MeldRecommendEvent = ({
  event,
  eventStyle,
  dragAndDropState,
}: {
  event: AggregatedCalendarEvent;
  eventStyle: React.CSSProperties;
  dragAndDropState: CalendarDragAndDropState;
}) => {
  const isDraggingMeld = dragAndDropState !== null;
  if (isDraggingMeld) {
    return null;
  }

  return (
    <div style={{ ...eventStyle }} onClick={event.onClick}>
      <PmText style={EventTextStyles} color={getEventColor(event).text} fontSize="p3">
        {event.description}
      </PmText>
    </div>
  );
};

const MeldAltEventPlaceholderEvent = ({
  event,
  eventStyle,
}: {
  event: Extract<AggregatedCalendarEvent, { type: "pending_alt_event" }>;
  eventStyle: React.CSSProperties;
}) => {
  return (
    <div
      style={{ ...eventStyle, borderRadius: "6px", fontSize: "12px" }}
      data-testid={`meld-calendar-pending-alt-event-${event.personaId}`}
    >
      <EuiFlexGroup direction="row" gutterSize="xs" style={{ justifyContent: "space-between" }}>
        <EuiFlexItem grow={false}>
          <PmText style={EventTextStyles} color={getEventColor(event).text} fontSize="p3">
            {event.description}
          </PmText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};

interface MeldPendingDraftEventProps {
  event: ExtractMember<AggregatedCalendarEvent, "pendingDraftAction">;
  eventStyle: React.CSSProperties;
  type: MeldCalendarEventProps["type"];
  isMobile: boolean;
  hourSizeInPx: number;
  isEventViewableDueToPropertyGroups: boolean;
}

const MeldPendingDraftEvent = ({
  event,
  eventStyle,
  type,
  isMobile,
  hourSizeInPx,
  isEventViewableDueToPropertyGroups,
}: MeldPendingDraftEventProps) => {
  const { isHovered, floatingTargetRef, handleMouseEnter, handleMouseLeave } = useFloatingEventDetails();

  const borderStyles: React.CSSProperties = {
    borderRadius: BorderRadius,
    border: `2px dashed ${getEventColor(event, isEventViewableDueToPropertyGroups).text}`,
  };

  // useCallback as add/removeEventListener needs a stable reference
  const onResizeDrop: React.DragEventHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    const apptId = (event.draftAction.type === "rescheduleAppointment" && event.draftAction.appointmentId) || null;
    const newDurationInMin = (newSize.current / hourSizeInPx) * 60;
    if (event.draftAction.type !== "rescheduleAppointment") {
      // pass;
    } else if (!apptId) {
      // pass;
    } else if (newDurationInMin === differenceInMinutes(event.draftAction.endTime, event.draftAction.startTime)) {
      // event didn't change, no modal should show
    } else if ("startTime" in event.draftAction) {
      const endTime = addMinutes(event.draftAction.startTime, newDurationInMin);
      getCalendarDraftModeActions().updatePendingAction(event.draftAction.uuid, {
        type: "rescheduleAppointment",
        endTime,
      });
    }

    stopResizingEvent();
    document.removeEventListener("drag", onResizeDrag as unknown as EventListener);
    document.removeEventListener("drop", onResizeDrop as unknown as EventListener);
    document.removeEventListener("dragover", calendarEventOnDragOver as unknown as EventListener);
    // reset the event size in case the user cancels the reschedule
    setSize(getEventSize(eventStyle, type, hourSizeInPx));
  }, []);

  const {
    onResizeDrag,
    newSize,
    size,
    setSize,
    eventRef,
    startDraggingEvent,
    stopResizingEvent,
    resizeEnabled: resizeMeldEnabled,
    onResizeDragStart,
    dragMeldEnabled,
    stopDragging,
    dragAndDropState,
  } = useCalendarEventDragAndDrop({
    id: event.draftAction.meldId,
    isMobile,
    onResizeDrop,
    eventStyle,
    type,
    hourSizeInPx,
  });

  const eventTimeCanBeRescheduled = getCanRescheduleAppointment({
    availability_segment: { event: { dtstart: event.start, dtend: event.end } },
  });
  const dragEnabled = dragMeldEnabled && eventTimeCanBeRescheduled;

  // TODO: this might need updates for other batch action types
  const resizeEnabled = resizeMeldEnabled && eventTimeCanBeRescheduled;
  const appointmentId = event.draftAction.type === "rescheduleAppointment" && event.draftAction.appointmentId;

  const text = getMeldScheduledEventText(event, isEventViewableDueToPropertyGroups);

  const pointerEventsDisabled = dragAndDropState !== null && dragAndDropState.id !== event.draftAction.meldId;

  return (
    <div
      style={{
        width: type === "row" ? size : eventStyle.width,
        height: type === "column" ? size : eventStyle.height,
        position: "relative",
        left: eventStyle.left,
        top: eventStyle.top,
        pointerEvents: pointerEventsDisabled ? "none" : undefined,
      }}
      ref={eventRef}
    >
      <div
        // white background div to allow opacity to lighten the color
        style={{
          ...eventStyle,
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          pointerEvents: "none",
          background: colors.brand.white,
          position: "absolute",
        }}
        data-testid={`meld-calendar-draft-meld-event-${event.draftAction.meldId}-${event.personaType}-${event.personaId}`}
      />
      {/* this div is the drag handle for drag and drop the whole event*/}
      <div
        ref={floatingTargetRef}
        style={{
          ...eventStyle,
          paddingRight: "4px",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          cursor: (isEventViewableDueToPropertyGroups && dragEnabled && "grab") || "pointer",
          ...borderStyles,
          // 70% opacity
          background: eventStyle.background + "B3",
        }}
        onClick={isEventViewableDueToPropertyGroups ? event.onClick : undefined}
        draggable={isEventViewableDueToPropertyGroups && dragEnabled}
        onDragStart={
          isEventViewableDueToPropertyGroups && event.draftAction.meldId && dragEnabled && appointmentId
            ? getCalendarEventOnDragStart({
                meld: { id: event.draftAction.meldId },
                coordinates: event.coordinates,
                additionalCallback: startDraggingEvent,
                appointmentId,
                type: "meld",
                personaType: event.personaType,
                personaIds: event.draftAction.personaIds,
                draftAction: event.draftAction,
              })
            : undefined
        }
        onDragEnd={isEventViewableDueToPropertyGroups ? stopDragging : undefined}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiFlexItem grow={false}>
            <PmText
              style={EventTextStyles}
              color={getEventColor(event, isEventViewableDueToPropertyGroups).text}
              fontWeight="semiBold"
              fontSize="p3"
            >
              {text}
            </PmText>
          </EuiFlexItem>
          {event.addressText !== undefined && (
            <EuiFlexItem grow={false}>
              <PmText style={EventTextStyles} color={colors.neutrals.gray900} fontSize="p3">
                {event.addressText}
              </PmText>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </div>
      {/* this div is the narrow drag handle for resizing events */}
      <div
        // white background div to allow for recolor
        style={{
          width: type === "row" ? "10px" : "100%",
          height: type === "row" ? "100%" : "10px",
          left: type === "row" ? size - 10 : 0,
          top: type === "row" ? 0 : size - 10,
          position: "absolute",
          background: colors.brand.white,
          pointerEvents: "none",
        }}
      />
      <div
        style={{
          width: type === "row" ? "10px" : "100%",
          height: type === "row" ? "100%" : "10px",
          borderTopRightRadius: type === "row" ? borderStyles.borderRadius : undefined,
          borderBottomRightRadius: borderStyles.borderRadius,
          borderBottomLeftRadius: type === "row" ? undefined : borderStyles.borderRadius,
          borderTop: type === "row" ? borderStyles.border : undefined,
          borderRight: borderStyles.border,
          borderBottom: borderStyles.border,
          borderLeft: type === "row" ? undefined : borderStyles.border,
          position: "absolute",
          left: type === "row" ? size - 10 : 0,
          top: type === "row" ? 0 : size - 10,
          cursor:
            (isEventViewableDueToPropertyGroups && resizeEnabled && (type === "row" ? "e-resize" : "ns-resize")) ||
            undefined,
          zIndex: eventStyle.zIndex,
          // 0% opacity to show prior background color
          backgroundColor: getEventColor(event, isEventViewableDueToPropertyGroups).bg + "00",
        }}
        draggable={isEventViewableDueToPropertyGroups && resizeEnabled}
        onDragStart={isEventViewableDueToPropertyGroups && resizeEnabled ? onResizeDragStart : undefined}
      />
      <RemoveEventButton
        ariaLabel="Remove draft action"
        onClick={() => getCalendarDraftModeActions().removePendingAction(event.draftAction.uuid)}
        zIndex={eventStyle.zIndex}
      />
      {!isMobile && isHovered && dragAndDropState === null && event.draftAction.meldId && (
        <FloatingEventDetails targetRef={floatingTargetRef} isVisible={isHovered}>
          {/* TODO: container component for this 
          <MeldCalendarDetailsCard source="calendarEventHover" meld={event.draftAction.meldId} />
      */}
        </FloatingEventDetails>
      )}
    </div>
  );
};

const MeldCalendarEvent = ({
  event,
  offsetIndex,
  offsetTotal,
  zIndex,
  type,
  selectedTimeFrame,
  activeRightpaneMeld,
  modalState,
  isMobile,
  hourSizeInPx,
}: MeldCalendarEventProps) => {
  const pendingAltEvent = useCalendarStateAltEventSelectedAgentsTime();
  const dragAndDropState = useCalendarDragAndDropState();
  const { rightPaneState } = useGetSetActivePaneAndMap();
  const isEventViewableDueToPropertyGroups = useIsEventViewableDueToPropertyGroups(event);
  const numHours = event.end_moment.diff(event.start, "hours", true);

  // height in 1day view, width in 3/5 day view
  const eventSize = `${100 / offsetTotal}%`;
  // top offset in 1 day view, left offset in 3/5 day view
  const eventSizeOffset = `${offsetIndex * (100 / offsetTotal)}%`;

  // offset based on the minute time from the beginning of the block
  const minuteOffset = `${(event.start_moment.minutes() / 60) * hourSizeInPx}px`;

  const offsetStyles: React.CSSProperties = {};
  // 1 day calendar
  if (type === "row") {
    // -2 to create a gap between the event and calendar cell border
    const eventWidthInPx = hourSizeInPx * numHours - 2;
    offsetStyles.height = eventSize;
    offsetStyles.left = minuteOffset;
    // handle very narrow events
    offsetStyles.width = eventWidthInPx > 0 ? `${eventWidthInPx}px` : "fit-content";
    offsetStyles.top = eventSizeOffset;
  } else {
    // -2 to create a gap between the event and calendar cell border
    const eventHeightInPx = hourSizeInPx * numHours - 2;
    // 3/5 day calendar
    offsetStyles.width = eventSize;
    offsetStyles.top = minuteOffset;
    // handle very short events
    offsetStyles.height = eventHeightInPx > LINE_HEIGHT_IN_PX ? `${eventHeightInPx}px` : undefined;
    offsetStyles.left = eventSizeOffset;
  }

  const meldIsActive = event.meld && activeRightpaneMeld?.id === event.meld.id;

  const backgroundColor = getEventColor(event, isEventViewableDueToPropertyGroups).bg;

  const eventStyle: React.CSSProperties = {
    ...offsetStyles,
    zIndex,
    background: backgroundColor,
    lineHeight: `${LINE_HEIGHT_IN_PX}px`,
    position: "relative",
    padding: `${PADDING_IN_PX}px`,
    borderRadius: "6px",
    cursor: "pointer",
    border: `1px solid ${meldIsActive ? backgroundColor : colors.brand.white}`,
    overflow: "hidden",
    boxShadow: meldIsActive ? "2px 4px 10px 0px rgba(0, 0, 0, 0.50)" : undefined,
  };

  switch (event.type) {
    case "vendor_scheduled":
    case "management_scheduled":
      return (
        <MeldScheduledEvent
          event={event}
          eventStyle={eventStyle}
          modalState={modalState}
          type={type}
          isMobile={isMobile}
          hourSizeInPx={hourSizeInPx}
          isEventViewableDueToPropertyGroups={isEventViewableDueToPropertyGroups}
        />
      );
    case "google_calendar_event":
      return <GoogleCalendarEvent event={event} eventStyle={eventStyle} dragAndDropState={dragAndDropState} />;
    case "outlook_calendar_event":
      return <OutlookCalendarEvent event={event} eventStyle={eventStyle} dragAndDropState={dragAndDropState} />;
    case "alternative_event_scheduled":
      // we hide the actual event if the placeholder is showing
      if (rightPaneState.type === "alternativeEvent" && pendingAltEvent?.eventId === event.altEvent.id) {
        return null;
      }

      return (
        <AlternativeEvent
          event={event}
          eventStyle={eventStyle}
          modalState={modalState}
          type={type}
          isMobile={isMobile}
          hourSizeInPx={hourSizeInPx}
        />
      );
    case "recommend_meld_placeholder":
      return <MeldRecommendEvent event={event} eventStyle={eventStyle} dragAndDropState={dragAndDropState} />;
    case "pending_recommendation_event":
      return (
        <BatchRecommendedMeldEvent
          event={event}
          eventStyle={eventStyle}
          modalState={modalState}
          isMobile={isMobile}
          hourSizeInPx={hourSizeInPx}
        />
      );
    case "pending_alt_event":
      return <MeldAltEventPlaceholderEvent event={event} eventStyle={eventStyle} />;
    case "resident_availability":
      return (
        <MeldResidentAvailabilityEvent
          isMobile={isMobile}
          event={event}
          eventStyle={eventStyle}
          selectedTimeFrame={selectedTimeFrame}
          meld={event.meld}
          segment={event.segment}
          isEventViewableDueToPropertyGroups={isEventViewableDueToPropertyGroups}
          dragAndDropState={dragAndDropState}
        />
      );
    case "vendor_availability":
      return (
        <MeldOfferedAvailabilityEvent
          isMobile={isMobile}
          event={event}
          meld={event.meld}
          eventStyle={eventStyle}
          segment={event.segment.vendoravailabilitysegment}
          isEventViewableDueToPropertyGroups={isEventViewableDueToPropertyGroups}
          dragAndDropState={dragAndDropState}
        />
      );
    case "management_availability":
      return (
        <MeldOfferedAvailabilityEvent
          isMobile={isMobile}
          meld={event.meld}
          event={event}
          eventStyle={eventStyle}
          segment={event.segment.managementavailabilitysegment}
          isEventViewableDueToPropertyGroups={isEventViewableDueToPropertyGroups}
          dragAndDropState={dragAndDropState}
        />
      );
    case "pending_offered_availability":
      return <MeldPendingOfferedAvailabilityEvent event={event} eventStyle={eventStyle} />;
    case "pendingDraftAction":
      if (DraftActionsToHide[event.draftAction.type]) {
        return null;
      }
      return (
        <MeldPendingDraftEvent
          event={event}
          eventStyle={eventStyle}
          type={type}
          isMobile={isMobile}
          hourSizeInPx={hourSizeInPx}
          isEventViewableDueToPropertyGroups={isEventViewableDueToPropertyGroups}
        />
      );
    default:
      return null;
  }
};

export { MeldCalendarEvent, getMeldScheduledAddressText };
