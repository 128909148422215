var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState } from "react";
import { usePatchAssignMeldMaintenance } from "../../queries";
import { PmFormStyling } from "@pm-frontend/shared/components/Forms/PmFormStyling";
import {
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiText,
  EuiToolTip,
  EuiLink
} from "@elastic/eui";
import { MaintTypes, MaintTypesDisplayNames } from "@pm-frontend/shared/types/meld";
import { getInviteFullName } from "@pm-frontend/shared/utils/invite-utils";
import { getFullName } from "@pm-frontend/shared/utils/agent-utils";
import { colors } from "@pm-frontend/styles";
import {
  getAssignedMaint,
  getFilterByPropertyGroups,
  isMaintenanceManagementAgent,
  isMaintenanceVendor,
  isMaintenanceVendorInvite,
  isWorkersCompValid,
  isLiabilityInsuranceValid,
  isAppfolioComplianceValid
} from "@pm-frontend/shared/utils/assignment-utils";
import { getVendorDisplayName } from "@pm-frontend/shared/utils/vendor-utils";
import { getMeldPropertyGroups, isMeldAssigned } from "@pm-frontend/shared/utils/meld-utils";
import URL from "@pm-shared/utils/url";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import { PmFilledButton } from "@pm-frontend/shared/components/Buttons/PmFilledButton";
import { PmEmptyButton } from "@pm-frontend/shared/components/Buttons/PmEmptyButton";
import { getIdFromText } from "@pm-frontend/shared/utils/idFromText";
import { PmText } from "@pm-frontend/shared/components/Text/PmText";
import { getPersonaColor } from "@pm-frontend/shared/utils/color-utils";
import { AssignmentCards } from "../../MeldDetails/cards/AssignmentCards";
const maintenanceFormLabel = /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, gutterSize: "s", alignItems: "center" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmText, { fontWeight: "bold", fontSize: "p2" }, "Select assignees")), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
  EuiToolTip,
  {
    className: "preWhiteSpace",
    content: `Vendors and Maintenance Technicians are filtered by the assigned Property Group.

Property group assignment for Maintenance Technicians is accessible from user management and Vendor is managed from the specific vendor property groups tab.`,
    anchorProps: { style: { display: "flex" } }
  },
  /* @__PURE__ */ React.createElement(EuiIcon, { type: URL.getStatic("icons/questionmark.svg"), "aria-label": "Show maintenance tooltip", size: "s" })
)));
const isOptionDisabled = (maint, selected, validMaintenances) => {
  var _a, _b, _c, _d;
  const isValid = validMaintenances.find((m) => m.id === maint.id);
  if (!isValid) {
    return "(Not in property group)";
  }
  if (((_b = (_a = maint.vendor_managements) == null ? void 0 : _a[0]) == null ? void 0 : _b.allow_assignments) === false) {
    return "(Not assignable)";
  }
  if (selected.length === 0) {
    return void 0;
  }
  if (isMaintenanceManagementAgent(maint)) {
    const typeNumber = (_c = selected[0].value) == null ? void 0 : _c.charAt(0);
    if (typeNumber === "2") {
      return void 0;
    } else {
      return "(Vendor already selected)";
    }
  }
  if (selected[0] && ((_d = selected[0].value) == null ? void 0 : _d.charAt(0)) === "2") {
    return "(User already selected)";
  }
  return void 0;
};
const getOptions = (allMaintenance, selected, propertyGroups) => {
  let currentType = "";
  const filterFunction = getFilterByPropertyGroups(propertyGroups);
  const validMaintenances = allMaintenance.filter(filterFunction);
  return allMaintenance.reduce((result, maint) => {
    if (currentType !== maint.type) {
      currentType = maint.type;
      result.push({
        isGroupLabelOption: true,
        label: MaintTypesDisplayNames[maint.type]
      });
    }
    const disabledReason = isOptionDisabled(maint, selected, validMaintenances);
    let label = "";
    let color = "";
    switch (maint.type) {
      case MaintTypes.VENDOR:
        label = disabledReason ? `${getVendorDisplayName(maint)} ${disabledReason}` : getVendorDisplayName(maint);
        color = getPersonaColor(maint) || colors.brand.meldBlue;
        break;
      case MaintTypes.INVITED_VENDOR:
        label = disabledReason ? `${getInviteFullName(maint)} ${disabledReason}` : getInviteFullName(maint);
        color = colors.brand.veryLightBlue;
        break;
      case MaintTypes.MANAGEMENT_AGENT:
        label = disabledReason ? `${getFullName(maint)} ${disabledReason}` : getFullName(maint);
        color = getPersonaColor(maint) || colors.brand.meldBlue;
        break;
      default:
        break;
    }
    result.push({
      label,
      value: maint.composite_id,
      disabled: !!disabledReason,
      color
    });
    return result;
  }, []);
};
const getInitialSelectedMaintenance = (meld, allMaintenance, assignedMaintenanceOverride) => {
  const assignedMaintenance = assignedMaintenanceOverride || getAssignedMaint(meld);
  switch (assignedMaintenance == null ? void 0 : assignedMaintenance.type) {
    case MaintTypes.MANAGEMENT_AGENT:
      return assignedMaintenance.in_house_servicers.map((ma, index) => {
        const maint = allMaintenance.find((m) => m.id === ma.id && m.type === MaintTypes.MANAGEMENT_AGENT);
        if (!maint) {
          return void 0;
        }
        return {
          label: assignedMaintenance.names[index] || "In-house technician",
          value: maint.composite_id,
          color: getPersonaColor(maint) || colors.brand.meldBlue
        };
      }).filter(Boolean);
    case MaintTypes.VENDOR:
      const vendor = allMaintenance.find((m) => m.id === assignedMaintenance.vendor.id && m.type === MaintTypes.VENDOR);
      if (!vendor || vendor.type !== MaintTypes.VENDOR) {
        return [];
      }
      return [
        {
          label: assignedMaintenance.name,
          value: vendor.composite_id,
          color: getPersonaColor(vendor) || colors.brand.meldBlue
        }
      ];
    case MaintTypes.INVITED_VENDOR:
      const invitedVendor = allMaintenance.find(
        (m) => m.type === MaintTypes.INVITED_VENDOR && m.email === assignedMaintenance.invite_assignment.invite_group.invite.email
      );
      if (!invitedVendor) {
        return [];
      }
      return [
        {
          label: assignedMaintenance.name,
          value: invitedVendor.composite_id,
          color: colors.brand.meldBlue
        }
      ];
    default:
      return [];
  }
};
const DropdownErrorWithoutLink = ({ error }) => {
  return /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", responsive: false, gutterSize: "s", alignItems: "flexStart" }, /* @__PURE__ */ React.createElement(EuiFlexItem, null, /* @__PURE__ */ React.createElement(EuiText, null, error)));
};
const DropdownErrorWithLink = ({ error, link, link_text }) => {
  return /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", responsive: false, gutterSize: "s", alignItems: "flexStart" }, /* @__PURE__ */ React.createElement(EuiFlexItem, null, /* @__PURE__ */ React.createElement(EuiText, null, error)), /* @__PURE__ */ React.createElement(EuiFlexItem, null, /* @__PURE__ */ React.createElement(EuiLink, { href: link, target: "_blank" }, link_text)));
};
const getDropdownErrorProps = ({
  selectedValue,
  allMaintenance
}) => {
  const selectedMaintenance = allMaintenance.find((maintenance) => maintenance.composite_id === selectedValue.value);
  if ((selectedMaintenance == null ? void 0 : selectedMaintenance.type) === MaintTypes.VENDOR) {
    const isAfComplianceValid = isAppfolioComplianceValid(selectedMaintenance);
    const workersCompValid = isWorkersCompValid(selectedMaintenance);
    const liabilityInsuranceValid = isLiabilityInsuranceValid(selectedMaintenance);
    if (!isAfComplianceValid) {
      return {
        error: "Vendor has expired compliance info in AppFolio, so their invoices can't sync. Please update in AppFolio."
      };
    } else if (!workersCompValid && !liabilityInsuranceValid) {
      return {
        error: "Vendor's workers' compensation insurance and general liability insurance expired",
        link: LinkHelper.normalize(RouteUrls.vendorDetail(selectedMaintenance.id)),
        link_text: "Request vendor compliance changes"
      };
    } else if (!workersCompValid) {
      return {
        error: "Vendor's workers' compensation insurance expired",
        link: LinkHelper.normalize(RouteUrls.vendorDetail(selectedMaintenance.id)),
        link_text: "Request vendor compliance changes"
      };
    } else if (!liabilityInsuranceValid) {
      return {
        error: "Vendor's general liability insurance expired",
        link: LinkHelper.normalize(RouteUrls.vendorDetail(selectedMaintenance.id)),
        link_text: "Request vendor compliance changes"
      };
    }
  }
  return null;
};
const DropdownError = (props) => {
  if (props.link) {
    return /* @__PURE__ */ React.createElement(DropdownErrorWithLink, __spreadValues({}, props));
  } else {
    return /* @__PURE__ */ React.createElement(DropdownErrorWithoutLink, __spreadValues({}, props));
  }
};
const useAssignMaintenanceField = (props) => {
  const [controlledSelected, setControlledSelected] = useState(
    () => props.meld ? getInitialSelectedMaintenance(props.meld, props.allMaintenance) : []
  );
  const selected = props.selected || controlledSelected;
  const setSelected = props.setSelected || setControlledSelected;
  const [dropdownError, setDropdownError] = useState(() => {
    if (selected.length === 1) {
      const dropdownErrorProps = getDropdownErrorProps({
        selectedValue: selected[0],
        allMaintenance: props.allMaintenance
      });
      if (dropdownErrorProps) {
        return /* @__PURE__ */ React.createElement(DropdownError, __spreadValues({}, dropdownErrorProps));
      }
    }
    return null;
  });
  const noSuggestions = selected.length > 0 && !!selected[0].value && (isMaintenanceVendor({ composite_id: selected[0].value }) || isMaintenanceVendorInvite({ composite_id: selected[0].value }));
  const handleSelectedChange = (selectedOptions) => {
    setSelected(selectedOptions);
    if (selectedOptions.length === 1) {
      const selectedValue = selectedOptions[0];
      const dropdownErrorProps = getDropdownErrorProps({ selectedValue, allMaintenance: props.allMaintenance });
      if (dropdownErrorProps) {
        setDropdownError(/* @__PURE__ */ React.createElement(DropdownError, __spreadValues({}, dropdownErrorProps)));
        return;
      }
    }
    setDropdownError(void 0);
  };
  return {
    noSuggestions,
    selected,
    setSelected,
    dropdownError,
    setDropdownError,
    handleSelectedChange
  };
};
const AssignMaintenanceDropdown = ({
  dropdownError,
  allMaintenance,
  fullWidth = false,
  selected,
  propertyGroups,
  handleSelectedChange,
  noSuggestions,
  isLoading
}) => {
  return /* @__PURE__ */ React.createElement(EuiFormRow, { label: maintenanceFormLabel, isInvalid: !!dropdownError, error: dropdownError }, /* @__PURE__ */ React.createElement(
    EuiComboBox,
    {
      placeholder: "Search vendors and technicians",
      isInvalid: !!dropdownError,
      "data-testid": "meld-details-edit-assignment-modal-maintenance-dropdown",
      options: getOptions(allMaintenance, selected, propertyGroups),
      selectedOptions: selected,
      rowHeight: 32,
      onChange: handleSelectedChange,
      noSuggestions,
      fullWidth,
      isLoading
    }
  ));
};
const MeldEditAssignmentForm = ({ meld, allMaintenance, onClose }) => {
  const formId = getIdFromText(`meld-details-edit-assignement-form-${meld.id}`);
  const { selected, dropdownError, handleSelectedChange, setDropdownError, noSuggestions } = useAssignMaintenanceField({
    meld,
    allMaintenance
  });
  const mutation = usePatchAssignMeldMaintenance(
    meld,
    !isMeldAssigned(meld) || selected.length > 0 ? "assign" : "unassign"
  );
  const onFormSubmit = (event) => {
    event.preventDefault();
    const parsedUserGroups = [];
    const mappedSelected = selected.map((option) => allMaintenance.find((m) => m.composite_id === option.value)).filter(Boolean);
    if (mappedSelected.length > 1 && !mappedSelected.every(isMaintenanceManagementAgent)) {
      return;
    }
    if (!isMeldAssigned(meld) && mappedSelected.length === 0) {
      setDropdownError("Please make a maintenance selection");
      return;
    }
    mutation.mutate(
      {
        maintenance: mappedSelected,
        user_groups: parsedUserGroups
      },
      {
        onSuccess: onClose,
        onError: (err) => {
          var _a, _b, _c;
          if (((_c = (_b = (_a = err.response) == null ? void 0 : _a.data) == null ? void 0 : _b.non_field_errors) == null ? void 0 : _c[0]) === "Meld is already assigned to this vendor") {
            setDropdownError("Meld is already assigned to this vendor");
          }
        }
      }
    );
  };
  return /* @__PURE__ */ React.createElement(PmFormStyling, { error: void 0 }, /* @__PURE__ */ React.createElement(EuiForm, { fullWidth: true, component: "form", id: formId, onSubmit: onFormSubmit }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column" }, /* @__PURE__ */ React.createElement(
    AssignMaintenanceDropdown,
    {
      dropdownError,
      allMaintenance,
      propertyGroups: getMeldPropertyGroups(meld),
      fullWidth: true,
      selected,
      handleSelectedChange,
      noSuggestions
    }
  ), /* @__PURE__ */ React.createElement(AssignmentCards, { meld, onClose, mutation, allMaintenance })), /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "row",
      alignItems: "flexStart",
      justifyContent: "flexStart",
      style: { gap: "16px", marginTop: "16px" }
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      PmFilledButton,
      {
        color: "primary",
        isLoading: mutation.isLoading,
        formId,
        text: !isMeldAssigned(meld) || selected.length > 0 ? "Assign" : "Unassign",
        minWidth: "121px",
        "data-testid": "meld-details-assign-modal-submit-button"
      }
    )),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmEmptyButton, { onClick: onClose, text: "Close" }))
  )));
};
export { MeldEditAssignmentForm, useAssignMaintenanceField, AssignMaintenanceDropdown, getInitialSelectedMaintenance };
