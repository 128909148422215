import React, { useState } from "react";
import {
  EuiButtonIcon,
  EuiComboBoxOptionOption,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiLink,
  EuiLoadingSpinner,
} from "@elastic/eui";
import { Link, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import moment, { Moment } from "moment";
import { isFuture } from "date-fns";

import { PmPageLoading } from "@pm-frontend/shared/components/Loaders/PmPageLoading";
import { PmCallout } from "@pm-frontend/shared/components/PmCallout";
import { calendarMeldKeys, useBookResidentAvailability, useScheduleMeld } from "../queries";
import { PmText } from "@pm-frontend/shared/components/Text/PmText";
import {
  canAssignmentChange,
  getMeldDetailsLink,
  getMeldPriorityBadgeProps,
  getMeldProperty,
  getMeldStatusBadgeProps,
  isAgentInMeldPropertyGroups,
  isEstimateMeld,
  isMeldAssigned,
  isMeldSchedulable,
  getIsMeldScheduled,
  isPropertyLevelMeld,
  getMeldCoordinates,
  isClosed,
  getMeldPropertyGroups,
} from "@pm-frontend/shared/utils/meld-utils";
import { getFormattedCityStateZip, getTwoLinePropertyNameAddress } from "@pm-frontend/shared/utils/property-utils";
import { PmBadge } from "@pm-frontend/shared/components/PmBadge";
import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { getFullName } from "@pm-frontend/shared/utils/agent-utils";
import { SchedulableMeldDetailViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/scheduable_meld_detail_view_serializer";
import { useGetAllMaintenance } from "@pm-frontend/shared/hooks/queries/useGetAllMaintenance";
import {
  AssignMaintenanceDropdown,
  getInitialSelectedMaintenance,
  useAssignMaintenanceField,
} from "@pm-frontend/routes/Melds/shared/forms/MeldEditAssignmentForm";
import { AllVendorsListRegisteredOnlyFalse } from "@pm-frontend/shared/types/api/maintenance/api";
import {
  getAssignedMaint,
  GetAssignedMaintenanceReturn,
  InviteProps,
  isMaintenanceManagementAgent,
} from "@pm-frontend/shared/utils/assignment-utils";
import { PmFormStyling } from "@pm-frontend/shared/components/Forms/PmFormStyling";
import { PmEmptyButton } from "@pm-frontend/shared/components/Buttons/PmEmptyButton";
import {
  ListItem,
  PmDescriptionList,
  PmDescriptionListProps,
} from "@pm-frontend/shared/components/DetailSection/PmDescriptionList";
import { usePatchAssignMeldMaintenance } from "@pm-frontend/routes/Melds/queries";
import { PmFilledButton } from "@pm-frontend/shared/components/Buttons/PmFilledButton";
import { BorderRadius, colors } from "@pm-frontend/styles";
import { getVendorDetailsLink, getVendorDisplayName } from "@pm-frontend/shared/utils/vendor-utils";
import { AppointmentRequestDetailViewSerializer } from "@pm-frontend/shared/types/api/availability/serializers/appointment_request_detail_view_serializer";
import {
  useCalendarStateActions,
  useCalendarStateScheduledSegments,
} from "@pm-frontend/routes/Calendar/stores/calendarStateStore";
import URL from "@pm-shared/utils/url";
import { PmAvatar } from "@pm-frontend/shared/components/Avatar/PmAvatar";
import { ResidentPresenceRequiredBadge } from "../subcomponents/ResidentPresenceBadge";
import { isSegmentScheduled } from "../utils/utils";
import Features from "@pm-assets/js/common/feature-flags";
import { canAddAvailabilities } from "@pm-frontend/shared/utils/calendar-utils";
import {
  canCreateAssignMelds,
  canScheduleMelds,
  canViewEditReassignMelds,
} from "@pm-frontend/shared/utils/permission-utils";
import { getDisplayStreetAndUnit } from "@pm-frontend/shared/utils/unit-utils";
import { MeldCalendarDatePickers } from "./subcomponents/MeldCalendarDatePickers";

import {
  AgentCreatedMultipleAppointmentsOnInitialSchedule,
  AgentCreatedAdditionalAppointment,
  AgentRescheduledAppointment,
  track,
  CalendarMeldAssign,
  CalendarMeldViewDetailsClicked,
} from "@pm-app/utils/analytics";
import { useGetCalendarEventMetaData, useGetSetActivePaneAndMap } from "../utils/hooks";
import { useGetAgentMe } from "@pm-frontend/shared/hooks/queries/useGetAgents";
import {
  CALENDAR_TIMEFRAMES,
  CALENDAR_TIMEFRAMES_TYPE,
  useCalendarStateSelectedDate,
  useCalendarStateSelectedTimeFrame,
} from "../stores/timeFrameStore";
import { sortDescendingAppointments } from "../maps/utils";
import { DraggingEvent, getCalendarEventOnDragStart } from "../utils/click-drag-and-drop-utils";
import { MeldCalendarModalState } from "../MeldCalendar";
import { useCalendarDragAndDropStateActions } from "../stores/dragDropStore";
import { getCanRescheduleAppointment } from "@pm-frontend/shared/utils/appointment-utils";
import {
  CalendarDraftPendingActions,
  getCalendarDraftModeActions,
  getCalendarDraftModeEnabled,
  getCalendarDraftModePendingActions,
  useCalendarDraftModeEnabled,
  useGetCalendarDraftModePendingActions,
} from "../stores/draftModeStore";
import { getMeldScheduledAddressText } from "../subcomponents/MeldCalendarEvents";
import { ManagementAgentSerializer } from "@pm-frontend/shared/types/api/manager/serializers/serializers";
import { VendorFilterSerializer } from "@pm-frontend/shared/types/api/vendor/serializers/serializers";

const PersonaAvatarLine = ({
  name,
  id,
  type,
  created,
  meld,
}: {
  name: string;
  id: number;
  type: "vendor" | "agent";
  created: string;
  meld: { id: number };
}) => {
  const nameText = <PmText fontSize="p1">{name}</PmText>;
  return (
    <EuiFlexGroup direction="row" responsive={false} alignItems="center" gutterSize="m" key={id}>
      <EuiFlexItem grow={false}>
        <PmAvatar name={name} persona={{ created }} />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {type === "vendor" ? (
          <Link
            to={{
              pathname: getVendorDetailsLink({ id }),
              state: {
                backText: "Back to calendar",
                backUrl: LinkHelper.normalize(RouteUrls.meldCalendar(meld)),
              },
            }}
          >
            <EuiLink style={{ fontSize: "16px" }}>{name}</EuiLink>
          </Link>
        ) : (
          nameText
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

/*
 *  Get assigned maintenance (same return type as getAssignedMaint) from pending draft actions
 */
const getDraftModeAssignedMaint = (
  meld: { id: number },
  pendingDraftActions: CalendarDraftPendingActions[]
):
  | GetAssignedMaintenanceReturn<
      { id: number; first_name?: string; last_name?: string; created: string },
      { id: number; created: string; name?: string; email?: string },
      InviteProps & { created: string }
    >
  | undefined => {
  for (const action of pendingDraftActions) {
    if (action.type === "assignMeld" && action.meldId === meld.id) {
      if (action.assigneeType === "agent") {
        return {
          type: "ManagementAgent",
          in_house_servicers: action.maintenance,
          names: action.maintenance.map((agent) => getFullName(agent)),
        };
      } else {
        return {
          type: "Vendor",
          vendor: action.maintenance[0],
          name: getVendorDisplayName(action.maintenance[0]),
        };
      }
    }
  }
  return undefined;
};

const ReadonlyAssignedMaint = ({
  meld,
  setIsAssigning,
}: {
  meld: SchedulableMeldDetailViewSerializer;
  setIsAssigning: (arg0: boolean) => void;
}) => {
  const draftModeEnabled = useCalendarDraftModeEnabled();
  const pendingDraftActions = useGetCalendarDraftModePendingActions();

  const assignees =
    (draftModeEnabled && getDraftModeAssignedMaint(meld, pendingDraftActions)) || getAssignedMaint(meld);

  let body: ListItem["description"] = "";
  if (!assignees) {
    body = <PmCallout message="Meld is not assigned" />;
  } else if (assignees.type === "ManagementAgent") {
    body = (
      <EuiFlexGroup direction="column" gutterSize="s">
        {assignees.in_house_servicers.map((agent) => (
          <PersonaAvatarLine
            name={getFullName(agent)}
            created={agent.created}
            id={agent.id}
            key={agent.id}
            type="agent"
            meld={meld}
          />
        ))}
      </EuiFlexGroup>
    );
  } else if (assignees.type === "Vendor") {
    body = (
      <EuiFlexGroup direction="column" gutterSize="s">
        <PersonaAvatarLine
          name={assignees.name}
          created={assignees.vendor.created}
          id={assignees.vendor.id}
          type="vendor"
          meld={meld}
        />
      </EuiFlexGroup>
    );
  } else if (assignees.type === "VendorInvite") {
    body = (
      <EuiFlexGroup direction="column" gutterSize="s">
        <PersonaAvatarLine
          name={assignees.name}
          created={assignees.invite_assignment.created}
          id={assignees.invite_assignment.id}
          type="agent"
          meld={meld}
        />
      </EuiFlexGroup>
    );
  }

  return (
    <PmDescriptionList
      data-testid="meld-calendar-details-rightpane-readonly-assignment"
      listItems={[
        {
          title: "Assignees",
          description: body,
          titleAction: {
            text: assignees ? "Reassign" : "Assign",
            onClick: () => setIsAssigning(true),
            enabled: canViewEditReassignMelds && canCreateAssignMelds && canAssignmentChange(meld),
            dataTestId: "meld-calendar-details-rightpane-assign-link",
          },
        },
      ]}
    />
  );
};

const EditAssignedMaint = ({
  meld,
  allMaintenance,
  setIsAssigning,
}: {
  setIsAssigning: (arg0: boolean) => void;
  meld: SchedulableMeldDetailViewSerializer;
  allMaintenance: AllVendorsListRegisteredOnlyFalse;
}) => {
  const draftModeEnabled = useCalendarDraftModeEnabled();
  const eventMetaData = useGetCalendarEventMetaData();

  // we have to control the selected state due to sometimes needing to read from the
  // pending draft actions for assignments
  const [selected, setSelected] = useState<Array<EuiComboBoxOptionOption<string>>>(() => {
    if (draftModeEnabled) {
      const draftAssignedMaintenance = getDraftModeAssignedMaint(meld, getCalendarDraftModePendingActions());
      return getInitialSelectedMaintenance(meld, allMaintenance, draftAssignedMaintenance);
    }
    return getInitialSelectedMaintenance(meld, allMaintenance);
  });

  const { dropdownError, handleSelectedChange, setDropdownError, noSuggestions } = useAssignMaintenanceField({
    meld,
    allMaintenance,
    selected,
    setSelected,
  });

  const queryClient = useQueryClient();
  const assignMutation = usePatchAssignMeldMaintenance(
    meld,
    !isMeldAssigned(meld) || selected.length > 0 ? "assign" : "unassign"
  );

  const onAssignmentSubmit = () => {
    const mappedSelected = selected
      .map((option) => allMaintenance.find((m) => m.composite_id === option.value))
      .filter(Boolean) as Array<Required<AllVendorsListRegisteredOnlyFalse>[number]>;
    // only multiple in-house techs can be assigned
    if (mappedSelected.length > 1 && !mappedSelected.every(isMaintenanceManagementAgent)) {
      return;
    }
    // prevent submission with no selection unless unassigning
    if (!isMeldAssigned(meld) && mappedSelected.length === 0) {
      setDropdownError("Please make a maintenance selection");
      return;
    }
    if (draftModeEnabled) {
      const assignees =
        mappedSelected[0].type === "ManagementAgent"
          ? {
              assigneeType: "agent" as const,
              maintenance: mappedSelected as ManagementAgentSerializer[],
            }
          : {
              assigneeType: "vendor" as const,
              maintenance: mappedSelected as VendorFilterSerializer[],
            };

      getCalendarDraftModeActions().addPendingAction({
        type: "assignMeld",
        meldId: meld.id,
        date: null,
        personaIds: mappedSelected.map((p) => p.id),
        personaType: mappedSelected[0].type === "ManagementAgent" ? "agent" : "vendor",
        recommendationId: undefined,
        ...assignees,
      });
      setIsAssigning(false);
      return;
    }
    if (mappedSelected[0]) {
      track(
        CalendarMeldAssign({
          personaType: mappedSelected[0].type === "ManagementAgent" ? "agent" : "vendor",
          ...eventMetaData,
        })
      );
    }

    assignMutation.mutate(
      {
        maintenance: mappedSelected,
        user_groups: [],
      },
      {
        onSuccess: () => {
          setIsAssigning(false);
          return queryClient.invalidateQueries(calendarMeldKeys.meldDetail(meld.id.toString()));
        },
        onError: (err: AxiosError<{ non_field_errors?: string[] }>) => {
          if (err.response?.data?.non_field_errors?.[0] === "Meld is already assigned to this vendor") {
            setDropdownError("Meld is already assigned to this vendor");
          }
        },
      }
    );
  };

  return (
    <EuiFlexGroup
      direction="column"
      gutterSize="m"
      alignItems="flexStart"
      data-testid="meld-calendar-details-rightpane-edit-assignment"
    >
      <EuiFlexItem grow={true} style={{ width: "100%" }}>
        <AssignMaintenanceDropdown
          dropdownError={dropdownError}
          allMaintenance={allMaintenance}
          propertyGroups={getMeldPropertyGroups(meld)}
          selected={selected}
          handleSelectedChange={handleSelectedChange}
          noSuggestions={noSuggestions}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction="row" responsive={false} gutterSize="m" style={{ flexWrap: "wrap" }}>
          <EuiFlexItem grow={false}>
            <PmFilledButton
              text={!isMeldAssigned(meld) || selected.length > 0 ? "Assign" : "Unassign"}
              onClick={onAssignmentSubmit}
              isLoading={assignMutation.isLoading}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <PmEmptyButton text="Cancel" onClick={() => setIsAssigning(false)} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const AssignedMaintenance = ({
  meld,
  allMaintenance,
}: {
  meld: SchedulableMeldDetailViewSerializer;
  allMaintenance: AllVendorsListRegisteredOnlyFalse;
}) => {
  const [isAssigning, setIsAssigning] = useState<boolean>(() => {
    if (getCalendarDraftModeEnabled()) {
      return false;
    }
    return !isMeldAssigned(meld);
  });

  if (
    isAssigning &&
    canAssignmentChange(meld) &&
    canViewEditReassignMelds &&
    canCreateAssignMelds &&
    !isEstimateMeld(meld)
  ) {
    return <EditAssignedMaint meld={meld} allMaintenance={allMaintenance} setIsAssigning={setIsAssigning} />;
  } else {
    return <ReadonlyAssignedMaint meld={meld} setIsAssigning={setIsAssigning} />;
  }
};

type EventAppointment = {
  id: number;
  availability_segment: {
    event: {
      dtstart: string;
      dtend?: string | null;
    };
  };
};

const getEventFromAppointment = (appt: EventAppointment): CalendarScheduleFormData["events"][number] => {
  return {
    id: appt.id,
    date: moment(appt.availability_segment.event.dtstart).startOf("day"),
    startTime: moment(appt.availability_segment.event.dtstart),
    endTime: appt.availability_segment.event.dtend ? moment(appt.availability_segment.event.dtend) : undefined,
    appointment: appt,
  };
};

const getInitialMeldEvents = (
  meld: SchedulableMeldDetailViewSerializer,
  selectedDate: number,
  selectedTimeframe: CALENDAR_TIMEFRAMES_TYPE
): CalendarScheduleFormData["events"] => {
  const result: CalendarScheduleFormData["events"] = [];
  if (meld.managementappointment?.length > 0) {
    meld.managementappointment.forEach((appt) => {
      // appointments in the past are readonly and not included in the form with multi appt flag on
      if (
        !appt.availability_segment ||
        (Features.isMultipleAppointmentsEnabled() && !getCanRescheduleAppointment(appt))
      ) {
        return;
      }
      result.push(
        getEventFromAppointment({
          id: appt.id,
          availability_segment: appt.availability_segment,
        })
      );
    });
  } else if (meld.vendorappointment?.length > 0) {
    meld.vendorappointment.forEach((appt) => {
      // appointments in the past are readonly and not included in the form with multi appt flag on
      if (
        !appt.availability_segment ||
        (Features.isMultipleAppointmentsEnabled() && !isEstimateMeld(meld) && !getCanRescheduleAppointment(appt))
      ) {
        return;
      }
      result.push(getEventFromAppointment(appt));
    });
  }

  if (result.length > 0) {
    return result.sort((a, b) => {
      if (!a.startTime || !b.startTime) {
        return 0;
      }
      return a.startTime.isBefore(b.startTime) ? -1 : 1;
    });
  } else {
    // on the 1-day view we use the currently selected date, otherwise we
    // use today's date
    const date = selectedTimeframe === CALENDAR_TIMEFRAMES.ONE_DAY ? moment(selectedDate) : moment();
    return [{ id: undefined, date, startTime: undefined, endTime: undefined, appointment: undefined }];
  }
};

const ScheduleButton = ({
  onScheduleClick,
  isLoading,
  showScheduleText,
}: {
  showScheduleText: boolean;
  onScheduleClick: () => void;
  isLoading: boolean;
}) => {
  return (
    <EuiFlexItem grow={false}>
      <PmFilledButton
        text={showScheduleText ? "Schedule" : "Reschedule"}
        onClick={onScheduleClick}
        isLoading={isLoading}
        data-testid="calendar-rightpane-schedule-primary-button"
      />
    </EuiFlexItem>
  );
};
const AddAnotherButton = ({ appendEvent }: { appendEvent: () => void }) => {
  return (
    <EuiFlexItem grow={false}>
      <PmEmptyButton
        text="Add Another"
        onClick={appendEvent}
        data-testid="calendar-rightpane-schedule-addanother-button"
      />
    </EuiFlexItem>
  );
};

const getOnCancelClick = (
  meld: MeldCalendarMeldDetailsProps["meld"],
  modalState: MeldCalendarModalState,
  event: CalendarScheduleFormData["events"][number]
) => {
  if (!event.id || !meld || !canScheduleMelds) {
    return;
  }

  const appointment = event.appointment;
  if (appointment === undefined) {
    return;
  }

  const appointmentIsInFuture =
    appointment.availability_segment && isFuture(new Date(appointment.availability_segment.event.dtstart));
  if (!appointmentIsInFuture) {
    return undefined;
  }
  const assignees = getAssignedMaint(meld);
  const personaType = assignees?.type === "ManagementAgent" ? "agent" : "vendor";

  return () => {
    if (getCalendarDraftModeEnabled()) {
      getCalendarDraftModeActions().addPendingAction({
        type: "cancelAppointment",
        meldId: meld.id,
        appointmentId: appointment.id,
        recommendationId: undefined,
        date: new Date(appointment.availability_segment.event.dtstart),
        personaType,
        // not needed as we don't display anything for canceled appointments
        personaIds: [],
      });
    } else {
      modalState.open({ type: "cancelAppointment", meldId: meld.id, appointmentId: appointment.id });
    }
  };
};

interface CalendarScheduleFormData {
  // for UX purposes we autofill 'date', but empty startTime indicates the field entry should be not submitted
  // id is undefined for new events that are in the midst of being created on the FE
  events: Array<{
    id: number | undefined;
    date: Moment;
    startTime: Moment | undefined;
    endTime: Moment | undefined;
    appointment: EventAppointment | undefined;
  }>;
}

interface ScheduleFormProps {
  meld: SchedulableMeldDetailViewSerializer;
  isMobile: boolean;
  modalState: MeldCalendarModalState;
}

const ScheduleForm = ({ meld, isMobile, modalState }: ScheduleFormProps) => {
  const [dirtySets, setDirtySets] = useState<Record<number, boolean>>({});
  const selectedDate = useCalendarStateSelectedDate();
  const draftModeEnabled = useCalendarDraftModeEnabled();
  const draftModePendingActions = useGetCalendarDraftModePendingActions();
  const selectedTimeFrame = useCalendarStateSelectedTimeFrame(isMobile);
  const {
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<CalendarScheduleFormData>({
    defaultValues: {
      events: getInitialMeldEvents(meld, selectedDate, selectedTimeFrame),
    },
  });
  const events: CalendarScheduleFormData["events"] = watch("events");

  const assignedMaintenance = getAssignedMaint(meld);
  const isMeldScheduledValue = getIsMeldScheduled(meld);

  const isAddingAppointment = isMeldScheduledValue && events.some((event) => event.id === undefined);

  const scheduleMeldMutation = useScheduleMeld(meld, {
    initiator: "form",
    actionType: !isMeldScheduledValue ? "scheduleMeld" : isAddingAppointment ? "addAppointment" : "rescheduleMeld",
  });

  const appendEvent = () => {
    if (draftModeEnabled) {
      // TODO: batch action
      return;
    }
    setValue("events", [
      ...events,
      { id: undefined, date: moment(selectedDate), startTime: undefined, endTime: undefined, appointment: undefined },
    ]);
  };

  const onRescheduleClick = (index: number) => {
    const eventToReschedule = events[index];
    if (!eventToReschedule.id || !eventToReschedule.startTime || !eventToReschedule.endTime) {
      if (!eventToReschedule.startTime) {
        setError(`events.${index}.startTime`, { message: "Please enter a starting time" });
      }
      if (!eventToReschedule.endTime) {
        setError(`events.${index}.endTime`, { message: "Please enter an ending time" });
      }

      return;
    }
    const personaIds =
      (assignedMaintenance?.type === "ManagementAgent" && assignedMaintenance.in_house_servicers.map((a) => a.id)) ||
      (assignedMaintenance?.type === "Vendor" && [assignedMaintenance.vendor.id]) ||
      [];

    if (draftModeEnabled) {
      getCalendarDraftModeActions().addPendingAction({
        type: "rescheduleAppointment",
        meldId: meld.id,
        appointmentId: eventToReschedule.id,
        recommendationId: undefined,
        date: new Date(eventToReschedule.date.toISOString()),
        startTime: new Date(eventToReschedule.startTime.toISOString()),
        endTime: new Date(eventToReschedule.endTime.toISOString()),
        personaType: assignedMaintenance?.type === "ManagementAgent" ? "agent" : "vendor",
        personaIds,
        description: meld.brief_description,
        addressText: getMeldScheduledAddressText(meld) || "",
      });
      return;
    }

    track(AgentRescheduledAppointment());

    scheduleMeldMutation.mutate({
      isReschedulingAppointment: true,
      isAddingAppointment: false,
      events: null,
      startTime: eventToReschedule.startTime,
      durationInMin: eventToReschedule.endTime.diff(eventToReschedule.startTime, "minutes", true),
      appointmentId: eventToReschedule.id ? eventToReschedule.id : null,
    });
  };

  const onScheduleClick = () => {
    if (draftModeEnabled) {
      // TODO: batch action
      return;
    }
    let startTime: Moment;
    let durationInMin: number;

    if (isAddingAppointment) {
      const lastIndex = events.length - 1;
      const lastEvent = events[lastIndex];
      if (!lastEvent?.startTime || !lastEvent.endTime) {
        if (!lastEvent.startTime) {
          setError(`events.${lastIndex}.startTime`, { message: "Please enter a starting time" });
        }
        if (!lastEvent.endTime) {
          setError(`events.${lastIndex}.endTime`, { message: "Please enter an ending time" });
        }
        return;
      }
      startTime = lastEvent.startTime;
      durationInMin = lastEvent.endTime.diff(lastEvent.startTime, "minutes", true);
      track(AgentCreatedAdditionalAppointment());
    } else {
      const firstEvent = events[0];
      if (!firstEvent?.startTime || !firstEvent.endTime) {
        if (!firstEvent.startTime) {
          setError(`events.${0}.startTime`, { message: "Please enter a starting time" });
        }
        if (!firstEvent.endTime) {
          setError(`events.${0}.endTime`, { message: "Please enter an ending time" });
        }
        return;
      }
      startTime = firstEvent.startTime;
      durationInMin = firstEvent.endTime.diff(firstEvent.startTime, "minutes", true);
    }

    if (events.length > 1) {
      track(AgentCreatedMultipleAppointmentsOnInitialSchedule(events.length));
    }

    scheduleMeldMutation.mutate({
      startTime,
      durationInMin,
      events,
      isReschedulingAppointment: false,
      isAddingAppointment,
      appointmentId: null,
    });
  };

  // if the first element has no id it is not a scheduled event
  const showScheduleText = events[0]?.id === undefined || Features.isMultipleAppointmentsEnabled();
  const now = moment();

  return (
    <EuiFlexGroup direction="column" gutterSize="m" data-testid="meld-calendar-details-rightpane-schedule-form">
      {events.map((event, index) => {
        // any queued cancellations are hidden
        if (draftModeEnabled) {
          if (
            draftModePendingActions.some(
              (action) =>
                action.type === "cancelAppointment" &&
                action.meldId === meld.id &&
                action.appointmentId === event.appointment?.id
            )
          ) {
            return null;
          }
        }
        // null if the date is erased via typing
        const onDateChange = (date: Moment | null) => {
          clearErrors();
          const eventId = event.id;
          if (typeof eventId === "number") {
            setDirtySets((dirty) => ({ ...dirty, [eventId]: true }));
          }
          const newEventsValue = [...events];
          if (!newEventsValue[index]) {
            return;
          }
          // the form disables past dates but doesn't prevent typing them in
          if (!date || date.isBefore(now.clone().startOf("day"))) {
            return;
          }
          newEventsValue[index].date = date;
          // we need to make sure startTime and endTime are always on the same date
          const currentStartTime = newEventsValue[index].startTime;
          const currentEndTime = newEventsValue[index].endTime;
          if (currentStartTime) {
            const newStartTime = currentStartTime.clone();
            newStartTime.year(date.year());
            newStartTime.month(date.month());
            newStartTime.date(date.date());
            // avoid edge case - set early time on future date, toggle back to current day where that time is now invalid
            if (newStartTime.isBefore(now)) {
              newEventsValue[index].startTime = undefined;
              newEventsValue[index].endTime = undefined;
            } else {
              newEventsValue[index].startTime = newStartTime;
            }
          }
          if (currentEndTime && newEventsValue[index].startTime !== undefined) {
            const newEndTime = currentEndTime.clone();
            newEndTime.year(date.year());
            newEndTime.month(date.month());
            newEndTime.date(date.date());
            // avoid edge case - set early time on future date, toggle back to current day where that time is now invalid
            if (newEndTime.isBefore(now)) {
              newEventsValue[index].endTime = undefined;
            } else {
              newEventsValue[index].endTime = newEndTime;
            }
          } else {
            newEventsValue[index].endTime = undefined;
          }
          setValue("events", newEventsValue);
        };

        const onFirstTimeChange = (date: Moment | null) => {
          clearErrors();
          const eventId = event.id;
          if (typeof eventId === "number") {
            setDirtySets((dirty) => ({ ...dirty, [eventId]: true }));
          }
          const newEventsValue = [...events];
          if (!newEventsValue[index] || !date) {
            return;
          }
          // when typing the date in the date was not getting set correctly
          const newDate = date.clone();
          newDate.year(newEventsValue[index].date.year());
          newDate.month(newEventsValue[index].date.month());
          newDate.date(newEventsValue[index].date.date());
          // the form disables past times but doesn't prevent typing them in
          if (newDate.isBefore(now)) {
            return;
          }
          const currentStartTime = newEventsValue[index].startTime;
          newEventsValue[index].startTime = newDate;
          // we set the endTime to preserve the duration (or default to 2)
          const currentEndTime = newEventsValue[index].endTime;
          let newEndTime: CalendarScheduleFormData["events"][number]["endTime"];
          if (currentEndTime && currentStartTime) {
            const durationInHours = currentEndTime.diff(currentStartTime, "hours", true);
            newEndTime = newDate.clone().add(durationInHours, "hours");
          } else {
            newEndTime = newDate.clone().add(2, "hours");
          }
          // endTime must remain on the same day
          if (!newDate.isSame(newEndTime, "day")) {
            newEndTime = undefined;
          }
          newEventsValue[index].endTime = newEndTime;
          setValue("events", newEventsValue);
        };

        const onSecondTimeChange = (date: Moment | null) => {
          clearErrors();
          const eventId = event.id;
          if (typeof eventId === "number") {
            setDirtySets((dirty) => ({ ...dirty, [eventId]: true }));
          }
          const newEventsValue = [...events];
          if (!newEventsValue[index] || !date) {
            return;
          }
          // when typing the date in the date was not getting set correctly
          const newDate = date.clone();
          newDate.year(newEventsValue[index].date.year());
          newDate.month(newEventsValue[index].date.month());
          newDate.date(newEventsValue[index].date.date());
          const currentStartTime = newEventsValue[index].startTime;
          // the form disables past times but doesn't prevent typing them in
          if (newDate.isBefore(now)) {
            return;
          }
          // the form disables times past start time and not within 8 hours but doesn't prevent typing them in
          if (
            currentStartTime &&
            (newDate.isBefore(currentStartTime) || newDate.diff(currentStartTime, "hours", true) > 8)
          ) {
            return;
          }
          newEventsValue[index].endTime = newDate;
          setValue("events", newEventsValue);
        };

        const onRemoveClick = () => {
          clearErrors();
          const newEventsValue = [...events];
          newEventsValue.splice(index, 1);
          setValue("events", newEventsValue);
        };

        // only in-progress additions can be removed, determined by event.id === undefined
        const removeLink = (
          <EuiLink onClick={onRemoveClick} style={{ fontSize: "16px" }}>
            Remove
          </EuiLink>
        );

        const onHoursClick = (hours: 2 | 4 | 8) => {
          clearErrors();
          const eventId = event.id;

          const newEventsValue = [...events];
          if (!newEventsValue[index]) {
            return;
          }
          const startTime = newEventsValue[index].startTime || now.clone();

          // we have to keep the date for start_time in sync with the date
          startTime.year(newEventsValue[index].date.year());
          startTime.month(newEventsValue[index].date.month());
          startTime.date(newEventsValue[index].date.date());

          let newEndTime: CalendarScheduleFormData["events"][number]["endTime"] = startTime.clone().add(hours, "hours");
          // we don't allow events to have endTimes on the next day
          if (!newEndTime.isSame(startTime, "day")) {
            newEndTime = undefined;
          }

          if (typeof eventId === "number") {
            if (newEndTime === undefined) {
              setDirtySets((dirty) => ({ ...dirty, [eventId]: false }));
            } else if (event.endTime?.toISOString() !== newEndTime.toISOString()) {
              setDirtySets((dirty) => ({ ...dirty, [eventId]: true }));
            }
          }

          newEventsValue[index].endTime = newEndTime;
          newEventsValue[index].startTime = startTime;
          setValue("events", newEventsValue);
        };

        const dateFieldsAreInvalid = !!errors.events?.[index]?.startTime || !!errors.events?.[index]?.endTime;
        let dateFieldsErrorText: string[] | undefined;
        if (dateFieldsAreInvalid) {
          const dateFieldErrors = [
            errors.events?.[index]?.startTime?.message,
            errors.events?.[index]?.endTime?.message,
          ].filter((m): m is string => typeof m === "string");
          if (errors) {
            dateFieldsErrorText = dateFieldErrors;
          }
        }

        // used to cancel an existing appointments
        const onCancelClick = getOnCancelClick(meld, modalState, event);

        return (
          <EuiFlexItem
            grow={false}
            key={event.id?.toString() || `new-${index}`}
            data-testid={`meld-calendar-schedule-form-fields-${index}`}
          >
            <EuiFormRow
              isInvalid={dateFieldsAreInvalid}
              error={dateFieldsErrorText}
              label={`Date and Time #${index + 1}`}
              labelAppend={event.id || index === 0 ? undefined : removeLink}
            >
              <EuiFlexGroup direction="column" gutterSize="m">
                <MeldCalendarDatePickers
                  isMobile={isMobile}
                  event={event}
                  eventIndex={index}
                  onDateChange={onDateChange}
                  onStartTimeChange={onFirstTimeChange}
                  onEndTimeChange={onSecondTimeChange}
                  errors={errors}
                  onCancelClick={onCancelClick}
                />
                {event.date.isSameOrAfter(now, "dates") && (
                  <EuiFlexGroup direction="row" responsive={true} gutterSize="m" style={{ flexWrap: "wrap" }}>
                    <EuiLink onClick={() => onHoursClick(2)} style={{ fontSize: "16px" }}>
                      2 hours
                    </EuiLink>
                    <EuiLink onClick={() => onHoursClick(4)} style={{ fontSize: "16px" }}>
                      4 hours
                    </EuiLink>
                    <EuiLink onClick={() => onHoursClick(8)} style={{ fontSize: "16px" }}>
                      8 hours
                    </EuiLink>
                  </EuiFlexGroup>
                )}
                {event.id && Features.isMultipleAppointmentsEnabled() && dirtySets[event.id] && (
                  <EuiFlexItem grow={false} style={{ width: "fit-content" }}>
                    <PmEmptyButton
                      onClick={() => {
                        onRescheduleClick(index);
                        const eventId = event.id;
                        if (typeof eventId === "number") {
                          setDirtySets((dirty) => ({ ...dirty, [eventId]: false }));
                        }
                      }}
                      hasBorder={true}
                      isLoading={scheduleMeldMutation.isLoading}
                      text={draftModeEnabled ? "Add to draft" : "Reschedule"}
                      data-testid={`calendar-rightpane-details-event-reschedule-link-${event.id}`}
                    />
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            </EuiFormRow>
          </EuiFlexItem>
        );
      })}
      {canScheduleMelds && (
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" responsive={false} gutterSize="s" style={{ flexWrap: "wrap" }}>
            <>
              {!Features.isMultipleAppointmentsEnabled() && (
                <ScheduleButton
                  showScheduleText={showScheduleText}
                  isLoading={scheduleMeldMutation.isLoading}
                  onScheduleClick={onScheduleClick}
                />
              )}
              {Features.isMultipleAppointmentsEnabled() && (isAddingAppointment || !isMeldScheduledValue) && (
                <ScheduleButton
                  showScheduleText={showScheduleText}
                  isLoading={scheduleMeldMutation.isLoading}
                  onScheduleClick={onScheduleClick}
                />
              )}
              {Features.isMultipleAppointmentsEnabled() && !isAddingAppointment && !isEstimateMeld(meld) && (
                <AddAnotherButton appendEvent={appendEvent} />
              )}
            </>
          </EuiFlexGroup>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
};

/**
 * Gets an array of events grouped by date
 *
 * [
 *   {
 *     dateText: 'Monday, July 10',
 *     segments: [//array of segments]
 *   },
 *   {
 *     dateText: 'Tuesday, July 11',
 *     segments: [//array of segments]
 *   },
 * ]
 *
 */
const getGroupedSegments = (
  availabilities: AppointmentRequestDetailViewSerializer["availabilities"]
): Array<{ dateText: string; segments: AppointmentRequestDetailViewSerializer["availabilities"] }> => {
  const sortedAvailabilities = [...availabilities].sort((a, b) => a.event.dtstart.localeCompare(b.event.dtstart));
  if (sortedAvailabilities.length === 0) {
    return [];
  }
  let currentDateString = moment(sortedAvailabilities[0].event.dtstart).format("dddd, MMMM D");
  let currentIndex = 0;
  const result: ReturnType<typeof getGroupedSegments> = [
    {
      dateText: currentDateString,
      segments: [sortedAvailabilities[0]],
    },
  ];
  for (let i = 1; i < sortedAvailabilities.length; i++) {
    const dateString = moment(sortedAvailabilities[i].event.dtstart).format("dddd, MMMM D");
    if (dateString !== currentDateString) {
      result.push({
        dateText: dateString,
        segments: [sortedAvailabilities[i]],
      });
      currentDateString = dateString;
      currentIndex++;
      continue;
    }
    result[currentIndex].segments.push(sortedAvailabilities[i]);
  }
  return result;
};

const NUM_APPTS_BEFORE_HIDING = 5;
const ResidentAppointments = ({
  residentSegments,
  meld,
}: {
  residentSegments: NonNullable<SchedulableMeldDetailViewSerializer["appointment_request"]>["availabilities"];
  meld: SchedulableMeldDetailViewSerializer;
}) => {
  const draftModeEnabled = useCalendarDraftModeEnabled();
  const [isExpanded, setIsExpanded] = useState(false);
  const bookMutation = useBookResidentAvailability(meld, { initiator: "offeredAvailabilityRightpane" });
  const groupedSegments = getGroupedSegments(residentSegments);
  const hasScheduledAppointment = getIsMeldScheduled(meld);
  const scheduledSegments = useCalendarStateScheduledSegments();
  const { addScheduledSegment, removeScheduledSegment } = useCalendarStateActions();

  const scheduleMeldMutation = useScheduleMeld(meld, {
    initiator: "offeredAvailabilityRightpane",
    actionType: !hasScheduledAppointment
      ? "scheduleMeld"
      : Features.isMultipleAppointmentsEnabled()
      ? "addAppointment"
      : "rescheduleMeld",
  });

  const onAddRequestedAppointment = (segment: AppointmentRequestDetailViewSerializer["availabilities"][number]) => {
    const startTimeStr = segment.event.dtstart;
    const endTimeStr = segment.event.dtend;
    if (!startTimeStr || !endTimeStr) {
      return;
    }

    const startTime = moment(startTimeStr);
    const endTime = moment(endTimeStr);
    const durationInMin = endTime.diff(startTime, "minutes", true);

    scheduleMeldMutation.mutate({
      isReschedulingAppointment: false,
      isAddingAppointment: true,
      events: null,
      startTime,
      durationInMin,
      appointmentId: null,
    });
  };

  const onAddRequestedAppointmentClick = (
    segment: AppointmentRequestDetailViewSerializer["availabilities"][number]
  ) => {
    if (draftModeEnabled) {
      // TODO: batch action
      return;
    }
    onAddRequestedAppointment(segment);
  };

  const onBookClick = (segment: AppointmentRequestDetailViewSerializer["availabilities"][number]) => {
    if (draftModeEnabled) {
      // TODO: batch action
      return;
    }
    if (Features.isMultipleAppointmentsEnabled()) {
      addScheduledSegment(segment);
    } else {
      bookMutation.mutate({ ids: [segment.id] });
    }
  };

  let shownCount = 0;
  const description: PmDescriptionListProps["listItems"][number]["description"] = (
    <EuiFlexGroup direction="column" gutterSize="s" data-testid="meld-calendar-details-rightpane-availability-area">
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction="column" responsive={false} gutterSize="xs">
          {groupedSegments.map((group) => {
            if (!isExpanded && shownCount >= NUM_APPTS_BEFORE_HIDING) {
              return null;
            }

            return (
              <React.Fragment key={group.dateText}>
                <EuiFlexGroup
                  direction="row"
                  responsive={true}
                  style={{ flexWrap: "wrap", columnGap: "12px", rowGap: "4px" }}
                >
                  <EuiFlexItem grow={false}>
                    <PmText fontWeight="semiBold">{group.dateText}</PmText>
                  </EuiFlexItem>
                </EuiFlexGroup>
                {group.segments.map((segment) => {
                  const isScheduled = isSegmentScheduled(segment, meld);

                  if (!isExpanded && shownCount >= NUM_APPTS_BEFORE_HIDING) {
                    return null;
                  }
                  shownCount++;
                  const eventInPast = moment(segment.event.dtstart).isBefore(moment());
                  return (
                    <EuiFlexGroup
                      direction="row"
                      responsive={true}
                      gutterSize="xs"
                      alignItems="flexEnd"
                      data-testid={`meld-calendar-details-rightpane-availability-row-${segment.id}`}
                      style={{ flexWrap: "wrap", columnGap: "4px" }}
                      key={segment.id}
                    >
                      <EuiFlexItem grow={false} style={{ minWidth: "150px" }}>
                        <PmText color={eventInPast ? colors.neutrals.gray600 : undefined}>
                          {`${moment(segment.event.dtstart).format("h a")} - ${
                            segment.event.dtend ? moment(segment.event.dtend).format("h a") : ""
                          }`}
                        </PmText>
                      </EuiFlexItem>
                      {!eventInPast && isMeldSchedulable(meld) && canScheduleMelds && (
                        <EuiFlexItem grow={false} data-testid="meld-calendar-resident-row-schedule-link">
                          {isScheduled ? (
                            <PmText color={colors.interface.green.default}>Scheduled</PmText>
                          ) : hasScheduledAppointment ? (
                            scheduleMeldMutation.isLoading ? (
                              <EuiLoadingSpinner size="m" />
                            ) : (
                              <EuiLink
                                style={{ fontSize: "16px" }}
                                onClick={() =>
                                  Features.isMultipleAppointmentsEnabled()
                                    ? onAddRequestedAppointmentClick(segment)
                                    : onBookClick(segment)
                                }
                              >
                                {Features.isMultipleAppointmentsEnabled() ? "Add Appointment" : "Schedule"}
                              </EuiLink>
                            )
                          ) : bookMutation.isLoading ? (
                            <EuiLoadingSpinner size="m" />
                          ) : (
                            <EuiLink
                              style={{ textDecoration: "none" }}
                              onClick={() => {
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                const isSelected = scheduledSegments.some((s: any) => s.id === segment.id);
                                if (isSelected) {
                                  removeScheduledSegment(segment.id);
                                } else {
                                  onBookClick(segment);
                                }
                              }}
                            >
                              <span
                                style={{
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  color: scheduledSegments.some((s: any) => s.id === segment.id)
                                    ? colors.interface.green.default
                                    : undefined,
                                  fontSize: "18px",
                                }}
                              >
                                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                {scheduledSegments.some((s: any) => s.id === segment.id) ? "Selected" : "Schedule"}
                              </span>
                            </EuiLink>
                          )}
                        </EuiFlexItem>
                      )}
                    </EuiFlexGroup>
                  );
                })}
              </React.Fragment>
            );
          })}
        </EuiFlexGroup>
      </EuiFlexItem>
      {!isExpanded &&
        meld.appointment_request?.availabilities &&
        meld.appointment_request.availabilities.length > NUM_APPTS_BEFORE_HIDING && (
          <EuiFlexItem grow={false}>
            <EuiFlexGroup
              direction="row"
              responsive={false}
              gutterSize="none"
              onClick={() => setIsExpanded(true)}
              style={{ cursor: "pointer", height: "24px" }}
            >
              <EuiFlexItem grow={false}>
                <EuiLink style={{ fontSize: "16px" }}>
                  {`Show ${meld.appointment_request.availabilities.length - NUM_APPTS_BEFORE_HIDING} more`}
                </EuiLink>
              </EuiFlexItem>
              <EuiFlexItem grow={false} style={{ justifyContent: "center" }}>
                <EuiIcon
                  color={colors.brand.meldBlue}
                  type={URL.getStatic("icons/expand-more-open-arrow.svg")}
                  aria-label="See more resident availabilities"
                  size="m"
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        )}
      {scheduledSegments.length > 0 && !hasScheduledAppointment && (
        <EuiFlexItem grow={false}>
          <PmFilledButton
            text="Book Scheduled Segment"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={() => bookMutation.mutate({ ids: scheduledSegments.map((segment: any) => segment.id) })}
            isLoading={bookMutation.isLoading}
            data-testid="meld-calendar-book-scheduled-segment-button"
          />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );

  return (
    <PmDescriptionList
      listItems={[
        {
          title: "Resident availability",
          description,
        },
      ]}
    />
  );
};

type PastSegment =
  | NonNullable<SchedulableMeldDetailViewSerializer["managementappointment"][number]["availability_segment"]>
  | NonNullable<SchedulableMeldDetailViewSerializer["vendorappointment"][number]["availability_segment"]>;

const getPastSegments = (meld: SchedulableMeldDetailViewSerializer): PastSegment[] => {
  const result: PastSegment[] = [];
  // current behavior needs to support editing past appointments
  if (!Features.isMultipleAppointmentsEnabled() || isEstimateMeld(meld)) {
    return result;
  }
  if (meld.managementappointment) {
    meld.managementappointment.forEach((appt) => {
      if (appt.availability_segment && !getCanRescheduleAppointment(appt)) {
        result.push(appt.availability_segment);
      }
    });
  }
  if (meld.vendorappointment) {
    meld.vendorappointment.forEach((appt) => {
      if (appt.availability_segment && !getCanRescheduleAppointment(appt)) {
        result.push(appt.availability_segment);
      }
    });
  }

  return result;
};

const PastSegments = ({ segments }: { segments: PastSegment[] }) => {
  const formattedSegments: PmDescriptionListProps["listItems"][number]["description"] = (
    <>
      {segments.sort(sortDescendingAppointments).map((segment) => (
        <PmText key={segment.id + segment.created}>
          {`${moment(segment.event.dtstart).format("dddd, MMMM D, h:mm a")} - ${
            segment.event.dtend ? moment(segment.event.dtend).format("h:mm a") : ""
          }`}
        </PmText>
      ))}
    </>
  );

  return (
    <PmDescriptionList
      listItems={[
        {
          title: "Previous Appointments",
          description: formattedSegments,
          dataTestId: "meld-calendar-right-pane-details-past-appointments",
        },
      ]}
    />
  );
};

const MeldAddress = ({ meld }: { meld: MeldCalendarMeldDetailsProps["meld"] }) => {
  if (!meld) {
    return null;
  } else if (isPropertyLevelMeld(meld)) {
    return <PmText fontSize="p1">{getTwoLinePropertyNameAddress(getMeldProperty(meld))}</PmText>;
  } else if (meld.unit) {
    return (
      <>
        <PmText fontSize="p1">{getDisplayStreetAndUnit(meld.unit)}</PmText>
        <PmText fontSize="p1">{getFormattedCityStateZip(meld.unit.prop)}</PmText>
      </>
    );
  }
  return null;
};

const MeldDragHandle = ({ meld }: { meld: NonNullable<MeldCalendarMeldDetailsProps["meld"]> }) => {
  const draftModeEnabled = useCalendarDraftModeEnabled();
  const { startDraggingMeld, stopDragging } = useCalendarDragAndDropStateActions();

  const assignees = getAssignedMaint(meld);

  const personaType =
    (assignees?.type === "ManagementAgent" && "agent") || (assignees?.type === "Vendor" && "vendor") || null;

  const personaIds =
    (assignees?.type === "ManagementAgent" && assignees.in_house_servicers.map((a) => a.id)) ||
    (assignees?.type === "Vendor" && [assignees.vendor.id]) ||
    [];

  return (
    <EuiFlexGroup
      direction="row"
      onDragStart={getCalendarEventOnDragStart({
        meld,
        coordinates: getMeldCoordinates(meld),
        additionalCallback: startDraggingMeld,
        appointmentId: null,
        type: DraggingEvent.meld,
        personaType,
        personaIds,
      })}
      onDragEnd={stopDragging}
      draggable={true}
      style={{
        cursor: "grab",
        background: colors.brand.veryLightBlue,
        borderRadius: BorderRadius,
        padding: "4px",
      }}
      alignItems="center"
      gutterSize="xs"
      data-testid="calendar-meld-details-rightpane-drag-handle"
    >
      <EuiFlexItem grow={false}>
        <EuiIcon type="grabHorizontal" />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <PmText fontSize="p2">{`Drag to ${draftModeEnabled ? "add" : "schedule"}`}</PmText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

interface MeldCalendarMeldDetailsProps {
  meld: SchedulableMeldDetailViewSerializer | undefined;
  onClose?: () => void;
  isMobile: boolean;
  modalState: MeldCalendarModalState;
}

const MeldCalendarMeldDetails = ({ meld, onClose, isMobile, modalState }: MeldCalendarMeldDetailsProps) => {
  const { updateRightPaneURL } = useGetSetActivePaneAndMap();
  const pendingDraftActions = useGetCalendarDraftModePendingActions();
  const draftModeEnabled = useCalendarDraftModeEnabled();
  const eventMetaData = useGetCalendarEventMetaData();
  const {
    data: currentAgentDetails,
    isError: currentAgentDetailsIsError,
    isLoading: currentAgentDetailsIsLoading,
  } = useGetAgentMe();

  // rather than drilling isError we read from the cache without triggering a refetch
  const queryClient = useQueryClient();
  const { meldId } = useParams<{ meldId: string | undefined }>();
  const meldDataQuery = meldId ? queryClient.getQueryState(calendarMeldKeys.meldDetail(meldId)) : null;

  const { data: allMaintenanceData, isLoading: maintIsLoading, isError: maintIsError } = useGetAllMaintenance();

  if (meldDataQuery?.status === "error" || maintIsError || currentAgentDetailsIsError) {
    return <PmCallout message="Something went wrong. Try reloading the page." />;
  } else if (
    meld === undefined ||
    allMaintenanceData === undefined ||
    maintIsLoading ||
    currentAgentDetailsIsLoading ||
    currentAgentDetails === undefined
  ) {
    return <PmPageLoading />;
  } else if (!isAgentInMeldPropertyGroups(meld, currentAgentDetails)) {
    return (
      <PmCallout
        message="You do not have permission to view this Meld, as you are not in one of this Meld's property groups."
        data-testid="meld-calendar-right-pane-meld-details"
      />
    );
  }
  const pastSegments = getPastSegments(meld);

  let scheduleArea: React.ReactNode = null;
  if (meld.status === "PENDING_VENDOR") {
    scheduleArea = <PmCallout message="The vendor must accept the Meld before it can be scheduled" />;
  } else if (isMeldSchedulable(meld)) {
    scheduleArea = <ScheduleForm meld={meld} isMobile={isMobile} modalState={modalState} />;
  } else if (isClosed(meld)) {
    scheduleArea = <PmCallout message="Closed Melds cannot be scheduled" />;
  }

  const offerAvailabilityLink = canAddAvailabilities(meld) && (
    <EuiFlexItem grow={true} data-testid="meld-calendar-details-offer-availability-link">
      <EuiLink
        onClick={() =>
          updateRightPaneURL({ newRightpaneState: { type: "offerAvailabilities", meldId: meld.id.toString() } })
        }
      >
        Offer availability to resident
      </EuiLink>
    </EuiFlexItem>
  );

  // since edit maintenance is a form, if we have a change in the pending draft actions we need to unmount/mount
  // the component
  const assignMaintenanceKey =
    (draftModeEnabled &&
      pendingDraftActions.find((action) => action.type === "assignMeld" && action.meldId === meld.id)?.uuid) ||
    "default";

  return (
    <EuiFlexGrid
      columns={1}
      style={{ gridTemplateColumns: "100%" }}
      data-testid="meld-calendar-right-pane-meld-details"
    >
      {!onClose && !isMobile && <MeldDragHandle meld={meld} />}
      <EuiFlexGroup direction="row" alignItems="center" responsive={false} justifyContent="spaceBetween">
        <EuiFlexItem grow={false}>
          <PmText fontSize="p1">{meld.brief_description}</PmText>
        </EuiFlexItem>
        {onClose !== undefined && (
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              display="empty"
              iconType={URL.getStatic("icons/close.svg")}
              aria-label="Close flyout"
              onClick={onClose}
            />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      <EuiFlexItem grow={false} className="preWhiteSpace">
        <MeldAddress meld={meld} />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup
          direction="row"
          style={{ maxWidth: "100%", flexWrap: "wrap", rowGap: "4px", columnGap: "8px" }}
          alignItems="center"
        >
          <EuiFlexItem grow={false} style={{ lineHeight: "14px" }}>
            <PmText fontSize="p1">#{meld.reference_id}</PmText>
          </EuiFlexItem>
          {meld.tenant_presence_required && (
            <EuiFlexItem grow={false}>
              <ResidentPresenceRequiredBadge />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexGroup direction="row" style={{ maxWidth: "100%", flexWrap: "wrap", rowGap: "4px", columnGap: "8px" }}>
        <EuiFlexItem grow={false}>
          <PmBadge {...getMeldStatusBadgeProps(meld)} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <PmBadge {...getMeldPriorityBadgeProps(meld)} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexItem grow={false}>
        <Link
          to={{
            pathname: getMeldDetailsLink(meld),
            state: { backText: "Back to Calendar", backUrl: LinkHelper.normalize(RouteUrls.meldCalendar(meld)) },
          }}
          onClick={() => track(CalendarMeldViewDetailsClicked({ meldId: meld.id, ...eventMetaData }))}
          style={{ width: "max-content" }}
        >
          <EuiLink style={{ fontSize: "16px" }}>
            {isEstimateMeld(meld) ? "View estimate details" : "View details"}
          </EuiLink>
        </Link>
      </EuiFlexItem>
      <EuiFlexItem grow={true} data-testid="meld-calendar-details-rightpane-assign-area">
        <AssignedMaintenance meld={meld} allMaintenance={allMaintenanceData} key={assignMaintenanceKey} />
      </EuiFlexItem>
      {meld.tenant_presence_required &&
        meld.appointment_request?.availabilities &&
        meld.appointment_request.availabilities.length > 0 && (
          <EuiFlexItem grow={true}>
            <ResidentAppointments residentSegments={meld.appointment_request.availabilities} meld={meld} />
          </EuiFlexItem>
        )}
      {scheduleArea && (
        <EuiFlexItem grow={true} data-testid="meld-calendar-right-pane-schedule-area">
          <PmFormStyling error={undefined}>
            <EuiForm fullWidth={true}>{scheduleArea}</EuiForm>
          </PmFormStyling>
        </EuiFlexItem>
      )}
      {pastSegments.length > 0 && (
        <EuiFlexItem grow={true}>
          <PastSegments segments={pastSegments} />
        </EuiFlexItem>
      )}
      {offerAvailabilityLink}
    </EuiFlexGrid>
  );
};

export { MeldCalendarMeldDetails, getInitialMeldEvents, CalendarScheduleFormData, getDraftModeAssignedMaint };
