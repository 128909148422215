import { PmCard } from "@pm-frontend/shared/components/Card/PmCard";
import { PmText } from "@pm-frontend/shared/components/Text/PmText";
import React, { useState } from "react";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiLink,
  EuiSpacer,
  EuiToolTip,
} from "@elastic/eui";
import URL from "@pm-shared/utils/url";
import { colors } from "@pm-frontend/styles";
import { getFilterByPropertyGroups } from "@pm-frontend/shared/utils/assignment-utils";
import { getMeldPropertyGroups } from "@pm-frontend/shared/utils/meld-utils";
import { getFullName } from "@pm-frontend/shared/utils/agent-utils";
import { getVendorDisplayName } from "@pm-frontend/shared/utils/vendor-utils";
import { MaintTypes } from "@pm-frontend/shared/types/meld";
import { PmEmptyButton } from "@pm-frontend/shared/components/Buttons/PmEmptyButton";
import { UseBaseMutationResult } from "@tanstack/react-query";
import { AssignMeldMaintenanceData } from "../../queries";
import { AssignRecommended, track } from "@pm-app/utils/analytics";
import { css } from "@emotion/react";
import { MeldToBeAssigned } from "../../shared/flyouts/MeldAssignmentFlyout";
import {
  ActiveVendorsListRecommendations,
  ManagementAgentRecSerializer,
  VendorFilterRecSerializer,
} from "@pm-frontend/shared/types/api/recommend/serializers/agent_vendor_serializers";

const RECOMMEND_RESULT_SIZE = 6;

enum ReasonOptions {
  prior_work_at_prop = "prior_work_at_prop",
  prior_work_in_cat = "prior_work_in_cat",
  sor_days = "sor_days",
  avg_resident_sat = "avg_resident_sat",
  median_invoice = "median_invoice",
  most_recent_tenant_review_at_unit = "most_recent_tenant_review_at_unit",
  avail_overlaps_resident = "avail_overlaps_resident",
}

const keyToLabel: Record<string, string[]> = {
  prior_work_at_prop: ["Prior work at property", ": ", " Melds"],
  prior_work_in_cat: ["Prior work in category", ": ", " Melds"],
  sor_days: ["Speed of Repair", ": ", " days"],
  avg_resident_sat: ["Resident Satisfaction", ": ", "/5"],
  median_invoice: ["Median invoice", ": ", ""],
  most_recent_tenant_review_at_unit: ["Last resident review for unit", ': "', '"'],
  avail_overlaps_resident: ["Availability overlaps with resident", ": ", ""],
};

const RecommendationLabel = (
  <EuiFlexGroup direction="column" gutterSize="none">
    <EuiFlexGroup direction="row" responsive={false} gutterSize="s" alignItems="center">
      <EuiFlexItem grow={false}>
        <PmText fontWeight="bold" fontSize="p2">
          Assignment Recommendations
        </PmText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiToolTip
          className="preWhiteSpace"
          content={`Recommendations are based on factors such as previous work assignments, Meld history, and Meld categories. They're made based on Property Meld's machine learning model.`}
          anchorProps={{ style: { display: "flex" } }}
        >
          <EuiIcon type={URL.getStatic("icons/questionmark.svg")} aria-label="Show maintenance tooltip" size="s" />
        </EuiToolTip>
      </EuiFlexItem>
    </EuiFlexGroup>
    <EuiFlexItem grow={false}>
      <PmText fontWeight="regular" fontSize="p3" color={colors.neutrals.gray600}>
        Recommendations are delivered with the best match first.
      </PmText>
    </EuiFlexItem>
  </EuiFlexGroup>
);

function truncateString(str: string, maxLength: number): string {
  return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
}

const isOptionDisabled = (
  maint: { id: number; composite_id: string; vendor_managements?: Array<{ allow_assignments: boolean }> },
  recommendations: Array<{ id: number }>
): string | undefined => {
  const isValid = recommendations.find((m) => m.id === maint.id);
  // maint must be in a valid property group
  if (!isValid) {
    return "(Not in property group)";
  }
  // vendors who are marked unassignable
  if (maint.vendor_managements?.[0]?.allow_assignments === false) {
    return "(Not assignable)";
  }
  return undefined;
};

const mapMetrics = (maint: VendorFilterRecSerializer | ManagementAgentRecSerializer): Reason[] => {
  if (!maint.recommendation?.metrics) {
    return [];
  }

  return Object.entries(maint.recommendation.metrics)
    .filter(([key, value]) => keyToLabel[key] && value && value !== "")
    .map(([key, value]) => {
      const [label, prefix, unit] = keyToLabel[key];

      let formattedValue = `${prefix}${value}${unit}`;
      if (key === ReasonOptions.most_recent_tenant_review_at_unit) {
        formattedValue = `${prefix}${truncateString(value, 47)}${unit}`;
      } else if (key === ReasonOptions.prior_work_at_prop || key === ReasonOptions.prior_work_in_cat) {
        formattedValue = `${prefix}${value} ${value === 1 ? "Meld" : "Melds"}`;
      }

      return { name: key, description: `${label}${formattedValue}` };
    });
};

// maps the all-maintenance endpoint to the select options, and also filters
// out based on property groups
const getOptions = (recommendations: ActiveVendorsListRecommendations, meld: MeldToBeAssigned): Recommendation[] => {
  const filterFunction = getFilterByPropertyGroups(getMeldPropertyGroups(meld));
  const validMaintenances = recommendations.filter(filterFunction);
  return recommendations.reduce((result, maint) => {
    const disabledReason: string | undefined = isOptionDisabled(maint, validMaintenances);
    let label = "";
    let type = "";
    if (disabledReason) {
      // we don't want to recommend invalid assignees
      return result;
    } else {
      switch (maint.type) {
        case MaintTypes.VENDOR:
          label = disabledReason
            ? `${getVendorDisplayName(maint)} ${disabledReason}`
            : `${getVendorDisplayName(maint)} (Vendor)`;
          type = "Vendor";
          break;
        case MaintTypes.MANAGEMENT_AGENT:
          label = disabledReason ? `${getFullName(maint)} ${disabledReason}` : getFullName(maint);
          break;
        default:
          break;
      }

      const reasons = mapMetrics(maint);
      result.push({
        label,
        value: maint.composite_id,
        disabled: !!disabledReason,
        type,
        reasons,
      });

      return result.slice(0, RECOMMEND_RESULT_SIZE);
    }
  }, [] as Recommendation[]);
};

interface AssignRecommendationCardProps {
  meld: MeldToBeAssigned;
  onClose: () => void;
  mutation: UseBaseMutationResult<unknown, unknown, AssignMeldMaintenanceData, unknown>;
  recommendationData: ActiveVendorsListRecommendations;
}

interface Recommendation {
  label: string;
  value: string;
  disabled?: boolean;
  type?: string;
  reasons: Reason[];
}

interface Reason {
  name: string;
  description: string;
}

const AssignRecommendationCard = ({ meld, onClose, mutation, recommendationData }: AssignRecommendationCardProps) => {
  const [viewAll, setViewAll] = useState<boolean>(false);
  // const isSmallWindow = () => window.matchMedia("(max-width: 490px)").matches;
  const calculateButtonPosition = (text: string, font: string): number => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.font = font;
      return context.measureText(text).width;
    }
    return 0;
  };

  const recommendationOptions: Recommendation[] = getOptions(recommendationData, meld);

  const longestLabel = recommendationOptions?.length
    ? recommendationOptions.reduce((a, b) => (b.label.length > a.label.length ? b : a)).label
    : "";

  const buttonPosition = longestLabel
    ? calculateButtonPosition(`1. ${longestLabel}`, "16px Helvetica")
    : calculateButtonPosition("1. ", "16px Helvetica");

  const onAssignClick = (value: string, index: number) => {
    const mappedSelected = recommendationData.find((m: { composite_id: string }) => m.composite_id === value);

    if (!mappedSelected) {
      return;
    }
    track(AssignRecommended(index, meld.id));
    mutation.mutate(
      {
        maintenance: [mappedSelected],
        user_groups: [],
      },
      {
        onSuccess: onClose,
      }
    );
  };

  return (
    <EuiFlexItem grow={true} style={{ maxWidth: "500px", margin: "0px" }}>
      <PmCard grow={false} panelClassName="card" padding="16px 20px" flex="1">
        <EuiFormRow label={RecommendationLabel}>
          <EuiFlexGroup direction="column" gutterSize="none">
            {recommendationOptions
              .filter((_, i) => viewAll || i < 1)
              .map(({ label, value, disabled, reasons }, index) => (
                <EuiFlexGroup direction="row" responsive={false} gutterSize="none" alignItems="flexStart" key={index}>
                  <EuiFlexGroup
                    direction="row"
                    responsive={true}
                    gutterSize="none"
                    style={{
                      maxWidth: `${Math.min(buttonPosition, 345) + 24}px`,
                      minWidth: `${Math.min(buttonPosition, 345) + 24}px`,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "inline", overflowX: "hidden" }}>
                      <PmText
                        color={!disabled ? colors.neutrals.gray900 : colors.neutrals.gray500}
                        fontWeight="regular"
                        fontSize="p2"
                      >
                        {index + 1}. {label}
                      </PmText>
                    </div>
                    {reasons && reasons.length > 0 && (
                      <div style={{ display: "inline", margin: "4px 0px 0px 4px" }}>
                        <EuiToolTip
                          content={
                            <div style={{ margin: "0px 4px" }}>
                              <PmText
                                fontSize="p2"
                                color={colors.brand.white}
                                fontWeight="semiBold"
                                style={{ whiteSpace: "nowrap", width: "100%" }}
                              >
                                Why this assignee is recommended
                              </PmText>
                              <EuiHorizontalRule margin="xs" />
                              <ul style={{ listStyle: "disc", paddingLeft: "16px" }}>
                                {reasons.map((reason) => (
                                  <li key={reason.name}>{reason.description}</li>
                                ))}
                              </ul>
                            </div>
                          }
                        >
                          <EuiFlexItem grow={false} style={{ width: "16px", padding: "1px" }}>
                            <EuiIcon type={URL.getStatic("icons/network_intelligence_gray.svg")} size="original" />
                          </EuiFlexItem>
                        </EuiToolTip>
                      </div>
                    )}
                  </EuiFlexGroup>
                  <EuiFlexItem
                    aria-label={`Select ${label}`}
                    grow={false}
                    style={{ alignItems: "center", paddingLeft: `8px` }}
                  >
                    <PmEmptyButton
                      onClick={() => onAssignClick(value, index + 1)}
                      text="Assign"
                      hasBorder={false}
                      textSize="14px"
                      padding="0px"
                      isDisabled={disabled}
                      aria-label={`Select ${label}`}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              ))}
            {recommendationOptions.length > 1 && (
              <>
                <EuiSpacer size="xs" />
                <EuiFlexGroup direction="row" responsive={false} alignItems="center" gutterSize="none">
                  <EuiLink
                    onClick={() => setViewAll((state) => !state)}
                    css={css`
                      & {
                        text-decoration: none;
                      }
                      &:hover {
                        text-decoration: underline;
                      }
                    `}
                  >
                    <PmText color={colors.brand.meldBlue} fontWeight="regular" fontSize="p2">
                      {viewAll ? (
                        <>
                          See less
                          <EuiIcon
                            color={colors.brand.meldBlue}
                            type={URL.getStatic("icons/expand-more-close-arrow.svg")}
                            aria-label="See less recommendations"
                            size="m"
                          />
                        </>
                      ) : (
                        <>
                          See more
                          <EuiIcon
                            color={colors.brand.meldBlue}
                            type={URL.getStatic("icons/expand-more-open-arrow.svg")}
                            aria-label="See more recommendations"
                            size="m"
                          />
                        </>
                      )}
                    </PmText>
                  </EuiLink>
                </EuiFlexGroup>
              </>
            )}
          </EuiFlexGroup>
        </EuiFormRow>
      </PmCard>
    </EuiFlexItem>
  );
};

export { AssignRecommendationCard };
