import { MarkerProps } from "@pm-frontend/shared/hooks/useMap";
import { getAccessibleTextColor } from "@pm-frontend/shared/utils/color-utils";
import { colors } from "@pm-frontend/styles";
import { AggregatedCalendarEvent } from "../utils/aggregated-calendar-events-utils";
import { getMeldCoordinates } from "@pm-frontend/shared/utils/meld-utils";
import { LatLongCoordinates } from "@pm-frontend/shared/utils/location-utils";
import { MeldCalendarProximityListViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/calendar_meld_proximity_list_serializer";
import { calculateDistance } from "@pm-frontend/routes/Melds/MeldDetails/rightpane/subcomponents/MeldsProximity/utils";
import { useGetCalendarEventMetaData, useGetSetActivePaneAndMap } from "../utils/hooks";
import { isEventAvailability } from "../utils/utils";
import { CalendarMeldMapNearbyMeldOpened, track } from "@pm-app/utils/analytics";
import { CalendarSelectedAgentsVendorsIds } from "@pm-frontend/routes/Calendar/stores/calendarStateStore";
import { format } from "date-fns";
import { useGeocodingCore } from "@mapbox/search-js-react";
import { GoogleCalendarEvent } from "@pm-frontend/shared/types/api/calendar_integrations/api/api";
import { useCalendarMapStateActions } from "../stores/mapStore";
import { getMeldInHouseServicers } from "@pm-frontend/shared/utils/meld-utils";
import { StorageUtils } from "@pm-frontend/shared/utils/storage-utils";

const MAPBOX_ACCESS_TOKEN: string = window.PM.mapbox_public_token;

// just a utility type for grabbing two specific variants of AggregatedCalendarEvent union
type ExtractMember<U, T extends U[keyof U]> = U extends { type: T } ? U : never;

export type AggregatedEventsShownOnMap =
  | ExtractMember<AggregatedCalendarEvent, "management_scheduled">
  | ExtractMember<AggregatedCalendarEvent, "vendor_scheduled">
  | ExtractMember<AggregatedCalendarEvent, "alternative_event_scheduled">
  | ExtractMember<AggregatedCalendarEvent, "google_calendar_event">
  | ExtractMember<AggregatedCalendarEvent, "outlook_calendar_event">
  | {
      type: "nearby_melds";
      meld: MeldCalendarProximityListViewSerializer;
      start: null;
      end: null;
      coordinates: LatLongCoordinates | undefined;
    };

export type CalendarMapMarkerType = {
  personas: Array<{
    count: number;
    color: string;
    // undefined represents markers not associated with a persona (such as nearby Melds)
    compositeId: string | undefined;
    name: string | undefined;
  }>;
} & AggregatedEventsShownOnMap;

// should allow for three items internally before scrolling
export const CALENDAR_MAP_CHICKLET_MAX_HEIGHT_VALUE_IN_PX = 146;
const CHICKLET_MAX_HEIGHT = `${CALENDAR_MAP_CHICKLET_MAX_HEIGHT_VALUE_IN_PX}px`;

export const CALENDAR_MAP_CHICKLET_MAX_WIDTH_VALUE_IN_PX = 130;

export const sortEvents = (event1: { dtstart: string }, event2: { dtstart: string }) =>
  event1.dtstart.localeCompare(event2.dtstart);

export const sortAscendingAppointments = (
  event1: { event: { dtstart: string } },
  event2: { event: { dtstart: string } }
) => event1.event.dtstart.localeCompare(event2.event.dtstart);

export const sortDescendingAppointments = (
  event1: { event: { dtstart: string } },
  event2: { event: { dtstart: string } }
) => event2.event.dtstart.localeCompare(event1.event.dtstart);

const createCircleWithNumber = (color: string, personaRunningCount: number | string): HTMLElement => {
  const circle = document.createElement("span");
  // falsey values (such as 0 for nearby melds) are shown as blank string
  circle.textContent = personaRunningCount ? personaRunningCount.toString() : "";
  circle.style.width = "16px";
  circle.style.height = "16px";
  circle.style.backgroundColor = color;
  circle.style.color = getAccessibleTextColor(color);
  circle.style.borderRadius = "50%";
  circle.style.position = "relative";
  circle.style.display = "flex";
  circle.style.alignItems = "center";
  circle.style.justifyContent = "center";
  circle.style.fontSize = "10px";
  circle.style.paddingTop = "1px";
  return circle;
};

const CHICKLET_TIME_FORMAT = "h:mm aaa";

interface MarkerChickletItem {
  start: string | null;
  end: string | null;
  personas: Array<{
    color: string;
    count: number;
  }>;
}

// we create an element using DOM apis as we create a mapbox marker
// using this element
export const createMarkerChicklet = <T extends MarkerChickletItem>(
  marker: MarkerProps<T>,
  getOnViewClick: (coordinates: MarkerProps<T>["coordinates"], items: T[]) => (mapRef: mapboxgl.Map) => void,
  getLinkText: (items: T[]) => string,
  getDataTestId: (items: T[], coordinates: MarkerProps<T>["coordinates"]) => string,
  map: mapboxgl.Map
) => {
  const outerContainer = document.createElement("div");
  outerContainer.style.maxHeight = CHICKLET_MAX_HEIGHT;
  outerContainer.style.height = "auto";
  outerContainer.style.overflow = "auto";
  outerContainer.className = "eui-yScroll";
  outerContainer.style.border = `1px solid ${marker.color}`;
  outerContainer.style.borderRadius = "6px";
  outerContainer.style.padding = "6px";
  outerContainer.style.background = colors.brand.white;
  outerContainer.setAttribute("data-testid", getDataTestId(marker.items, marker.coordinates) + "-chicklet");

  const div = document.createElement("div");
  outerContainer.append(div);
  div.style.font = '"Open Sans", sans-serif';
  div.style.fontSize = "12px";
  div.style.background = colors.brand.white;
  div.style.width = "fit-content";

  const circlesSpan = document.createElement("div");
  circlesSpan.style.display = "flex";
  circlesSpan.style.gap = "2px";
  const linkDiv = document.createElement("a");
  linkDiv.onclick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    getOnViewClick(marker.coordinates, marker.items)(map);
  };
  linkDiv.style.cursor = "pointer";

  const itemContainer = document.createElement("div");
  const circleLinkContainer = document.createElement("div");

  circleLinkContainer.style.display = "flex";
  circleLinkContainer.style.gap = "2px";
  circleLinkContainer.style.alignItems = "center";

  if (marker.items.length === 1) {
    linkDiv.textContent = `View ${getLinkText(marker.items)}`;
    const item = marker.items[0];
    item.personas.forEach((person) => {
      const circle = createCircleWithNumber(person.color, person.count);
      circlesSpan.append(circle);
    });
    const timeDiv = document.createElement("div");
    const startTime = item.start ? format(new Date(item.start), CHICKLET_TIME_FORMAT) : null;
    const endTime = item.end ? format(new Date(item.end), CHICKLET_TIME_FORMAT) : "";
    timeDiv.textContent = startTime && endTime ? `${startTime} - ${endTime}` : null;

    circleLinkContainer.append(circlesSpan);
    circleLinkContainer.append(linkDiv);
    itemContainer.append(circleLinkContainer);
    if (startTime && endTime) {
      itemContainer.append(timeDiv);
    }
  } else if (marker.items.length > 0) {
    const uniqueColors: Record<string, boolean> = {};
    linkDiv.textContent = `View ${marker.items.length} ${getLinkText(marker.items)}`;
    // add persona sequence circles
    marker.items.forEach((item) => {
      item.personas.forEach((person) => {
        uniqueColors[person.color] = true;
      });
    });
    Object.keys(uniqueColors).forEach((color) => {
      const circle = createCircleWithNumber(color, "");
      circlesSpan.append(circle);
    });

    circleLinkContainer.append(linkDiv);
    itemContainer.append(circleLinkContainer);
    itemContainer.append(circlesSpan);
  }

  div.appendChild(itemContainer);
  marker.extraElement = outerContainer;
};

export const getMapPinDataTestId = (
  items: CalendarMapMarkerType[],
  coordinates: MarkerProps<CalendarMapMarkerType>["coordinates"]
): string => {
  if (items.length === 1) {
    const item = items[0];
    if (item.type === "vendor_scheduled") {
      return `meld-calendar-marker-vendor-appt-${item.segment.id}`;
    } else if (item.type === "management_scheduled") {
      return `meld-calendar-marker-agent-appt-${item.segment.id}`;
    } else if (item.type === "alternative_event_scheduled") {
      return `meld-calendar-marker-alt-event-${item.altEvent.id}`;
    } else if (item.type === "google_calendar_event") {
      return `meld-calendar-marker-google-event-${item.event.id}`;
    } else if (item.type === "nearby_melds") {
      return `meld-calendar-marker-nearby-meld-${item.meld.id}`;
    }
  } else {
    return `meld-calendar-map-multiple-event-lat-${coordinates?.latitude}-long-${coordinates?.longitude}`;
  }

  return "";
};

export const getMapChickletItemsText = (items: CalendarMapMarkerType[]): string => {
  let meldPresent = false;
  let eventPresent = false;

  for (const item of items) {
    if (item.type === "management_scheduled" || item.type === "vendor_scheduled" || item.type === "nearby_melds") {
      meldPresent = true;
    } else if (item.type === "alternative_event_scheduled" || item.type === "google_calendar_event") {
      eventPresent = true;
    }
  }
  let result = "item";
  if (meldPresent && eventPresent) {
    result = "item";
  } else if (meldPresent) {
    result = "Meld";
  } else if (eventPresent) {
    result = "event";
  }

  if (items.length > 1) {
    result += "s";
  }
  return result;
};

// this hook returns a function, which given an item on the map returns an onlick
// function for the link which will be attached to that item
export const useGetCalendarMapItemOnClick = (
  mapType: "large" | "small-agent" | "small-vendor"
): ((
  coordinates: MarkerProps<CalendarMapMarkerType>["coordinates"],
  items: CalendarMapMarkerType[]
) => (map: mapboxgl.Map) => void) => {
  const { updateRightPaneURL } = useGetSetActivePaneAndMap();
  const { setMapEventListRightpaneItems } = useCalendarMapStateActions();
  const eventMetaData = useGetCalendarEventMetaData();

  return (coordinates, items) => (map: mapboxgl.Map) => {
    if (items.length === 1) {
      const item = items[0];
      if (item.type === "management_scheduled" || item.type === "vendor_scheduled" || item.type === "nearby_melds") {
        updateRightPaneURL({ newRightpaneState: { type: "meldDetails", meldId: item.meld.id.toString() } });
        if (item.type === "nearby_melds") {
          track(
            CalendarMeldMapNearbyMeldOpened({
              type: mapType,
              ...eventMetaData,
            })
          );
        }
      } else if (item.type === "alternative_event_scheduled") {
        updateRightPaneURL({
          newRightpaneState: { type: "alternativeEvent", mode: "edit", eventId: item.altEvent.id.toString() },
        });
      } else if (item.type === "google_calendar_event") {
        updateRightPaneURL({ newRightpaneState: { type: "googleCalendarEvent", eventId: item.event.id.toString() } });
      }
    } else if (items.length > 1) {
      setMapEventListRightpaneItems(items);
      updateRightPaneURL({ newRightpaneState: { type: "mapEventList" } });
    }

    if (coordinates) {
      map.easeTo({ center: { lat: coordinates.latitude, lng: coordinates.longitude } });
    }
  };
};

function isValidAddress(value: string): boolean {
  // Check if the string looks like a URL
  const urlRegex = /^(https?:\/\/|www\.)[^\s]+$/i;
  if (urlRegex.test(value)) {
    return false;
  }
  return true;
}

export const useGoogleEventCoordinates = () => {
  const GEOCODINGCORE = useGeocodingCore({ accessToken: MAPBOX_ACCESS_TOKEN });

  const getMapboxGoogleEventCoordinates = async (
    event: GoogleCalendarEvent,
    managementLatLong: {
      latitude: string | undefined;
      longitude: string | undefined;
    }
  ) => {
    if (!("location" in event) || !event.location || event.location === undefined) {
      return;
    }
    if (typeof event.location !== "string" || !isValidAddress(event.location)) {
      return;
    }

    // If location is already retrieved and in localstorage, grab it, no need to call MapBox again
    const { value } = StorageUtils.getLocalStorageItem(event.location);
    if (value) {
      return JSON.parse(value);
    }
    const geocodingOptions = {
      country: "US,CA",
      limit: 1,
      ...(managementLatLong.longitude !== undefined &&
        managementLatLong.latitude !== undefined && {
          proximity: `${managementLatLong.longitude},${managementLatLong.latitude}`,
        }),
    };

    const response = await GEOCODINGCORE.forward(event.location, geocodingOptions);

    if (response && response.features[0]) {
      const parsedLat = response.features[0].geometry.coordinates[1];
      const parsedLong = response.features[0].geometry.coordinates[0];

      if (!Number.isNaN(parsedLat) && !Number.isNaN(parsedLong)) {
        const data = {
          mapbox_id: response.features[0].properties.mapbox_id,
          original_string: event.location,
          name: response.features[0].properties.name,
          full_address: response.features[0].properties.full_address,
          latitude: parsedLat,
          longitude: parsedLong,
        };

        // To avoid excess calls to MapBox for the same location, store them in localStorage
        StorageUtils.setLocalStorageItem(event.location, JSON.stringify(data));
        return data;
      }
    }
  };
  return { getMapboxGoogleEventCoordinates };
};

const DISTANCE_MARGIN_IN_MILES = 3;

/**
 * Used to get a radial distance in which to find nearby melds
 *
 * We find the min/max lat/long and add margin in each direction
 */
export const getNearbyDistanceInMiles = (
  filteredEvents: AggregatedEventsShownOnMap[],
  centerCoordinates: LatLongCoordinates
): number | undefined => {
  const max: LatLongCoordinates = { latitude: Number.MIN_SAFE_INTEGER, longitude: Number.MIN_SAFE_INTEGER };
  const min: LatLongCoordinates = { latitude: Number.MAX_SAFE_INTEGER, longitude: Number.MAX_SAFE_INTEGER };
  let found = false;
  filteredEvents.forEach((event) => {
    const coordinates = event.coordinates;
    if (!coordinates) {
      return;
    }
    found = true;
    if (coordinates.latitude > max.latitude) {
      max.latitude = coordinates.latitude;
    }
    if (coordinates.longitude > max.longitude) {
      max.longitude = coordinates.longitude;
    }
    if (coordinates.latitude < min.latitude) {
      min.latitude = coordinates.latitude;
    }
    if (coordinates.longitude < min.longitude) {
      min.longitude = coordinates.longitude;
    }
  });

  if (!found) {
    return;
  }

  // compute the greatest distance to each corner of the box bounded by
  // min/max coordinates
  const corners: LatLongCoordinates[] = [
    { latitude: min.latitude, longitude: min.longitude },
    { latitude: min.latitude, longitude: max.longitude },
    { latitude: max.latitude, longitude: min.longitude },
    { latitude: max.latitude, longitude: max.longitude },
  ];
  let maxDistance = 0;
  corners.forEach((corner) => {
    const distance = calculateDistance(corner, centerCoordinates);
    maxDistance = Math.max(distance, maxDistance);
  });

  return maxDistance + DISTANCE_MARGIN_IN_MILES;
};

/**
 * Used to get default location from a list of locations by averaging all lat and long values
 */
export const getAverageCalendarEventCoordinates = (
  filteredEvents: AggregatedEventsShownOnMap[]
): LatLongCoordinates | undefined => {
  const sums: LatLongCoordinates = { latitude: 0, longitude: 0 };
  let count = 0;
  for (const event of filteredEvents) {
    const coordinates = event.coordinates;
    if (!coordinates) {
      continue;
    }
    sums.latitude += coordinates.latitude;
    sums.longitude += coordinates.longitude;
    count++;
  }

  if (count > 0) {
    return { latitude: sums.latitude / count, longitude: sums.longitude / count };
  } else {
    return;
  }
};

export const filterMapNearbyMeldsToCalendarAggregatedEvents = (
  melds: MeldCalendarProximityListViewSerializer[],
  otherEvents: AggregatedEventsShownOnMap[]
): Array<ExtractMember<AggregatedEventsShownOnMap, "nearby_melds">> => {
  const result: Array<ExtractMember<AggregatedEventsShownOnMap, "nearby_melds">> = [];
  melds.forEach((meld) => {
    // we don't want to show a nearby meld if the meld is already on the map
    if (otherEvents.some((event) => event.meld?.id === meld.id)) {
      return;
    }
    const coordinates = getMeldCoordinates(meld);
    if (!coordinates) {
      return;
    }
    result.push({
      type: "nearby_melds",
      start: null,
      end: null,
      meld,
      coordinates,
    });
  });

  return result;
};

// only some event types are shown on the map
export const getEventsToShowOnMap = (
  aggregatedEvents: AggregatedCalendarEvent[],
  selectedAgentsVendors: CalendarSelectedAgentsVendorsIds
): AggregatedEventsShownOnMap[] => {
  const calendarEvents: AggregatedEventsShownOnMap[] = [];

  aggregatedEvents.forEach((event) => {
    if (event.coordinates === undefined) {
      return;
    }

    if (event.type === "management_scheduled") {
      // we don't want to show offered availabilities on the map
      if (isEventAvailability(event.segment)) {
        return;
      }
      const assignedAgents = getMeldInHouseServicers(event.segment.managementavailabilitysegment.meld);
      if (assignedAgents.some((servicer) => selectedAgentsVendors.agents.includes(servicer.agent))) {
        calendarEvents.push(event);
      }
    } else if (event.type === "vendor_scheduled") {
      // we don't want to show offered availabilities on the map
      if (isEventAvailability(event.segment)) {
        return;
      }
      if (
        selectedAgentsVendors.vendors.includes(event.segment.vendoravailabilitysegment.assignment_request.vendor.id)
      ) {
        calendarEvents.push(event);
      }
    } else if (event.type === "alternative_event_scheduled") {
      const assignedAgents = event.altEvent.management_attendees;
      if (assignedAgents.some((servicer) => selectedAgentsVendors.agents.includes(servicer))) {
        calendarEvents.push(event);
      }
    } else if (event.type === "google_calendar_event" || event.type === "outlook_calendar_event") {
      const assignedAgent = event.agent;
      if (assignedAgent && selectedAgentsVendors.agents.includes(assignedAgent.id)) {
        calendarEvents.push(event);
      }
    }
  });
  return calendarEvents;
};

export function shouldHideCalendarMapMarkerChicklet(marker: MarkerProps<CalendarMapMarkerType>): boolean {
  // a pin with only nearby melds never needs a chicklet
  if (marker.items.every((i) => i.type === "nearby_melds")) {
    return true;
  }
  // if there are multiple items, but all items match the same persona, no need to show the chicklet
  if (marker.items.length > 1 && marker.items.every((i) => i.personas.length === 1)) {
    const firstCompositeId = marker.items[0].personas[0].compositeId;
    return marker.items.every((i) => i.personas.every((p) => p.compositeId === firstCompositeId));
  }

  return false;
}
