import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIcon } from "@elastic/eui";

import { colors } from "@pm-frontend/styles";
import { PmText } from "@pm-frontend/shared/components/Text/PmText";
import URL from "@pm-shared/utils/url";

const ResidentPresenceRequiredBadge = () => {
  return (
    <EuiFlexGroup
      direction="row"
      gutterSize="s"
      alignItems="center"
      style={{
        border: `1px solid ${colors.brand.meldBlue}`,
        borderRadius: "4px",
        padding: "2px 4px",
        width: "fit-content",
      }}
      data-testid="meld-calendar-resident-presence-required-badge"
      responsive={false}
    >
      <EuiFlexItem grow={false}>
        <EuiIcon type={URL.getStatic("icons/residents_gray.svg")} size="m" />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <PmText fontSize="p3" style={{ lineHeight: "18px" }}>
          Resident Required
        </PmText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export { ResidentPresenceRequiredBadge };
