import { colors } from "@pm-frontend/styles";
import React from "react";

const PmXCircleOutline = ({ fill = colors.neutrals.gray800 }: { fill?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_10246_156045)">
        <path
          d="M9.72634 5.33203L7.99967 7.0587L6.27301 5.33203L5.33301 6.27203L7.05967 7.9987L5.33301 9.72536L6.27301 10.6654L7.99967 8.9387L9.72634 10.6654L10.6663 9.72536L8.93967 7.9987L10.6663 6.27203L9.72634 5.33203ZM7.99967 1.33203C4.31301 1.33203 1.33301 4.31203 1.33301 7.9987C1.33301 11.6854 4.31301 14.6654 7.99967 14.6654C11.6863 14.6654 14.6663 11.6854 14.6663 7.9987C14.6663 4.31203 11.6863 1.33203 7.99967 1.33203ZM7.99967 13.332C5.05967 13.332 2.66634 10.9387 2.66634 7.9987C2.66634 5.0587 5.05967 2.66536 7.99967 2.66536C10.9397 2.66536 13.333 5.0587 13.333 7.9987C13.333 10.9387 10.9397 13.332 7.99967 13.332Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_10246_156045">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { PmXCircleOutline };
