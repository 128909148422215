var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { getAccessibleTextColor } from "@pm-frontend/shared/utils/color-utils";
import { colors } from "@pm-frontend/styles";
import { getMeldCoordinates } from "@pm-frontend/shared/utils/meld-utils";
import { calculateDistance } from "@pm-frontend/routes/Melds/MeldDetails/rightpane/subcomponents/MeldsProximity/utils";
import { useGetCalendarEventMetaData, useGetSetActivePaneAndMap } from "../utils/hooks";
import { isEventAvailability } from "../utils/utils";
import { CalendarMeldMapNearbyMeldOpened, track } from "@pm-app/utils/analytics";
import { format } from "date-fns";
import { useGeocodingCore } from "@mapbox/search-js-react";
import { useCalendarMapStateActions } from "../stores/mapStore";
import { getMeldInHouseServicers } from "@pm-frontend/shared/utils/meld-utils";
import { StorageUtils } from "@pm-frontend/shared/utils/storage-utils";
const MAPBOX_ACCESS_TOKEN = window.PM.mapbox_public_token;
export const CALENDAR_MAP_CHICKLET_MAX_HEIGHT_VALUE_IN_PX = 146;
const CHICKLET_MAX_HEIGHT = `${CALENDAR_MAP_CHICKLET_MAX_HEIGHT_VALUE_IN_PX}px`;
export const CALENDAR_MAP_CHICKLET_MAX_WIDTH_VALUE_IN_PX = 130;
export const sortEvents = (event1, event2) => event1.dtstart.localeCompare(event2.dtstart);
export const sortAscendingAppointments = (event1, event2) => event1.event.dtstart.localeCompare(event2.event.dtstart);
export const sortDescendingAppointments = (event1, event2) => event2.event.dtstart.localeCompare(event1.event.dtstart);
const createCircleWithNumber = (color, personaRunningCount) => {
  const circle = document.createElement("span");
  circle.textContent = personaRunningCount ? personaRunningCount.toString() : "";
  circle.style.width = "16px";
  circle.style.height = "16px";
  circle.style.backgroundColor = color;
  circle.style.color = getAccessibleTextColor(color);
  circle.style.borderRadius = "50%";
  circle.style.position = "relative";
  circle.style.display = "flex";
  circle.style.alignItems = "center";
  circle.style.justifyContent = "center";
  circle.style.fontSize = "10px";
  circle.style.paddingTop = "1px";
  return circle;
};
const CHICKLET_TIME_FORMAT = "h:mm aaa";
export const createMarkerChicklet = (marker, getOnViewClick, getLinkText, getDataTestId, map) => {
  const outerContainer = document.createElement("div");
  outerContainer.style.maxHeight = CHICKLET_MAX_HEIGHT;
  outerContainer.style.height = "auto";
  outerContainer.style.overflow = "auto";
  outerContainer.className = "eui-yScroll";
  outerContainer.style.border = `1px solid ${marker.color}`;
  outerContainer.style.borderRadius = "6px";
  outerContainer.style.padding = "6px";
  outerContainer.style.background = colors.brand.white;
  outerContainer.setAttribute("data-testid", getDataTestId(marker.items, marker.coordinates) + "-chicklet");
  const div = document.createElement("div");
  outerContainer.append(div);
  div.style.font = '"Open Sans", sans-serif';
  div.style.fontSize = "12px";
  div.style.background = colors.brand.white;
  div.style.width = "fit-content";
  const circlesSpan = document.createElement("div");
  circlesSpan.style.display = "flex";
  circlesSpan.style.gap = "2px";
  const linkDiv = document.createElement("a");
  linkDiv.onclick = (event) => {
    event.preventDefault();
    getOnViewClick(marker.coordinates, marker.items)(map);
  };
  linkDiv.style.cursor = "pointer";
  const itemContainer = document.createElement("div");
  const circleLinkContainer = document.createElement("div");
  circleLinkContainer.style.display = "flex";
  circleLinkContainer.style.gap = "2px";
  circleLinkContainer.style.alignItems = "center";
  if (marker.items.length === 1) {
    linkDiv.textContent = `View ${getLinkText(marker.items)}`;
    const item = marker.items[0];
    item.personas.forEach((person) => {
      const circle = createCircleWithNumber(person.color, person.count);
      circlesSpan.append(circle);
    });
    const timeDiv = document.createElement("div");
    const startTime = item.start ? format(new Date(item.start), CHICKLET_TIME_FORMAT) : null;
    const endTime = item.end ? format(new Date(item.end), CHICKLET_TIME_FORMAT) : "";
    timeDiv.textContent = startTime && endTime ? `${startTime} - ${endTime}` : null;
    circleLinkContainer.append(circlesSpan);
    circleLinkContainer.append(linkDiv);
    itemContainer.append(circleLinkContainer);
    if (startTime && endTime) {
      itemContainer.append(timeDiv);
    }
  } else if (marker.items.length > 0) {
    const uniqueColors = {};
    linkDiv.textContent = `View ${marker.items.length} ${getLinkText(marker.items)}`;
    marker.items.forEach((item) => {
      item.personas.forEach((person) => {
        uniqueColors[person.color] = true;
      });
    });
    Object.keys(uniqueColors).forEach((color) => {
      const circle = createCircleWithNumber(color, "");
      circlesSpan.append(circle);
    });
    circleLinkContainer.append(linkDiv);
    itemContainer.append(circleLinkContainer);
    itemContainer.append(circlesSpan);
  }
  div.appendChild(itemContainer);
  marker.extraElement = outerContainer;
};
export const getMapPinDataTestId = (items, coordinates) => {
  if (items.length === 1) {
    const item = items[0];
    if (item.type === "vendor_scheduled") {
      return `meld-calendar-marker-vendor-appt-${item.segment.id}`;
    } else if (item.type === "management_scheduled") {
      return `meld-calendar-marker-agent-appt-${item.segment.id}`;
    } else if (item.type === "alternative_event_scheduled") {
      return `meld-calendar-marker-alt-event-${item.altEvent.id}`;
    } else if (item.type === "google_calendar_event") {
      return `meld-calendar-marker-google-event-${item.event.id}`;
    } else if (item.type === "nearby_melds") {
      return `meld-calendar-marker-nearby-meld-${item.meld.id}`;
    }
  } else {
    return `meld-calendar-map-multiple-event-lat-${coordinates == null ? void 0 : coordinates.latitude}-long-${coordinates == null ? void 0 : coordinates.longitude}`;
  }
  return "";
};
export const getMapChickletItemsText = (items) => {
  let meldPresent = false;
  let eventPresent = false;
  for (const item of items) {
    if (item.type === "management_scheduled" || item.type === "vendor_scheduled" || item.type === "nearby_melds") {
      meldPresent = true;
    } else if (item.type === "alternative_event_scheduled" || item.type === "google_calendar_event") {
      eventPresent = true;
    }
  }
  let result = "item";
  if (meldPresent && eventPresent) {
    result = "item";
  } else if (meldPresent) {
    result = "Meld";
  } else if (eventPresent) {
    result = "event";
  }
  if (items.length > 1) {
    result += "s";
  }
  return result;
};
export const useGetCalendarMapItemOnClick = (mapType) => {
  const { updateRightPaneURL } = useGetSetActivePaneAndMap();
  const { setMapEventListRightpaneItems } = useCalendarMapStateActions();
  const eventMetaData = useGetCalendarEventMetaData();
  return (coordinates, items) => (map) => {
    if (items.length === 1) {
      const item = items[0];
      if (item.type === "management_scheduled" || item.type === "vendor_scheduled" || item.type === "nearby_melds") {
        updateRightPaneURL({ newRightpaneState: { type: "meldDetails", meldId: item.meld.id.toString() } });
        if (item.type === "nearby_melds") {
          track(
            CalendarMeldMapNearbyMeldOpened(__spreadValues({
              type: mapType
            }, eventMetaData))
          );
        }
      } else if (item.type === "alternative_event_scheduled") {
        updateRightPaneURL({
          newRightpaneState: { type: "alternativeEvent", mode: "edit", eventId: item.altEvent.id.toString() }
        });
      } else if (item.type === "google_calendar_event") {
        updateRightPaneURL({ newRightpaneState: { type: "googleCalendarEvent", eventId: item.event.id.toString() } });
      }
    } else if (items.length > 1) {
      setMapEventListRightpaneItems(items);
      updateRightPaneURL({ newRightpaneState: { type: "mapEventList" } });
    }
    if (coordinates) {
      map.easeTo({ center: { lat: coordinates.latitude, lng: coordinates.longitude } });
    }
  };
};
function isValidAddress(value) {
  const urlRegex = /^(https?:\/\/|www\.)[^\s]+$/i;
  if (urlRegex.test(value)) {
    return false;
  }
  return true;
}
export const useGoogleEventCoordinates = () => {
  const GEOCODINGCORE = useGeocodingCore({ accessToken: MAPBOX_ACCESS_TOKEN });
  const getMapboxGoogleEventCoordinates = (event, managementLatLong) => __async(void 0, null, function* () {
    if (!("location" in event) || !event.location || event.location === void 0) {
      return;
    }
    if (typeof event.location !== "string" || !isValidAddress(event.location)) {
      return;
    }
    const { value } = StorageUtils.getLocalStorageItem(event.location);
    if (value) {
      return JSON.parse(value);
    }
    const geocodingOptions = __spreadValues({
      country: "US,CA",
      limit: 1
    }, managementLatLong.longitude !== void 0 && managementLatLong.latitude !== void 0 && {
      proximity: `${managementLatLong.longitude},${managementLatLong.latitude}`
    });
    const response = yield GEOCODINGCORE.forward(event.location, geocodingOptions);
    if (response && response.features[0]) {
      const parsedLat = response.features[0].geometry.coordinates[1];
      const parsedLong = response.features[0].geometry.coordinates[0];
      if (!Number.isNaN(parsedLat) && !Number.isNaN(parsedLong)) {
        const data = {
          mapbox_id: response.features[0].properties.mapbox_id,
          original_string: event.location,
          name: response.features[0].properties.name,
          full_address: response.features[0].properties.full_address,
          latitude: parsedLat,
          longitude: parsedLong
        };
        StorageUtils.setLocalStorageItem(event.location, JSON.stringify(data));
        return data;
      }
    }
  });
  return { getMapboxGoogleEventCoordinates };
};
const DISTANCE_MARGIN_IN_MILES = 3;
export const getNearbyDistanceInMiles = (filteredEvents, centerCoordinates) => {
  const max = { latitude: Number.MIN_SAFE_INTEGER, longitude: Number.MIN_SAFE_INTEGER };
  const min = { latitude: Number.MAX_SAFE_INTEGER, longitude: Number.MAX_SAFE_INTEGER };
  let found = false;
  filteredEvents.forEach((event) => {
    const coordinates = event.coordinates;
    if (!coordinates) {
      return;
    }
    found = true;
    if (coordinates.latitude > max.latitude) {
      max.latitude = coordinates.latitude;
    }
    if (coordinates.longitude > max.longitude) {
      max.longitude = coordinates.longitude;
    }
    if (coordinates.latitude < min.latitude) {
      min.latitude = coordinates.latitude;
    }
    if (coordinates.longitude < min.longitude) {
      min.longitude = coordinates.longitude;
    }
  });
  if (!found) {
    return;
  }
  const corners = [
    { latitude: min.latitude, longitude: min.longitude },
    { latitude: min.latitude, longitude: max.longitude },
    { latitude: max.latitude, longitude: min.longitude },
    { latitude: max.latitude, longitude: max.longitude }
  ];
  let maxDistance = 0;
  corners.forEach((corner) => {
    const distance = calculateDistance(corner, centerCoordinates);
    maxDistance = Math.max(distance, maxDistance);
  });
  return maxDistance + DISTANCE_MARGIN_IN_MILES;
};
export const getAverageCalendarEventCoordinates = (filteredEvents) => {
  const sums = { latitude: 0, longitude: 0 };
  let count = 0;
  for (const event of filteredEvents) {
    const coordinates = event.coordinates;
    if (!coordinates) {
      continue;
    }
    sums.latitude += coordinates.latitude;
    sums.longitude += coordinates.longitude;
    count++;
  }
  if (count > 0) {
    return { latitude: sums.latitude / count, longitude: sums.longitude / count };
  } else {
    return;
  }
};
export const filterMapNearbyMeldsToCalendarAggregatedEvents = (melds, otherEvents) => {
  const result = [];
  melds.forEach((meld) => {
    if (otherEvents.some((event) => {
      var _a;
      return ((_a = event.meld) == null ? void 0 : _a.id) === meld.id;
    })) {
      return;
    }
    const coordinates = getMeldCoordinates(meld);
    if (!coordinates) {
      return;
    }
    result.push({
      type: "nearby_melds",
      start: null,
      end: null,
      meld,
      coordinates
    });
  });
  return result;
};
export const getEventsToShowOnMap = (aggregatedEvents, selectedAgentsVendors) => {
  const calendarEvents = [];
  aggregatedEvents.forEach((event) => {
    if (event.coordinates === void 0) {
      return;
    }
    if (event.type === "management_scheduled") {
      if (isEventAvailability(event.segment)) {
        return;
      }
      const assignedAgents = getMeldInHouseServicers(event.segment.managementavailabilitysegment.meld);
      if (assignedAgents.some((servicer) => selectedAgentsVendors.agents.includes(servicer.agent))) {
        calendarEvents.push(event);
      }
    } else if (event.type === "vendor_scheduled") {
      if (isEventAvailability(event.segment)) {
        return;
      }
      if (selectedAgentsVendors.vendors.includes(event.segment.vendoravailabilitysegment.assignment_request.vendor.id)) {
        calendarEvents.push(event);
      }
    } else if (event.type === "alternative_event_scheduled") {
      const assignedAgents = event.altEvent.management_attendees;
      if (assignedAgents.some((servicer) => selectedAgentsVendors.agents.includes(servicer))) {
        calendarEvents.push(event);
      }
    } else if (event.type === "google_calendar_event" || event.type === "outlook_calendar_event") {
      const assignedAgent = event.agent;
      if (assignedAgent && selectedAgentsVendors.agents.includes(assignedAgent.id)) {
        calendarEvents.push(event);
      }
    }
  });
  return calendarEvents;
};
export function shouldHideCalendarMapMarkerChicklet(marker) {
  if (marker.items.every((i) => i.type === "nearby_melds")) {
    return true;
  }
  if (marker.items.length > 1 && marker.items.every((i) => i.personas.length === 1)) {
    const firstCompositeId = marker.items[0].personas[0].compositeId;
    return marker.items.every((i) => i.personas.every((p) => p.compositeId === firstCompositeId));
  }
  return false;
}
