import { PmCard } from "@pm-frontend/shared/components/Card/PmCard";
import { PmText } from "@pm-frontend/shared/components/Text/PmText";
import React, { useEffect, useState } from "react";

import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiImage } from "@elastic/eui";
import { BorderColor, colors } from "@pm-frontend/styles";
import { PmEmptyButton } from "@pm-frontend/shared/components/Buttons/PmEmptyButton";
import { UseBaseMutationResult } from "@tanstack/react-query";
import { AssignMeldMaintenanceData } from "../../queries";
import { MeldToBeAssigned } from "../../shared/flyouts/MeldAssignmentFlyout";
import { PmBadge } from "@pm-frontend/shared/components/PmBadge";
import { getPersonaColor } from "@pm-frontend/shared/utils/color-utils";
import { NexusFile } from "@pm-frontend/shared/types/api/nexus/models";
import { NexusDetailSerializer } from "@pm-frontend/shared/types/api/nexus/serializers/nexus_detail_serializer";
import { AllVendorsListRegisteredOnlyFalse } from "@pm-frontend/shared/types/api/maintenance/api";
import { getVendorDisplayName } from "@pm-frontend/shared/utils/vendor-utils";
import { Vendor } from "@pm-frontend/shared/types/api/vendor/models";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import {
  NexusPartnerAssigned,
  NexusPartnerAssignRecommended,
  NexusPartnerConnectViewed,
  track,
} from "@pm-app/utils/analytics";

const NotAssignedLabel = ({ nexus }: { nexus: NexusOption }) => (
  <EuiFlexGroup direction="column" gutterSize="none">
    <EuiFlexItem grow={true} style={{ paddingRight: "20px" }}>
      <PmText fontWeight="bold" fontSize="p2" data-testid="meld-details-assign-nexus-card-assign-label">
        Assign this vendor for the first time
      </PmText>
    </EuiFlexItem>
    <EuiFlexItem grow={true} style={{ padding: "0px 10px" }}>
      <PmText fontWeight="regular" fontSize="p2">
        <ul>
          <li>{nexus.vendor.vendorName} offers services for this category near this property</li>
          <li>You're connected through Vendor Nexus</li>
        </ul>
      </PmText>
    </EuiFlexItem>
  </EuiFlexGroup>
);

const NotConnectedLabel = ({ nexus }: { nexus: NexusOption }) => (
  <EuiFlexGroup direction="column" gutterSize="none">
    <EuiFlexItem grow={true} style={{ paddingRight: "20px" }}>
      <PmText fontWeight="bold" fontSize="p2" data-testid="meld-details-assign-nexus-card-connect-label">
        Try someone new
      </PmText>
    </EuiFlexItem>
    <EuiFlexItem grow={true} style={{ padding: "0px 10px" }}>
      <PmText fontWeight="regular" fontSize="p2">
        <ul>
          <li>{nexus.name} offers services for this category near this property</li>
          <li>Available through Vendor Nexus</li>
        </ul>
      </PmText>
    </EuiFlexItem>
  </EuiFlexGroup>
);

type NexusOption = {
  id: number;
  name: string;
  created: string;
  vendor: NexusVendorOption;
  service: NexusServiceOption;
  connections: NexusDetailSerializer["connections"];
  logo: NexusDetailSerializer["files"][0] | undefined;
};

type NexusVendorOption = {
  id: number;
  vendorName: string;
  vendor: Vendor;
};

type NexusServiceOption = {
  name: string;
  category: string;
};

// maps the nexus endpoint to a more readable view to be used by other components.
// No logic is done in here.
const mapNexusOptions = (nexusVendors: NexusDetailSerializer[], meldCategory: string) => {
  return nexusVendors
    .map((nexus) => {
      const matchingService = nexus.services.find((service) => service.category === meldCategory);
      const vendor = nexus.vendor_agents[0].vendor_agent?.vendor;
      return matchingService
        ? {
            id: nexus.id,
            name: nexus.name,
            vendor: vendor
              ? {
                  id: vendor.id,
                  vendorName: getVendorDisplayName(vendor),
                  vendor: vendor,
                }
              : null,
            service: {
              name: matchingService.name, // Return the service name instead of the category
              category: matchingService.category,
            },
            connections: nexus.connections,
            logo: nexus.files.find((file: NexusFile) => file.file_type === "LOGO"),
          }
        : null;
    })
    .filter((item): item is NexusOption => item !== null && item !== undefined);
};

interface NexusRecommendationCardProps {
  meld: MeldToBeAssigned;
  onClose: () => void;
  mutation: UseBaseMutationResult<unknown, unknown, AssignMeldMaintenanceData, unknown>;
  allMaintenance: AllVendorsListRegisteredOnlyFalse;
  nexusVendorData: NexusDetailSerializer[];
}

const NexusRecommendationCard = ({
  meld,
  onClose,
  mutation,
  allMaintenance,
  nexusVendorData,
}: NexusRecommendationCardProps) => {
  const nexuses = mapNexusOptions(nexusVendorData, meld.work_category);
  const [index] = useState(() => Math.floor(Math.random() * nexuses.length));
  const nexus = nexuses?.[index];

  useEffect(() => {
    if (nexus) {
      track(
        NexusPartnerAssignRecommended(nexus.id, nexus.connections.length > 0 ? "Not assigned before" : "Not connected")
      );
    }
  }, []);

  const onAssignClick = (value: number) => {
    const mappedSelected = allMaintenance.find((m: { id: number }) => m.id === value);

    if (!mappedSelected) {
      return;
    }

    track(NexusPartnerAssigned(nexus.id, "Not assigned before"));

    mutation.mutate(
      {
        maintenance: [mappedSelected],
        user_groups: [],
      },
      {
        onSuccess: onClose,
      }
    );
  };

  if (!nexus) {
    return null;
  }
  const label = nexus.connections.length > 0 ? <NotAssignedLabel nexus={nexus} /> : <NotConnectedLabel nexus={nexus} />;

  return (
    <EuiFlexItem grow={true} style={{ maxWidth: "500px", margin: "0px" }}>
      <PmCard grow={false} panelClassName="card" padding="16px 20px" data-testid="meld-details-assign-nexus-card">
        <EuiFlexGroup direction="row" gutterSize="none" responsive={false} style={{ height: "100%" }}>
          <EuiFlexItem grow={false} style={{ maxWidth: nexus.logo ? "70%" : "100%" }}>
            <EuiFormRow label={label}>
              <EuiFlexGroup
                direction="row"
                gutterSize="s"
                style={{ alignItems: "center", paddingRight: "10px", marginTop: "10px" }}
              >
                <EuiFlexItem
                  grow={false}
                  style={{ width: "100%", alignItems: "center", overflowX: "auto", textOverflow: "ellipsis" }}
                >
                  <PmBadge
                    bgColor={nexus.vendor ? getPersonaColor(nexus.vendor.vendor) : getPersonaColor(nexus)}
                    textColor={colors.neutrals.gray900}
                    text={nexus.vendor?.vendorName || nexus.name || ""}
                    data-testid="meld-details-assign-nexus-vendor-badge"
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ width: "100%" }}>
                  {nexus.connections.length > 0 ? (
                    <PmEmptyButton
                      onClick={() => onAssignClick(nexus.vendor.id)}
                      text={"Assign"}
                      hasBorder={false}
                      textSize="14px"
                      padding="0px"
                      aria-label={`Assign ${nexus.vendor.vendorName}`}
                      data-testid="meld-details-assign-nexus-card-assign-button"
                    />
                  ) : (
                    <PmEmptyButton
                      onClick={() => {
                        track(NexusPartnerConnectViewed(nexus.id, "Not connected"));
                      }}
                      text="Connect"
                      isLoading={false}
                      internalLink={false}
                      href={LinkHelper.normalize(RouteUrls.vendorSearchDetail(nexus.id))}
                      hasBorder={false}
                      textSize="14px"
                      padding="0px"
                      data-testid="meld-details-assign-nexus-card-connect-button"
                    />
                  )}
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>
          </EuiFlexItem>
          {nexus.logo && (
            <EuiFlexItem
              grow={false}
              style={{
                justifyContent: "center",
                borderLeft: `1px solid ${BorderColor}`,
              }}
            >
              <EuiImage
                size={120}
                hasShadow={false}
                alt={`Nexus Logo`}
                style={{
                  width: "100%",
                  marginLeft: "10px",
                }}
                src={nexus.logo.signed_url}
              />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </PmCard>
    </EuiFlexItem>
  );
};

export { NexusRecommendationCard };
